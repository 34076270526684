import { combineReducers } from "redux";
import  userReducer from "./userReducer";
import authReducer from "./authReducer";
import commonReducer from "./commonReducer";
import zoomReducer from "./zoomReducer";
import stripeReducer from "./stripeReducer";
import contactReducer from "./contactReducer";
import prsReducer from "./prsReducer";
import chatReducer from './chatReducer'

 const appReducer = combineReducers({
    userReducer,
    authReducer,
    commonReducer,
    stripeReducer,
    zoomReducer,
    prsReducer,
    contactReducer,
    chatReducer
});
export default  appReducer;
