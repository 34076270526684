import React, { useState, useEffect } from "react";
import {
  IonContent,
  IonCardTitle,
  IonInput,
  IonButton,
  IonCardHeader,
  IonLabel,
  IonSpinner
} from "@ionic/react";
import { Link } from "react-router-dom";
import {
    updatePlayLink,
  getPlayLink,
} from "../../redux/actions/zoomAction";
import "./playLink.css";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

const PlayLink = (props) => {
  const [formData, setFormValue] = useState({ playLink: "" });
  const [errorData, setErrorData] = useState({ playLink: [] });
  const [toast, setToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {


    (async () => {
        let id = await JSON.parse(localStorage.getItem("userData")).id;
         await props.getPlayLink(id)
    })();
    
    // if (props.checkUserRole === 3 || props.checkUserRole === 2) {
    //   const id = JSON.parse(localStorage.getItem("userData")).id;
    //   props.getPlayLink(id);
    // } else if (props.checkUserRole === 1) {
    //   const id = JSON.parse(localStorage.getItem("userData")).id;
    //   props.getPlayLink(id);
    // }
  }, []);

  useEffect(() => {
    if(props?.playPayload?.data?.data[0]?.play_link){
      const link = props.playPayload.data.data[0].play_link;
      setInputValue(link);
    }    
  }, [props.playPayload.data?.data]);

  const handleChange = (formField, event) => {
    let test = formData;
    test[formField] = event.target.value;
    setInputValue(test[formField]);
    setFormValue(test);
  };

  const validateForm = () => {
    let isFormValid = true;
    let testError = { playLink: [] };
    let regex = new RegExp(
      "^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?"
    );

    if (formData.playLink.trim().length === 0) {
      isFormValid = false;
      testError.playLink = ["Zoom Link cannot be empty!"];
    }
    if (
      formData.playLink.trim().length > 0 &&
      regex.test(formData.playLink) === false
    ) {
      isFormValid = false;
      testError.playLink = ["Please provide a valid Zoom Link!"];
    }
    setErrorData(testError);
    return isFormValid;
  };

  function openTab(value) {
    window.open(value);
  }

  async function onSubmitForm() {
    if (validateForm()) {
      formData.id = await JSON.parse(localStorage.getItem("userData")).id;
      
      await props.updatePlayLink(formData).then((response) => {
        if (response.success === true) {
          props.getPlayLink(formData.id);
          setToast(true);
          setToastMessage(response.message);
          setTimeout(() => {
            setToast(false);
          }, 3000);
        }
      });
      if(props?.playPayload?.data?.data[0]?.play_link ){
        const link = props.playPayload.data.data[0].play_link;
        setInputValue(link);
      } 
    }
  }

  return (
    <IonContent>
      <div className="zoom">
        <div className="zoom-header">
          <IonCardHeader>
            <IonCardTitle className="inner-page-heading">PlaySight</IonCardTitle>
            {toast === true ? (
              <div className="success-msg">
                <p>{toastMessage}</p>
              </div>
            ) : null}
          </IonCardHeader>
        </div>

        {props.checkUserRole === 1 ? (
          // admin
          <div className="zoom-content-body">
            <div className="zoom-content-head">
              <h2>PlaySight</h2>
            </div>

            <div className="zoom-content-mid">
              <form>
                <div className="form-group">
                  <IonInput
                    type="text"
                    name="playLink"
                    value={inputValue}
                    // placeholder={inputValue}
                    // defaultValue={props.playPayload?.data?.data[0].play_link}
                    className="form-control"
                    onIonChange={(event) => {
                      handleChange("playLink", event);
                    }}
                  ></IonInput>
                  <p className="errormsg">
                    {" "}
                    {errorData?.playLink && errorData.playLink[0]}
                  </p>
                </div>
                <div className="form-group-center">
                  <IonButton
                    color="none"
                    className="gradient-btn-blue"
                    onClick={(event) => {
                      onSubmitForm(event);
                    }}
                  >
                    Share Link
                    {(() => {
                    if (props.loading) {
                      return (
                        <IonSpinner
                          name="crescent"
                          className="spinner-loader"
                        />
                      );
                    }
                  })()}
                  </IonButton>
                </div>
              </form>
            </div>
          </div>
        ) : (
          //admin end
          // null
          // player
          <div className="zoom-content-body">
            <div className="zoom-content-head">
              <h2>PlaySight URL</h2>
            </div>
            <div className="zoom-content-mid">
              <div className="zoom-url">
                <Link
                  onClick={() =>
                    openTab(props.playPayload?.data?.data[0].play_link)
                  }
                >
                  {props.playPayload?.data?.data[0].play_link}
                </Link>
                {/* <Link to={props.playPayload?.data?.data[0].play_link} target="_blank">View</Link> */}
                {/* <Link to={props.playPayload?.data?.data[0].play_link} target="_blank" >
            {props.playPayload?.data?.data[0].play_link}
            </Link> */}
                {/* <a href={props.playPayload?.data?.data[0].play_link} target="_blank"> {props.playPayload?.data?.data[0].play_link}</a> */}
              </div>
            </div>
          </div>
          // player end
        )}
      </div>
    </IonContent>
  );
};

const mapStateToProps = (state) => {
  return {
    checkUserRole: state.authReducer.checkUserRole,
    playPayload: state.zoomReducer.playPayload,
    loading: state.commonReducer.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { updatePlayLink, getPlayLink },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PlayLink);
