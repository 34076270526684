import axios from "axios";
import { handleError, handleSuccess } from "./commonAction";
const URL = process.env.REACT_APP_API_URL;
let GroupArray = [];
export function getChatRoom(data) {
  return async function (dispatch) {
    try {
      dispatch({ type: "LOADING_STARTED" });
      const res = await axios({
        method: "post",
        url: `${URL}/api/v1/chat/findRoom`,
        data,
        headers: {
          authorization: localStorage.getItem("user"),
        },
      });
      if (res.data.isRegistered === false) {
        dispatch(
          handleError({ message: "Something Went wrong! Please try again." })
        );
      } else {
        dispatch({
          type: "GET_CHAT_ROOM",
          payload: { data: res?.data?.data?.data },
        });
      }

      dispatch({ type: "LOADING_COMPLETED" });
    } catch (error) {
      dispatch({ type: "LOADING_FAILURE" });
      dispatch(handleError(error));
    }
  };
}

export function getAllChatRoom(id) {
  return async function (dispatch) {
    try {
      dispatch({ type: "LOADING_STARTED" });
      const res = await axios({
        method: "get",
        url: `${URL}/api/v1/chat/getAllChatRoom/${id}`,
        headers: {
          authorization: localStorage.getItem("user"),
        },
      });
      dispatch({
        type: "GET_ALL_CHAT_ROOM",
        payload: { data: res?.data?.data?.data },
      });
      dispatch({ type: "LOADING_COMPLETED" });
    } catch (error) {
      dispatch({ type: "LOADING_FAILURE" });
      dispatch(handleError(error));
    }
  };
}

export function createGroupArray(contact) {
  return async function (dispatch) {
    if (GroupArray.find((o) => o === contact.email) === undefined) {
      GroupArray.push(contact.email);
    } else {
      let index = GroupArray.findIndex((x) => x === contact.email);
      GroupArray.splice(index, 1);
    }
    dispatch({ type: "CREATE_GROUP_ARRAY", payload: GroupArray });
  };
}
export function setGroupArray(contact) {
  return async function (dispatch) {
    GroupArray = contact;
    // dispatch({})
  };
}

export function createGroup(data) {
  return async function (dispatch) {
    try {
      dispatch({ type: "LOADING_STARTED" });
      const res = await axios({
        method: "post",
        url: `${URL}/api/v1/chat/createGroup`,
        data,
        headers: {
          authorization: localStorage.getItem("user"),
        },
      });
      dispatch({ type: "LOADING_COMPLETED" });
      if (res.data.isRegistered === true || res.data.isFound === true) {
        dispatch(handleSuccess({ message: "Group Created Successfully" }));
      } else {
        dispatch(
          handleError({ message: "Something Went wrong! Please try again." })
        );
      }
      GroupArray = [];
      dispatch({ type: "EMPTY_ARRAY" });
      return res;
    } catch (error) {
      dispatch({ type: "LOADING_FAILURE" });
      dispatch(handleError(error));
    }
  };
}

export function getAllGroups(id) {
  return async function (dispatch) {
    dispatch({ type: "EMPTY_ARRAY" });
    try {
      dispatch({ type: "LOADING_STARTED" });
      const res = await axios({
        method: "get",
        url: `${URL}/api/v1/chat/getGroup/${id}`,
        headers: {
          authorization: localStorage.getItem("user"),
        },
      });
      dispatch({
        type: "GET_GROUP_ARRAY",
        payload: { data: res?.data?.data?.data },
      });
      dispatch({ type: "LOADING_COMPLETED" });
    } catch (error) {
      dispatch({ type: "LOADING_FAILURE" });
      dispatch(handleError(error));
    }
  };
}

export function getGroupmembers(id) {
  return async function (dispatch) {
    try {
      dispatch({ type: "LOADING_STARTED" });
      const res = await axios({
        method: "get",
        url: `${URL}/api/v1/chat/getGroupMembers/${id}`,
        headers: {
          authorization: localStorage.getItem("user"),
        },
      });
      dispatch({
        type: "GET_GROUP_MEMBERS",
        payload: { data: res?.data?.data?.data },
      });
      dispatch({ type: "LOADING_COMPLETED" });
    } catch (error) {
      dispatch({ type: "LOADING_FAILURE" });
      dispatch(handleError(error));
    }
  };
}

export function saveLastSeen(data, id) {
  return async function (dispatch) {
    try {
      const res = await axios({
        method: "put",
        url: `${URL}/api/v1/chat/saveLastSeen/${id}`,
        data,
        headers: {
          authorization: localStorage.getItem("user"),
        },
      });
      dispatch({ type: "LOADING_COMPLETED" });
      return res;
    } catch (error) {
      dispatch({ type: "LOADING_FAILURE" });
      dispatch(handleError(error));
    }
  };
}

export function sendUserNotification(data) {
  return async function (dispatch) {
    try {
      const res = await axios({
        method: "post",
        url: `${URL}/api/v1/chat/sendUserNotification`,
        data,
        headers: {
          authorization: localStorage.getItem("user"),
        },
      });
      dispatch({ type: "LOADING_COMPLETED" });
      return res;
    } catch (error) {
      dispatch({ type: "LOADING_FAILURE" });
      dispatch(handleError(error));
    }
  };
}

export function deleteChatGroup(id) {
  return async function (dispatch) {
    try {
      dispatch({ type: "LOADING_STARTED" });
      const res = await axios({
        method: "delete",
        url: `${URL}/api/v1/chat/deleteChatGroup/${id}`,
        headers: {
          authorization: localStorage.getItem("user"),
        },
      });
      dispatch({ type: "LOADING_COMPLETED" });
      if (res.data.isDeleted === true) {
        dispatch(handleSuccess({ message: "Group Deleted Successfully" }));
      }
      return res;
    } catch (error) {
      dispatch({ type: "LOADING_FAILURE" });
      dispatch(handleError(error));
    }
  };
}

export function editGroup(data, id) {
  return async function (dispatch) {
    try {
      dispatch({ type: "LOADING_STARTED" });
      const res = await axios({
        method: "put",
        url: `${URL}/api/v1/chat/editGroup/${id}`,
        data,
        headers: {
          authorization: localStorage.getItem("user"),
        },
      });
      dispatch({ type: "LOADING_COMPLETED" });
      if (res.data.isDone === true) {
        dispatch(handleSuccess({ message: "Group Updated Successfully" }));
      } else {
        dispatch(
          handleError({ message: "Something Went wrong! Please try again." })
        );
      }
      return res;
    } catch (error) {
      dispatch({ type: "LOADING_FAILURE" });
      dispatch(handleError(error));
    }
  };
}

export const chatModelData = (data) => async (dispatch) => {
  dispatch({ type: "CHAT_DATA", payload: data });
};
