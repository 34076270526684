import React from "react";
import { ElementsConsumer } from "@stripe/react-stripe-js";
import Subscriptions from "./subscriptions";

export default function InjectedCheckoutForm() {
    return (
        <ElementsConsumer>
            {({ stripe, elements }) => (
                <Subscriptions stripe={stripe} elements={elements} />
            )}
        </ElementsConsumer>
    );
}