import React, { useState, useRef, useEffect } from "react";
import {
  IonIcon,
  IonInput,
  IonButton,
  IonLabel,
  IonModal,
  IonDatetime,
  IonAlert,
  IonGrid,
  IonRow,
  IonCol,
  IonSpinner,
  IonCheckbox,
} from "@ionic/react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { IonContent } from "@ionic/react";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Calendar as calendarIonic } from "@ionic-native/calendar";

import {
  addEvent,
  getEventList,
  deleteEvent,
  editEvent,
} from "../../redux/actions/zoomAction";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { close } from "ionicons/icons";
import moment from "moment";
import { useDispatch } from "react-redux";
import { getPlatforms } from "@ionic/react";
import { Diagnostic } from "@ionic-native/diagnostic";
import "../calendar/calendar.css";
import { getNotifications } from "../../redux/actions/users";

const localizer = momentLocalizer(moment);
const eventForm = {
  name: "",
  description: "",
  startDate: "",
  endDate: "",
  venue: "",
  is_weekly_repeat: "No",
  days: [],
  startTime: "",
  endTime: "",
  fromDate: "",
  toDate: "",
};
const eventError = {
  name: [],
  startDate: [],
  endDate: [],
  is_weekly_repeat: [],
  days: [],
  startTime: [],
  endTime: [],
  fromDate: [],
  toDate: [],
};

const CalendarClass = (props) => {
  const dispatch = useDispatch();
  const [list, setList] = useState([]);
  const [role, setRole] = useState();
  const [showModal, setShowModal] = useState(false);
  const [exportedCalender, setExportedCalender] = useState(false);
  const [message, setMessage] = useState("Calendar Exported");
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [title, setTitle] = useState("Add");
  const [isWeb, setWeb] = useState(false);
  const [formData, setFormValue] = useState(eventForm);
  const [errorData, setErrorData] = useState(eventError);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [currentDate, setCurrentDate] = useState(new Date());
  const daysMapping = {
    Sun: 0,
    Mon: 1,
    Tue: 2,
    Wed: 3,
    Thu: 4,
    Fri: 5,
    Sat: 6,
  };
  const [selectedDays, setSelectedDays] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [isBlankMonth, setIsBlankMonth] = useState(false);

  const eventStyleGetter = function () {
    var style = {
      background: "#762b87",
      borderRadius: "10px",
      opacity: 1,
      color: "#fff",
      border: "0px",
      display: "block",
      padding: "2px 10px",
      textAlign: "center",
      margin: "3px",
      outline: "none",
    };
    return {
      style: style,
    };
  };

  const handleCheckboxChangeHide = () => {
    setIsChecked(!isChecked);
    if (!isChecked) {
      setFormValue({
        ...formData,
        is_weekly_repeat: "Yes",
        days: selectedDays,
        startTime: "",
        endTime: "",
        fromDate: "",
        toDate: "",
        // sending blank when is_weekly_repeat is true
        startDate: "",
        endDate: "",
      });
    } else {
      setFormValue({
        ...formData,
        is_weekly_repeat: "No",
        startDate: "",
        endDate: "",
        // sending blank when is_weekly_repeat is false
        days: [],
        startTime: "",
        endTime: "",
        fromDate: "",
        toDate: "",
      });
    }
  };

  React.useEffect(() => {
    if (props?.location?.state?.event) {
      setSelectedEvent(props.location.state.event);
      setShowDetailModal(false);
    }
    getEvents();
    let webb = getPlatforms().some(
      (r) => ["desktop", "mobileweb"].indexOf(r) >= 0
    );
    if (webb) {
      setWeb(false);
    } else {
      setWeb(true);
    }
  }, []);

  React.useEffect(() => {
    let type = props.type;
    let events = props.eventList;
    if (type === "GETEVENT" && events.length > 0) {
      setIsBlankMonth(false);
    } else if (type === "GETEVENT" && events.length <= 0) {
      setIsBlankMonth(true);
    }
  }, [props.type, props.eventList]);

  React.useEffect(() => {
    let type = props.type;
    if (type === "LOADING_STARTED") {
      setList([]);
      dispatch({ type: "CLEAR_EVENT", payload: [] });
    }
  }, [props.type]);

  //unmount method
  React.useEffect(() => {
    return () => {
      setList([]);
      dispatch({ type: "CLEAR_EVENT", payload: [] });
    };
  }, []);

  React.useEffect(() => {
    let events = props.eventList;
    let dummyArray = [];
    for (let i = 0; i < events.length; i++) {
      dummyArray.push({
        id: events[i].id,
        title: events[i].name,
        start: new Date(moment.utc(events[i].startDate).local().format()),
        end: new Date(moment.utc(events[i].endDate).local().format()),
        desc: events[i].description,
        reccStartTime: new Date(
          moment.utc(events[i].startDate).local().format()
        ),
        reccEndTime: new Date(moment.utc(events[i].endDate).local().format()),

        venue: events[i].venue,
        //Recurring Events
        is_repeat: events[i].is_repeat,
        fromDate: events[i].fromDate,
        toDate: events[i].toDate,
        days: events[i].days?.split(",").map((e) => parseInt(e)),
      });
    }
    setList((_prev) => dummyArray);
  }, [props.eventList]);

  const validateForm = () => {
    let isFormValid = true;

    let testError = {
      name: [],
      // description: [],
      startDate: [],
      endDate: [],
      // venue: [],
      is_weekly_repeat: [],
      days: [],
      startTime: [],
      endTime: [],
      fromDate: [],
      toDate: [],
    };
    // name validation
    if (formData.name.trim().length === 0) {
      isFormValid = false;
      testError.name = ["This field is required."];
    }
    if (
      formData.name.trim().length > 0 &&
      (formData.name.trim().length < 1 || formData.name.trim().length > 50)
    ) {
      isFormValid = false;
      testError.name = ["name length must be between 1 to 50 characters"];
    }

    // is_weekly_repeat is not selected
    if (formData.is_weekly_repeat === "No") {
      // start date validation
      if (formData.startDate.trim().length === 0) {
        isFormValid = false;
        testError.startDate = ["This field is required."];
      }
      // end date validation
      if (formData.endDate.trim().length === 0) {
        isFormValid = false;
        testError.endDate = ["This field is required."];
      }
    }

    // is_weekly_repeat is selected
    if (formData.is_weekly_repeat === "Yes") {
      // start date validation
      if (formData.fromDate.trim().length === 0) {
        isFormValid = false;
        testError.fromDate = ["This field is required."];
      }
      // end date validation
      if (formData.toDate.trim().length === 0) {
        isFormValid = false;
        testError.toDate = ["This field is required."];
      }
      // start time validation
      if (formData.startTime.trim().length === 0) {
        isFormValid = false;
        testError.startTime = ["This field is required."];
      }
      // end time validation
      if (formData.endTime.trim().length === 0) {
        isFormValid = false;
        testError.endTime = ["This field is required."];
      }
    }
    if (
      (formData?.is_weekly_repeat === "Yes" ||
        selectedEvent?.is_repeat === "Yes") &&
      selectedDays.length === 0
    ) {
      isFormValid = false;
      testError.days = ["Please select at least one day"];
    }

    if (formData.is_weekly_repeat === "No") {
      const d1 = moment(formData.startDate);
      const d2 = moment(formData.endDate);

      if (d2.diff(d1, "minutes") <= 0) {
        isFormValid = false;
        testError.endDate = [
          "End date and time cannot be before or equal to the start date and time.",
        ];
      }
    }

    if (formData.is_weekly_repeat === "Yes") {
      const d1 = moment(formData.fromDate);
      const d2 = moment(formData.toDate);

      if (d2.diff(d1, "days") < 0) {
        isFormValid = false;
        testError.toDate = ["End date cannot be before the start date."];
      }
    }

    if (formData.is_weekly_repeat === "Yes") {
      var dateObj1 = new Date(formData.startTime).getTime();
      var dateObj2 = new Date(formData.endTime).getTime();

      const timeChecking = dateObj2 - dateObj1;

      const errorMessages = {
        invalidFormat: "End time must be after the start time.",
        endTimeBeforeStartTime: "End time must be after the start time.",
        success: "",
      };

      if (timeChecking > 0) {
        // isFormValid = true;
        testError.endTime = [errorMessages.success];
      } else {
        isFormValid = false;
        formData.endTime.trim().length !== 0 &&
          (testError.endTime = [
            errorMessages.invalidFormat,
            errorMessages.endTimeBeforeStartTime,
          ]);
      }
    }

    setErrorData(testError);
    return isFormValid;
  };

  const getEvents = async () => {
    let userData = localStorage.getItem("userData");
    userData = JSON.parse(userData);
    setRole(userData.role);
    await props.getEventList(
      moment
        .utc(moment(new Date()).startOf("month"))
        .add(7, "days")
        .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
    );
  };

  const onNavigate = (date, view) => {
    if (view === "month") {
      let start = moment
        .utc(moment(date).startOf("month"))
        .add(7, "days")
        .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
      setCurrentDate(start);
      props.getEventList(start);
    }
  };

  const validateField = (formField, value) => {
    let errors = [];

    if (Array.isArray(value)) {
      if (value.length > 0) {
        errors.push("");
      } else {
        errors.push(`Please select at least one day.`);
      }
    } else if (typeof value === "string") {
      if (value.trim() === "") {
        errors.push(`This field is required.`);
      }
    } else {
      errors.push(`Invalid type for ${formField}.`);
    }

    return errors;
  };

  const handleCheckboxChange = (day) => {
    const dayValue = daysMapping[day];
    let selectedDay = [];

    if (selectedDays.includes(dayValue)) {
      selectedDay = selectedDays.filter((item) => item !== dayValue);
    } else {
      selectedDay = [...selectedDays, dayValue];
    }

    setSelectedDays(selectedDay);

    const errors = validateField(day, selectedDay);

    setErrorData((prevErrorData) => ({
      ...prevErrorData,
      days: errors,
    }));
  };

  const handleChange = (formField, event) => {
    const value = event.target.value;

    const errors = validateField(formField, value);

    let updatedFormData = { ...formData, [formField]: value };
    setFormValue(updatedFormData);

    setErrorData((prevErrorData) => ({
      ...prevErrorData,
      [formField]: errors,
    }));
  };

  const SubmitEvent = async (event) => {
    console.log("formData", formData);

    if (validateForm()) {
      formData["user_id"] = props.authData.id;
      if (formData.is_weekly_repeat === "No") {
        formData["startDate"] = moment.utc(moment(formData.startDate)).format();
        formData["endDate"] = moment.utc(moment(formData.endDate)).format();
      }
      if (formData.is_weekly_repeat === "Yes") {
        formData["days"] = selectedDays;

        // formData["fromDate"] = moment
        //   .utc(moment(formData.fromDate))
        //   .format("YYYY-MM-DD");
        // formData["toDate"] = moment
        //   .utc(moment(formData.toDate))
        //   .format("YYYY-MM-DD");

        // formData["startTimeLocal"] = moment
        //   .utc(formData.startTime)
        //   .format("HH:mm:ss");
        // formData["endTimeLocal"] = moment
        //   .utc(formData.endTime)
        //   .format("HH:mm:ss");

        formData["fromDate"] = moment(formData.fromDate).format("YYYY-MM-DD");
        formData["toDate"] = moment(formData.toDate).format("YYYY-MM-DD");

        formData["startTimeLocal"] = moment(formData.startTime).format("HH:mm:ss");
        formData["endTimeLocal"] = moment(formData.endTime).format("HH:mm:ss");

        formData["timezone"] = Intl.DateTimeFormat().resolvedOptions().timeZone;
      }

      await props.addEvent(formData).then((data) => {
        if (data?.status) {
          setShowModal(false);
          setSuccessMessage(true);

          props.getEventList(currentDate);

          setTimeout(() => {
            setSuccessMessage(false);
          }, 3000);
        }
      });
    }
  };

  const createCalendar = async () => {
    await calendarIonic.hasReadWritePermission().then(async (data) => {
      if (data === false) {
        // exportEvent()
        await requestPermission().then(
          (data) => {
            if (data === true) {
              exportEvent();
            } else {
              setMessage(
                "Event can't be exported as you didn't granted permission"
              );
              setExportedCalender(true);
              setTimeout(() => {
                setExportedCalender(false);
              }, 3000);
            }
          },
          (err) => {}
        );
      } else {
        exportEvent();
      }
    });
  };

  const requestPermission = () => {
    return new Promise((resolve, reject) => {
      Diagnostic.requestCalendarAuthorization(
        (data) => {
          if (data === "DENIED_ONCE") {
            resolve(false);
          } else if (data === "GRANTED") {
            resolve(true);
          } else {
            resolve(false);
          }
        },
        (err) => {}
      );
    });
  };

  const exportEvent = async () => {
    let isSuccess = false;
    await calendarIonic.createCalendar("myCalendar").then(
      (message) => {
        isSuccess = true;
      },
      (error) => {
        isSuccess = false;
      }
    );

    for (let item of list) {
      await calendarIonic
        .createEvent(
          item.title,
          item.venue,
          item.desc,
          new Date(item.start),
          new Date(item.end)
        )
        .then(
          (message) => {
            //  message = "Export Successfully"
          },
          (error) => {}
        );
    }

    setTimeout(() => {
      setExportedCalender(false);
    }, 3000);
    if (isSuccess) {
      setMessage("Calendar Exported");
    } else {
      setMessage("Event can't be exported as you didn't granted permisson");
    }
    setExportedCalender(true);
  };

  const selectEvent = (event) => {
    setSelectedEvent(event);
    setShowDetailModal(true);
  };

  const formatDateTime = (dateTime) => {
    return moment(dateTime).format("MM-DD-YYYY hh:mm A");
  };

  const formatDate = (date) => {
    return moment.utc(date).format("MM-DD-YYYY");
  };

  const formatTime = (time) => {
    return moment(time).format("hh:mm A");
  };

  const selectSlot = (slot) => {
    if (role === 1) {
      setTitle("Add");
      let test = {
        name: "",
        description: "",
        startDate: "",
        endDate: "",
        venue: "",
      };
      test.startDate = slot?.start.toISOString();
      setFormValue(test);
      setShowDetailModal(false);
      setShowModal(true);
    }
  };

  const addPopup = () => {
    setTitle("Add");
    setFormValue({ ...eventForm });
    setErrorData({ ...eventError });
    setSelectedDays([]);
    setIsChecked(false);
    setShowModal(true);
    setSelectedEvent(null);
  };

  const editSelected = async () => {
    setTitle("Edit");
    setSelectedDays([]);
    setErrorData({ ...eventError });
    setShowDetailModal(false);
    let test = formData;
    test = {
      name: selectedEvent.title,
      description: selectedEvent.desc,
      venue: selectedEvent.venue,
    };
    if (selectedEvent?.is_repeat === "Yes") {
      test = {
        ...test,
        startTime: moment(selectedEvent?.reccStartTime).format("HH:mm:ss"),
        endTime: moment(selectedEvent?.reccEndTime).format("HH:mm:ss"),
        fromDate: moment.utc(selectedEvent.fromDate).format("MM-DD-YYYY"),
        toDate: moment.utc(selectedEvent.toDate).format("MM-DD-YYYY"),
      };
      setSelectedDays(selectedEvent.days);
      setIsChecked(true);
    } else {
      test = {
        ...test,
        startDate: moment(selectedEvent.start).format(),
        endDate: moment(selectedEvent.end).format(),
      };
      setIsChecked(false);
    }
    setFormValue(test);
    setShowModal(true);
  };

  const editOnClick = async () => {
    if (validateForm()) {
      if (formData.is_weekly_repeat === "No") {
        formData["startDate"] = moment.utc(moment(formData.startDate)).format();
        formData["endDate"] = moment.utc(moment(formData.endDate)).format();
      }
      if (formData.is_weekly_repeat === "Yes") {
        formData["days"] = selectedDays;
        formData["startTime"] = formData.startTime;
        formData["endTime"] = formData.endTime;
        // formData["startTimeLocal"] = moment
        //   .utc(formData.startTime)
        //   .format("HH:mm:ss");
        // formData["endTimeLocal"] = moment
        //   .utc(formData.endTime)
        //   .format("HH:mm:ss");
        // formData["fromDate"] = moment
        //   .utc(moment(formData.fromDate))
        //   .format("YYYY-MM-DD");
        // formData["toDate"] = moment
        //   .utc(moment(formData.toDate))
        //   .format("YYYY-MM-DD");

        formData["startTimeLocal"] = moment(formData.startTime).format("HH:mm:ss");
        formData["endTimeLocal"] = moment(formData.endTime).format("HH:mm:ss");
        formData["fromDate"] = moment(formData.fromDate).format("YYYY-MM-DD");
        formData["toDate"] = moment(formData.toDate).format("YYYY-MM-DD");
      }
      if (selectedEvent.is_repeat === "Yes") {
        formData["days"] = selectedDays;
        formData["startTime"] = formData.startTime;
        formData["endTime"] = formData.endTime;
        // formData["startTimeLocal"] = moment(formData.startTime, "HH:mm:ss")
        //   .utc()
        //   .format("HH:mm:ss");
        // formData["endTimeLocal"] = moment(formData.endTime, "HH:mm:ss")
        //   .utc()
        //   .format("HH:mm:ss");
        // formData["fromDate"] = moment
        //   .utc(moment(formData.fromDate))
        //   .format("YYYY-MM-DD");
        // formData["toDate"] = moment
        //   .utc(moment(formData.toDate))
        //   .format("YYYY-MM-DD");
        formData["startTimeLocal"] = moment(formData.startTime, "HH:mm:ss").format("HH:mm:ss");
        formData["endTimeLocal"] = moment(formData.endTime, "HH:mm:ss").format("HH:mm:ss");
        formData["fromDate"] = moment(formData.fromDate).format("YYYY-MM-DD");
        formData["toDate"] = moment(formData.toDate).format("YYYY-MM-DD");
      }

      formData["timezone"] = Intl.DateTimeFormat().resolvedOptions().timeZone;

      await props.editEvent(selectedEvent.id, formData).then((data) => {
        if (data?.status) {
          setShowModal(false);
          setSuccessMessage(true);
          props.getEventList(currentDate);
          setTimeout(() => {
            dispatch({
              type: "CLEARDATA",
              payload: {},
            });
          }, 3000);
        }
      });
    }
  };

  const deleteSelected = async () => {
    await props.deleteEvent(selectedEvent.id);
    setShowDetailModal(false);
    await props.getEventList(currentDate);
    await props.getNotifications({ page: 0 });
    setTimeout(() => {
      dispatch({
        type: "CLEARDATA",
        payload: {},
      });
    }, 3000);
  };

  return (
    <IonContent scrollEvents={true}>
      <section className="calendar-view-wrapper">
        <div className="container-fluid">
          <div className="calendar-main">
            <div className="calendar-header">
              <IonGrid>
                <IonRow>
                  <IonCol size="6">
                    <div className="calendar-header-heading"></div>
                  </IonCol>
                  <IonCol size="6">
                    <div className="calendar-header-btn">
                      {isWeb ? (
                        <button
                          className="button-tab"
                          onClick={(event) => {
                            createCalendar();
                          }}
                        >
                          Export
                        </button>
                      ) : null}

                      {role == 1 ? (
                        <button
                          className="button-tab"
                          onClick={(event) => {
                            addPopup();
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            viewBox="0 0 24 24"
                          >
                            <path d="M19 4h-2V3a1 1 0 0 0-2 0v1H9V3a1 1 0 0 0-2 0v1H5a3 3 0 0 0-3 3v12a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3V7a3 3 0 0 0-3-3Zm1 15a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-7h16Zm0-9H4V7a1 1 0 0 1 1-1h2v1a1 1 0 0 0 2 0V6h6v1a1 1 0 0 0 2 0V6h2a1 1 0 0 1 1 1Z" />
                          </svg>
                          Add Event
                        </button>
                      ) : null}

                      {exportedCalender === true ? (
                        <div className="success-msg">
                          <p>{message}</p>
                        </div>
                      ) : null}

                      {props?.errorMsg && (
                        <div className="errormsg">
                          <p>{props.errorMsg}</p>
                        </div>
                      )}
                    </div>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </div>

            <div style={{ height: 700 }}>
              {(list.length > 0 || isBlankMonth) && (
                <Calendar
                  events={list}
                  style={{ height: 700 }}
                  localizer={localizer}
                  startAccessor="start"
                  endAccessor="end"
                  dayLayoutAlgorithm="no-overlap"
                  onSelectSlot={(event) => {
                    selectSlot(event);
                  }}
                  onSelectEvent={(event) => {
                    selectEvent(event);
                  }}
                  // selectable={true}
                  // popup
                  // popupStyle="custom-popup"
                  defaultView="month"
                  onNavigate={onNavigate}
                  eventPropGetter={eventStyleGetter}
                  views={["month", "week", "day"]}
                  showMultiDayTimes={true}
                />
              )}
            </div>
          </div>
          <IonModal
            isOpen={showModal}
            cssClass="calendar-addevent-modal calendar-add-event-main-modal-wrapper"
            backdropDismiss={false}
            swipeToClose={true}
            onDidDismiss={() => setShowModal(false)}
          >
            <div className="change-password-popup">
              <div className="popup-header">
                <h5>{title} Event</h5>

                <IonButton onClick={() => setShowModal(false)}>
                  <IonIcon icon={close} />
                </IonButton>
              </div>
              <form>
                <div className="form-group">
                  <IonLabel>
                    Name<sup>*</sup>
                  </IonLabel>
                  <div className="password-input">
                    <IonInput
                      type="text"
                      name="name"
                      value={formData.name}
                      onIonChange={(event) => {
                        handleChange("name", event);
                      }}
                    ></IonInput>
                    <p className="errormsg">
                      {" "}
                      {errorData?.name && errorData.name[0]}
                    </p>
                  </div>
                </div>

                <div className="form-group">
                  <IonLabel>Description</IonLabel>
                  <div className="password-input">
                    <IonInput
                      type="text"
                      name="description"
                      value={formData.description}
                      onIonChange={(event) => {
                        handleChange("description", event);
                      }}
                    ></IonInput>
                  </div>
                </div>

                <div className="form-group">
                  <IonLabel>Venue</IonLabel>
                  <div className="password-input">
                    <IonInput
                      type="text"
                      name="venue"
                      value={formData.venue}
                      onIonChange={(event) => {
                        handleChange("venue", event);
                      }}
                    ></IonInput>
                  </div>
                </div>
                {selectedEvent?.is_repeat !== "Yes" && (
                  <div className="form-group weekly-checkbox-wrapper">
                    <IonLabel>Repeat Weekly</IonLabel>
                    <IonCheckbox
                      slot="end"
                      checked={isChecked}
                      onIonChange={handleCheckboxChangeHide}
                      className="checkbox-div"
                    />
                  </div>
                )}

                {!isChecked && (
                  <>
                    <div className="form-group">
                      <IonLabel>
                        Start Date<sup>*</sup>
                      </IonLabel>
                      <div className="password-input">
                        <IonDatetime
                          max={new Date().getFullYear() + 3}
                          displayFormat="MM-DD-YYYY hh:mm A"
                          pickerFormat="MMMM DD  YYYY hh:mm A"
                          placeholder="Select Start Date"
                          value={formData.startDate}
                          onIonChange={(event) => {
                            handleChange("startDate", event);
                          }}
                        ></IonDatetime>
                        {errorData.startDate[0] ? (
                          <p className="errormsg">{errorData.startDate[0]}</p>
                        ) : null}
                      </div>
                    </div>
                    <div className="form-group">
                      <IonLabel>
                        End Date<sup>*</sup>
                      </IonLabel>
                      <div className="password-input">
                        <IonDatetime
                          max={new Date().getFullYear() + 3}
                          displayFormat="MM-DD-YYYY hh:mm A"
                          pickerFormat="MMMM DD  YYYY hh:mm A"
                          placeholder="Select End Date"
                          value={formData.endDate}
                          onIonChange={(event) => {
                            handleChange("endDate", event);
                          }}
                        ></IonDatetime>
                        {errorData.endDate[0] ? (
                          <p className="errormsg">{errorData.endDate[0]}</p>
                        ) : null}
                      </div>
                    </div>
                  </>
                )}
                {isChecked && (
                  <>
                    <div className="days-checkbox-wrapper">
                      {Object.keys(daysMapping).map((day, index) => (
                        <div key={index} className="days-checkbox-div">
                          <IonLabel>{day}</IonLabel>
                          <IonCheckbox
                            slot="end"
                            checked={selectedDays.includes(daysMapping[day])}
                            onIonChange={(event) =>
                              handleCheckboxChange(day, event)
                            }
                          />
                        </div>
                      ))}
                      {errorData.days[0] ? (
                        <p className="errormsg">{errorData.days[0]}</p>
                      ) : null}
                    </div>
                    <div className="form-group date-and-time-form-wrapper">
                      <IonLabel>
                        Recurring Start Date <sup>*</sup>
                      </IonLabel>
                      <div className="password-input">
                        <IonDatetime
                          displayFormat="MM-DD-YYYY "
                          pickerFormat="MMMM DD  YYYY "
                          max={new Date().getFullYear() + 3}
                          value={formData.fromDate}
                          onIonChange={(event) => {
                            handleChange("fromDate", event);
                          }}
                          placeholder="Select Start Date"
                        ></IonDatetime>
                        {errorData.fromDate[0] ? (
                          <p className="errormsg">{errorData.fromDate[0]}</p>
                        ) : null}
                      </div>
                    </div>
                    <div className="form-group date-and-time-form-wrapper">
                      <IonLabel>
                        Recurring End Date <sup>*</sup>
                      </IonLabel>
                      <div className="password-input">
                        <IonDatetime
                          max={new Date().getFullYear() + 3}
                          displayFormat="MM-DD-YYYY "
                          pickerFormat="MMMM DD  YYYY "
                          value={formData.toDate}
                          onIonChange={(event) => {
                            handleChange("toDate", event);
                          }}
                          placeholder="Select End Date"
                          // min={formData.fromDate}
                        ></IonDatetime>
                        {errorData.toDate[0] ? (
                          <p className="errormsg">{errorData.toDate[0]}</p>
                        ) : null}
                      </div>
                    </div>
                    <div className="form-group date-and-time-form-wrapper">
                      <IonLabel>
                        Start Time <sup>*</sup>
                      </IonLabel>
                      <div className="password-input">
                        <IonDatetime
                          displayFormat="hh:mm A"
                          pickerFormat="hh:mm A"
                          placeholder="Select Start Time"
                          value={formData.startTime}
                          onIonChange={(event) => {
                            handleChange("startTime", event);
                          }}
                        ></IonDatetime>
                        {errorData.startTime[0] ? (
                          <p className="errormsg">{errorData.startTime[0]}</p>
                        ) : null}
                      </div>
                    </div>
                    <div className="form-group date-and-time-form-wrapper">
                      <IonLabel>
                        End Time <sup>*</sup>
                      </IonLabel>
                      <div className="password-input">
                        <IonDatetime
                          displayFormat="hh:mm A"
                          pickerFormat="hh:mm A"
                          placeholder="Select End Time"
                          value={formData.endTime}
                          onIonChange={(event) => {
                            handleChange("endTime", event);
                          }}
                        ></IonDatetime>
                        {errorData.endTime[0] ? (
                          <p className="errormsg">{errorData.endTime[0]}</p>
                        ) : null}
                      </div>
                    </div>
                  </>
                )}

                <div className="form-group-center">
                  {title === "Add" ? (
                    <IonButton
                      onClick={(event) => {
                        SubmitEvent(event);
                      }}
                    >
                      Add Event{" "}
                      {(() => {
                        if (props.loading) {
                          return (
                            <IonSpinner
                              name="crescent"
                              className="spinner-loader"
                            />
                          );
                        }
                      })()}
                    </IonButton>
                  ) : (
                    <IonButton
                      onClick={(event) => {
                        editOnClick();
                      }}
                    >
                      Update{" "}
                      {(() => {
                        if (props.loading) {
                          return (
                            <IonSpinner
                              name="crescent"
                              className="spinner-loader"
                            />
                          );
                        }
                      })()}
                    </IonButton>
                  )}
                </div>
              </form>
            </div>
          </IonModal>
          <IonModal
            isOpen={showDetailModal}
            cssClass="calendar-viewevent-modal calendar-view-event-main-modal-wrapper"
            backdropDismiss={false}
            swipeToClose={true}
            onDidDismiss={() => setShowDetailModal(false)}
          >
            <div className="change-password-popup">
              <div className="popup-header">
                <h5> Event</h5>
                <IonButton onClick={() => setShowDetailModal(false)}>
                  <IonIcon icon={close} />
                </IonButton>
              </div>

              <div>
                <div className="container-spacing">
                  <div className="event-main-display-div">
                    <div className="event-content">
                      <div className="event-title">
                        <p>Title:</p>
                      </div>
                      <div className="event-detail">
                        <p>
                          {selectedEvent?.title
                            ? selectedEvent?.title
                            : selectedEvent?.name}
                        </p>
                      </div>
                    </div>
                    <div className="event-content">
                      <div className="event-title">
                        <p>Description:</p>
                      </div>
                      <div className="event-detail">
                        <p>
                          {selectedEvent?.desc
                            ? selectedEvent?.desc
                            : selectedEvent?.description}
                        </p>
                      </div>
                    </div>
                    <div className="event-content">
                      <div className="event-title">
                        <p>Venue:</p>
                      </div>
                      <div className="event-detail">
                        <p>{selectedEvent?.venue}</p>
                      </div>
                    </div>
                    {selectedEvent?.is_repeat === "Yes" && (
                      <>
                        <div className="event-content">
                          <div className="event-title">
                            <p>Recurring start date:</p>
                          </div>
                          <div className="event-detail">
                            <p>{formatDate(selectedEvent?.fromDate)}</p>
                          </div>
                        </div>
                        <div className="event-content">
                          <div className="event-title">
                            <p>Recurring end date:</p>
                          </div>
                          <div className="event-detail">
                            <p>{formatDate(selectedEvent?.toDate)}</p>
                          </div>
                        </div>
                        <div className="event-content">
                          <div className="event-title">
                            <p>Days:</p>
                          </div>
                          <div className="event-detail">
                            <p>
                              {selectedEvent?.days
                                .map((e) => Object.keys(daysMapping)[e])
                                .join(", ")}
                            </p>
                          </div>
                        </div>
                      </>
                    )}
                    {selectedEvent?.is_repeat === "No" && (
                      <>
                        <div className="event-content">
                          <div className="event-title">
                            <p>Start Date:</p>
                          </div>
                          <div className="event-detail">
                            <p>{formatDateTime(selectedEvent?.start)}</p>
                          </div>
                        </div>
                        <div className="event-content">
                          <div className="event-title">
                            <p>End Date:</p>
                          </div>
                          <div className="event-detail">
                            <p>{formatDateTime(selectedEvent?.end)}</p>
                          </div>
                        </div>
                      </>
                    )}
                    {selectedEvent?.is_repeat === "Yes" && (
                      <>
                        <div className="event-content">
                          <div className="event-title">
                            <p>Start Time:</p>
                          </div>
                          <div className="event-detail">
                            <p>
                              {moment(selectedEvent?.reccStartTime).format(
                                "hh:mm A"
                              )}
                            </p>
                          </div>
                        </div>
                        <div className="event-content">
                          <div className="event-title">
                            <p>End Time:</p>
                          </div>
                          <div className="event-detail">
                            <p>
                              {moment(selectedEvent?.reccEndTime).format(
                                "hh:mm A"
                              )}
                            </p>
                          </div>
                        </div>
                      </>
                    )}
                    <div className="event-content">
                      <div className="event-title">
                        <p>Is Repeat:</p>
                      </div>
                      <div className="event-detail">
                        <p>{selectedEvent?.is_repeat}</p>
                      </div>
                    </div>
                  </div>
                  {role === 1 ? (
                    <div className="button-event">
                      <IonButton
                        onClick={(event) => {
                          editSelected();
                        }}
                        className="button-sub-event"
                      >
                        Edit
                      </IonButton>
                      <IonButton
                        onClick={(event) => {
                          setShowAlert(true);
                        }}
                        className="button-sub-event"
                      >
                        Delete
                      </IonButton>
                    </div>
                  ) : null}
                </div>
                <div className="container-spacing d-none">
                  <div className="calendar-viewevent-table">
                    <div className="dashtable-responsive">
                      <table className="table">
                        <tbody>
                          <tr>
                            <th>
                              <p>Title:</p>
                            </th>
                            <td>
                              <p>
                                {selectedEvent?.title
                                  ? selectedEvent?.title
                                  : selectedEvent?.name}
                              </p>
                              {/* <p>{selectedEvent?.name}</p> */}
                            </td>
                          </tr>
                          <tr>
                            <th>
                              <p>Description:</p>
                            </th>
                            <td>
                              <p>
                                {selectedEvent?.desc
                                  ? selectedEvent?.desc
                                  : selectedEvent?.description}
                              </p>
                              {/* <p>{selectedEvent?.description}</p> */}
                            </td>
                          </tr>
                          <tr>
                            <th>
                              <p>Venue:</p>
                            </th>
                            <td>
                              <p>{selectedEvent?.venue}</p>
                            </td>
                          </tr>
                          {selectedEvent?.is_repeat === "Yes" && (
                            <>
                              <tr>
                                <th>
                                  <p>Recurring start date:</p>
                                </th>
                                <td>
                                  <p> {formatDate(selectedEvent?.fromDate)}</p>
                                </td>
                              </tr>
                              <tr>
                                <th>
                                  <p>Recurring end date:</p>
                                </th>
                                <td>
                                  <p> {formatDate(selectedEvent?.toDate)}</p>
                                </td>
                              </tr>
                              <tr>
                                <th>
                                  <p>Days:</p>
                                </th>
                                <td>
                                  <p>
                                    {selectedEvent?.days
                                      .map((e) => Object.keys(daysMapping)[e])
                                      .join(", ")}
                                  </p>
                                </td>
                              </tr>
                            </>
                          )}

                          {selectedEvent?.is_repeat === "No" && (
                            <>
                              <tr>
                                <th>
                                  <p>Start Date:</p>
                                </th>
                                <td>
                                  <p> {formatDateTime(selectedEvent?.start)}</p>
                                </td>
                              </tr>
                              <tr>
                                <th>
                                  <p>End Date:</p>
                                </th>
                                <td>
                                  <p> {formatDateTime(selectedEvent?.end)}</p>
                                </td>
                              </tr>
                            </>
                          )}
                          {selectedEvent?.is_repeat === "Yes" && (
                            <>
                              <tr>
                                <th>
                                  <p>Start Time:</p>
                                </th>
                                <td>
                                  <p> {formatTime(selectedEvent?.start)}</p>
                                </td>
                              </tr>
                              <tr>
                                <th>
                                  <p>End Time:</p>
                                </th>
                                <td>
                                  <p> {formatTime(selectedEvent?.end)}</p>
                                </td>
                              </tr>
                            </>
                          )}

                          <tr>
                            <th>
                              <p>Is Repeat:</p>
                            </th>
                            <td>
                              <p> {selectedEvent?.is_repeat}</p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </IonModal>
          <IonAlert
            isOpen={showAlert}
            onDidDismiss={() => setShowAlert(false)}
            cssClass="my-custom-class"
            header={"Alert"}
            message={"Do you really want to delete this event?"}
            buttons={[
              {
                text: "No",
                role: "cancel",
                cssClass: "secondary",
                handler: (blah) => {},
              },
              {
                text: "Yes",
                handler: () => {
                  deleteSelected();
                },
              },
            ]}
          />
        </div>
      </section>
    </IonContent>
  );
};

const mapStateToProps = (state) => {
  return {
    isRegistered: state.authReducer.isRegistered,
    isLoggedIn: state.authReducer.isLoggedIn,
    userToken: state.authReducer.userToken,
    // errorMsg: state.commonReducer.errorMsg,
    errorData: state.commonReducer.errorData,
    authData: state.authReducer.authData,
    // successMsg: state.commonReducer.successMsg,
    // authData: state.authReducer.authData,
    // isEventAdded: state.zoomReducer.isEventAdded,
    eventList: state.zoomReducer.eventList,
    type: state.zoomReducer.type,
    loading: state.commonReducer.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { addEvent, getEventList, deleteEvent, editEvent, getNotifications },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CalendarClass)
);
