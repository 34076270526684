import {
  IonContent,
  IonInput,
  IonButton,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
  IonSpinner,
  IonIcon,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { eyeOutline, eyeOffOutline } from "ionicons/icons";
import { uploadImage } from "../Utils/fileUpload";
import { BUCKET_NAME } from "../Constants/constants";
import { menuController } from "@ionic/core";
import loginlogo from "../images/logo-blue.png";
import { getPlatforms } from "@ionic/react";
import "./signup.css";
import { connect } from "react-redux";
import { signAction } from "../redux/actions/authAction";
import { bindActionCreators } from "redux";
import { ClearData } from "../redux/actions/commonAction";
import { Link } from "react-router-dom";
import PrivatePolicy from "./privatePolicy";
import TNC from "./termsAndConditions";
import { useDispatch } from "react-redux";
// import GoogleLogin from "./googleLogin";
// import FacebookLogin from "./facebookLogin";
import OtpVerification from "../components/addOtpModel/otpVerification";

const Signup = (props) => {
  const dispatch = useDispatch();
  const [formData, setFormValue] = useState({
    firstName: "",
    lastName: "",
    contactNo: "",
    email: "",
    password: "",
    confirmPassword: "",
    teamName: "",
  });
  const [errorData, setErrorData] = useState({
    firstName: [],
    lastName: [],
    email: [],
    password: [],
    confirmPassword: [],
    teamName: [],
  });
  const [privatePolicy, setPrivatePolicy] = useState(false);
  const [isShowPassword, setPasswordType] = useState(false);
  const [isShowConfirmPassword, setConfirmPasswordType] = useState(false);
  const [tnc, setTnc] = useState(false);
  const [isWeb, setWeb] = useState(false);

  useEffect(() => {
    props.ClearData();
    menuController.enable(false);
    let webb = getPlatforms().some(
      (r) => ["desktop", "mobileweb"].indexOf(r) >= 0
    );

    if (!webb) {
      setWeb(false);
    } else {
      setWeb(true);
    }
  }, []);

  async function onSubmitForm(event) {
    if (validateForm()) {
      const response = await props.signAction(formData);
      if (response && response.success === true && response.message) {
        dispatch({
          type: "POPUP_MODEL",
          payload: true,
        });
      }
    }
  }

  const handleChange = (formField, event) => {
    if (formField === "teamLogo") {
      let file = event.target.files[0];
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = async () => {
        if (reader?.result) {
          const imagePath = await uploadImage(
            { dataUrl: reader.result, format: "png" },
            BUCKET_NAME,
            `${formData.email}/user-team-logo`
          );
          let test = formData;
          test["teamLogo"] = imagePath;
          setFormValue(test);
          // await props.editUser(formData);
          // let adminId = localStorage.getItem("userData");
        }
      };
    } else {
      let test = formData;
      test[formField] = event.target.value;
      setFormValue(test);
    }
  };

  const validateForm = () => {
    let isFormValid = true;
    let testError = {
      firstName: [],
      lastName: [],
      email: [],
      password: [],
      confirmPassword: [],
      teamLogo: [],
      teamName: [],
    };
    if (formData.email.trim().length === 0) {
      isFormValid = false;
      testError.email = ["This field is required."];
    }
    if (
      formData.email.trim().length > 0 &&
      /^([a-zA-Z0-9_\.\-]+)@([a-zA-Z0-9_\.\-]+)\.([a-zA-Z]{2,5})$/.test(
        formData.email
      ) === false
    ) {
      isFormValid = false;
      testError.email = ["Email must be a valid email address"];
    }
    if (formData.password.trim().length === 0) {
      isFormValid = false;
      testError.password = ["This field is required."];
    }
    if (
      formData.password.trim().length > 0 &&
      (formData.password.trim().length < 6 ||
        formData.password.trim().length > 15)
    ) {
      isFormValid = false;
      testError.password = [
        "Password length must be between 6 to 15 characters",
      ];
    }
    if (formData.confirmPassword.trim().length === 0) {
      isFormValid = false;
      testError.confirmPassword = ["This field is required."];
    }
    if (
      formData.confirmPassword.trim().length > 0 &&
      (formData.confirmPassword.trim().length < 6 ||
        formData.confirmPassword.trim().length > 15)
    ) {
      isFormValid = false;
      testError.confirmPassword = [
        "Confirm Password length must be between 6 to 15 characters",
      ];
    }
    if (
      formData.confirmPassword.trim().length > 0 &&
      formData.confirmPassword.trim().length > 0 &&
      formData.password.trim() !== formData.confirmPassword.trim()
    ) {
      isFormValid = false;
      testError.confirmPassword = ["Passwords Don’t Match!"];
    }

    if (formData.firstName.trim().length === 0) {
      isFormValid = false;
      testError.firstName = ["This field is required."];
    }
    
    if (
      formData.firstName.trim().length > 0 &&
      formData.firstName.trim().length > 50
    ) {
      isFormValid = false;
      testError.firstName = ["Name length cannot be gretar than 50"];
    }
    //LastName
    if (formData.lastName.trim().length === 0) {
      isFormValid = false;
      testError.lastName = ["This field is required."];
    }
    if (
      formData.lastName.trim().length > 0 &&
      formData.lastName.trim().length > 50
    ) {
      isFormValid = false;
      testError.lastName = ["Name length cannot be gretar than 50"];
    }

    if (formData.teamName.trim().length === 0) {
      isFormValid = false;
      testError.teamName = ["This field is required."];
    }
    if (
      formData.teamName.trim().length > 0 &&
      formData.teamName.trim().length > 50
    ) {
      isFormValid = false;
      testError.teamName = ["Team Name length cannot be gretar than 50"];
    }
    // if(formData.teamLogo.trim().length === 0){
    //   isFormValid=false
    //   testError.teamLogo=["Team Logo is required"]
    // }
    setErrorData(testError);
    return isFormValid;
  };
  return (
    <IonContent>
      <div className="signup">
        <div className="login-inner">
          <div className="login-logo">
            <IonImg src={loginlogo} alt="logo"></IonImg>
          </div>
          <div className="login-tagline">
            <h3>Inspired by Coaches</h3>
          </div>

          <div className="login-head">
            <h2>Sign up</h2>
            <div className="errormsg">
              <p>{props.errorMsg}</p>
            </div>
          </div>

          <div className="login-body">
            <form>
              <IonGrid>
                <IonRow>
                  <IonCol size="12" size-md="6">
                    <div className="form-group">
                      <IonLabel>
                        First Name<sup>*</sup>
                      </IonLabel>
                      <IonInput
                        type="text"
                        name="firstName"
                        className="form-control"
                        onIonChange={(event) => {
                          handleChange("firstName", event);
                        }}
                      ></IonInput>
                      {errorData?.firstName[0] ? (
                        <p>{errorData?.firstName[0]}</p>
                      ) : null}
                    </div>
                  </IonCol>

                  <IonCol size="12" size-md="6">
                    <div className="form-group">
                      <IonLabel>
                        Last Name<sup>*</sup>
                      </IonLabel>
                      <IonInput
                        type="text"
                        name="lastName"
                        className="form-control"
                        onIonChange={(event) => {
                          handleChange("lastName", event);
                        }}
                      ></IonInput>
                      {errorData?.lastName[0] ? (
                        <p>{errorData?.lastName[0]}</p>
                      ) : null}
                    </div>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol size="12" size-md="12">
                    <div className="form-group">
                      <IonLabel>
                        Email address<sup>*</sup>
                      </IonLabel>
                      <IonInput
                        type="email"
                        name="email"
                        className="form-control"
                        onIonChange={(event) => {
                          handleChange("email", event);
                        }}
                      ></IonInput>
                      {errorData?.email[0] ? (
                        <p>{errorData?.email[0]}</p>
                      ) : null}
                    </div>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol size="12" size-md="6">
                    <div className="form-group">
                      <IonLabel>Mobile Number</IonLabel>
                      <IonInput
                        type="number"
                        name="contactNo"
                        className="form-control"
                        onIonChange={(event) => {
                          handleChange("contactNo", event);
                        }}
                      ></IonInput>
                    </div>
                  </IonCol>

                  <IonCol size="12" size-md="6">
                    <div className="form-group">
                      <IonLabel>
                        Team Name<sup>*</sup>
                      </IonLabel>
                      <IonInput
                        type="text"
                        name="teamName"
                        className="form-control"
                        onIonChange={(event) => {
                          handleChange("teamName", event);
                        }}
                      ></IonInput>
                      {errorData?.teamName[0] ? (
                        <p>{errorData?.teamName[0]}</p>
                      ) : null}
                    </div>
                  </IonCol>

                  <IonCol size="12" size-md="6">
                    <div className="form-group">
                      <IonLabel>
                        Password<sup>*</sup>
                      </IonLabel>
                      <div className="showpassword-input">
                        <IonInput
                          type={isShowPassword == true ? "text" : "password"}
                          name="password"
                          className="form-control"
                          onIonChange={(event) => {
                            handleChange("password", event);
                          }}
                        ></IonInput>
                        {isShowPassword === true ? (
                          <a onClick={() => setPasswordType(false)}>
                            <IonIcon icon={eyeOutline} />
                          </a>
                        ) : (
                          <a onClick={() => setPasswordType(true)}>
                            <IonIcon icon={eyeOffOutline} />
                          </a>
                        )}
                      </div>

                      {errorData?.password[0] ? (
                        <p>{errorData?.password[0]}</p>
                      ) : null}
                    </div>
                  </IonCol>

                  <IonCol size="12" size-md="6">
                    <div className="form-group">
                      <IonLabel>
                        Confirm password<sup>*</sup>
                      </IonLabel>
                      <div className="showpassword-input">
                        <IonInput
                          type={
                            isShowConfirmPassword == true ? "text" : "password"
                          }
                          name="confirmPassword"
                          className="form-control"
                          onIonChange={(event) => {
                            handleChange("confirmPassword", event);
                          }}
                        ></IonInput>
                        {isShowConfirmPassword === true ? (
                          <a onClick={() => setConfirmPasswordType(false)}>
                            <IonIcon icon={eyeOutline} />
                          </a>
                        ) : (
                          <a onClick={() => setConfirmPasswordType(true)}>
                            <IonIcon icon={eyeOffOutline} />
                          </a>
                        )}
                      </div>

                      {errorData?.confirmPassword[0] ? (
                        <p>{errorData?.confirmPassword[0]}</p>
                      ) : null}
                    </div>
                  </IonCol>
                </IonRow>

                {/* <IonRow>
                  <IonCol size="12" size-md="12">
                    <div className="form-group">
                      <IonLabel>Logo</IonLabel>
                      <input
                        type="file"
                        accept="image/*"
                        name="teamLogo"
                        className="form-control"
                        onChange={(event)=>{handleChange("teamLogo",event)}}
                      ></input>
                      {errorData?.confirmPassword[0] ? (
                  <p>{errorData?.confirmPassword[0]}</p>
                ) : null}
                    </div>
                  </IonCol>
                </IonRow> */}
              </IonGrid>
              {/* <div className="forgot-password">
                <Link to={"/signup"}>Don't have Account? SignUp</Link>
                <a className="forgot-text">Forgot Password?</a>
                <Link to="/login" className="forgot-text">
                  {" "}
                  Have Account? Login
                </Link>
              </div> */}
              <div className="button-login">
                <IonButton
                  color="none"
                  className="gradient-btn-blue"
                  onClick={(event) => {
                    onSubmitForm(event);
                  }}
                >
                  Sign up
                  {(() => {
                    if (props.loading) {
                      return (
                        <IonSpinner
                          name="crescent"
                          className="spinner-loader"
                        />
                      );
                    }
                  })()}
                </IonButton>
              </div>
              <div className="haveaccount">
                <p>
                  Already have an account? <Link to="/login"> Log In</Link>
                </p>
              </div>

              <OtpVerification
                name="signup-otp"
                formData={formData}

                // setShowModal={setShowModal}
                // showModal={showModal}
                // setSuccessMessage={setSuccessMessage}
                // cleanUpUser={props.cleanUpUserDetails}
              />

              {/*   <div className="button-login">
                {isWeb === true ? (
                  <div className="login-social-heading">
                    <p>Or Sign Up with</p>
                  </div>
                ) : null}
                <div className="login-social-btn">
                {isWeb === true ? <GoogleLogin></GoogleLogin> : null}
                {isWeb === true ? <FacebookLogin></FacebookLogin> : null}

              </div> 
              </div>*/}
              <div className="private-policy">
                <p>
                  By logging in, you agree to our<br></br>{" "}
                  <a
                    href="#"
                    onClick={() => {
                      setPrivatePolicy(true);
                    }}
                  >
                    Privacy Policy
                  </a>{" "}
                  and{" "}
                  <a
                    href="#"
                    onClick={() => {
                      setTnc(true);
                    }}
                  >
                    Terms & Conditions
                  </a>
                  .
                </p>
              </div>
            </form>
            <PrivatePolicy
              showPrivatePolicy={privatePolicy}
              setPrivatePolicy={() => {
                setPrivatePolicy(false);
              }}
            />
            <TNC
              showPrivatePolicy={tnc}
              setPrivatePolicy={() => {
                setTnc(false);
              }}
            />
          </div>
        </div>
      </div>
    </IonContent>
  );
};

const mapStateToProps = (state) => {
  return {
    isRegistered: state.authReducer.isRegistered,
    errorMsg: state.commonReducer.errorMsg,
    errorData: state.commonReducer.errorData,
    successMsg: state.commonReducer.successMsg,
    loading: state.commonReducer.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ signAction, ClearData }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Signup);
