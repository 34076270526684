import {
  IonContent,
  IonInput,
  IonButton,
  IonModal,
  IonIcon,
  IonSpinner,
} from "@ionic/react";
import React, { useEffect, useRef, useState } from "react";
import { close } from "ionicons/icons";
import "./otpverification.css";
import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { handleError, ClearData } from "../../redux/actions/commonAction";
import OtpInput from "react-otp-input";
import { bindActionCreators } from "redux";
import {
  otpVerificationAction,
  reSendOtpAction,
} from "../../redux/actions/authAction";

const OtpVerification = (props) => {
  const currentEmail = props?.formData?.email;
  const dispatch = useDispatch();
  const [otp, setOtp] = useState("");

  const handleClose = () => {
    setOtp("");
    props.ClearData();
    dispatch({
      type: "POPUP_MODEL",
      payload: false,
    });
  };

  async function onResendOtp(event) {
    setOtp("");
    const payload = {
      email: currentEmail,
      identifier: props.name,
    };
    const response = await props.reSendOtpAction(payload);
    if (response && response.success === true && response.message) {
      dispatch({
        type: "SUCCESS",
        payload: { successMsg: response.message },
      });
    }
  }

  async function onSubmitForm(event) {
    const payload = {
      email: currentEmail,
      otp: otp,
      identifier: props.name,
    };
    if (otp) {
      const response = await props.otpVerificationAction(payload);
      if (response && response.success === true && response.message) {
        //login otp
        if (props.name == "login-otp") {
          dispatch({
            type: "CHECK_PAYMENT",
            checkIsPayment: response?.user.subscribed,
          });

          dispatch({
            type: "LOGIN",
            payload: { data: response.user },
            checkUserRole: response?.user.role,
          });
          dispatch({
            type: "SUCCESS",
            payload: { successMsg: response.message },
          });
          dispatch({ type: "LOADING_COMPLETED" });
          props.history.push("/page/payment");
        }

        dispatch({
          type: "POPUP_MODEL",
          payload: false,
        });
        dispatch({
          type: "SUCCESS",
          payload: { successMsg: response.message },
        });
        //props.ClearData();
        props.history.push("/login");
      }
    }
  }

  return (
    <IonModal
      onDidDismiss={handleClose}
      isOpen={props.setOpenModel}
      cssClass="addnew-user-popup otp-modal-wrapper otp-verification-modal"
      swipeToClose={false}
    >
      <div className="change-password">
        <div className="login-inner">
          <div className="login-head verification-head">
            <h2>OTP Verification</h2>
            <p className="enter-otp-title">
              Please enter OTP verification code sent to your email
            </p>
            <p className="otp-mail-text"> {currentEmail}</p>
          </div>

          <div className="verification-input-body">
            <div className="input-wrapper">
              <div className="input-div">
                <OtpInput
                  value={otp}
                  onChange={setOtp}
                  inputStyle={`${props?.errorMsg ? "error-text-input" : ""}`}
                  numInputs={4}
                  style={props?.errorMsg ? "error-text-input" : ""}
                  renderSeparator={<span>-</span>}
                  renderInput={(props) => <input {...props} />}
                />
              </div>
              {props?.errorMsg ? (
                <div>
                  <p className="errormsg alert-msg-text">{props?.errorMsg}</p>
                </div>
              ) : props?.successMsg ? (
                <div>
                  <p className="successmsg alert-msg-text">
                    {" "}
                    {props.successMsg}
                  </p>
                </div>
              ) : null}
            </div>

            {/* {props?.errorMsg ? (
              <div>
                <p className="errormsg alert-msg-text">{props?.errorMsg}</p>
              </div>
            ) : props?.successMsg ? (
              <div>
                <p className="successmsg alert-msg-text"> {props.successMsg}</p>
              </div>
            ) : null} */}

            <div className="verification-btn-div">
              <p className="receive-code-text">
                Didn’t receive code?{" "}
                <a
                  href="javascript:void(0)"
                  onClick={(event) => {
                    onResendOtp(event);
                  }}
                >
                  Resend
                </a>
              </p>
              <IonButton
                color="none"
                disabled={!otp || otp.length !== 4}
                className="gradient-btn-blue"
                onClick={(event) => {
                  onSubmitForm(event);
                }}
              >
                Verify
                {(() => {
                  if (props.otpLoading) {
                    return (
                      <IonSpinner name="crescent" className="spinner-loader" />
                    );
                  }
                })()}
              </IonButton>
            </div>
          </div>
        </div>
      </div>
    </IonModal>
  );
};

const mapStateToProps = (state) => {
  return {
    errorMsg: state.commonReducer.errorMsg,
    successMsg: state.commonReducer.successMsg,
    otpLoading: state.commonReducer.otpLoading,
    setOpenModel: state.commonReducer.setOpenModel,
    isLoggedIn: state.authReducer.isLoggedIn,
    isRegistered: state.authReducer.isRegistered,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { handleError, ClearData, otpVerificationAction, reSendOtpAction },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OtpVerification)
);
