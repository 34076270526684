import swa_gifnoc from "../Config/aws-config";
// import { toast } from "../Utils/toast";
const AWS = require("aws-sdk");

export const uploadImage = async (fileData, bucketName, filePath) => {
  return new Promise((resolve, reject) => {
    AWS.config.update(swa_gifnoc);
    const bucket = new AWS.S3({ params: { Bucket: bucketName } });
    console.log("bucket", bucket);
    let r = Math.random().toString(36).substring(7);
    const base64Data = new Buffer.from(
      fileData.dataUrl.replace(/^data:image\/\w+;base64,/, ""),
      "base64"
    );
    const params = {
      Key: `${filePath}/${r}`, // file will be saved as testBucket/contacts.csv
      Body: base64Data,
      ContentEncoding: "base64",
      ContentType: `image/${fileData.format}`,
      ACL: "public-read",
    };
    bucket.upload(params, function (s3Err, data) {
      console.log("s3Err", s3Err);
      console.log("data", data);
      if (s3Err) {
        //   dispatch({ type: "LOADING_FAILURE", payload: { error: null } });
        //   toast.error(s3Err);
        throw s3Err;
      }
      // dispatch({ type: "UPLOAD_PROFILE_IMAGE", payload: data.Location });
      resolve(data.Location);
    });
  });
};

export const uploadFile = async (fileData, bucketName, filePath) => {
  return new Promise((resolve, reject) => {
    AWS.config.update(swa_gifnoc);
    const bucket = new AWS.S3({ params: { Bucket: bucketName } });
    const params = {
      Key: `${filePath}/${fileData.name}`, // file will be saved as testBucket/contacts.csv
      Body: fileData,
      ContentType: fileData && fileData.type,
      ACL: "public-read",
    };
    bucket.upload(params, function (s3Err, data) {
      if (s3Err) {
        //   dispatch({ type: "LOADING_FAILURE", payload: { error: null } });

        //   toast.error(s3Err);
        throw s3Err;
      }
      // dispatch({ type: "UPLOAD_PROFILE_IMAGE", payload: data.Location });
      resolve(data.Location);
    });
  });
};
