import React, { useState, useEffect, useRef } from "react";
import { IonIcon, IonCard, IonCardHeader, IonCardContent } from "@ionic/react";
import ViewReportModel from "./viewReportModel";
import moment from "moment";
import ReactPaginate from "react-paginate";
import { connect } from "react-redux";
import {
  checkPlayerReporting,
  cleanUpData,
  getAllWeeklyUsers,
  clearReportingSystem,
} from "../../redux/actions/prsAction";
import { bindActionCreators } from "redux";
import { eyeOutline } from "ionicons/icons";
import "./playerReportingSystem.css";

const GoalsReport = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [totalPage, setTotalPage] = useState();
  const rowNumbers = [5, 10, 15, 20, 25];

  async function handlePageClick(e) {
    await props.getAllWeeklyUsers(
      "weekly_checkin",
      e.selected + 1,
      selectedRows
    );
  }

  const [selectedRows, setSelectedRows] = useState(5);

  const handleSelectChange = (event) => {
    setSelectedRows(event.target.value);
  };

  useEffect(() => {
    setTotalPage(props?.getLastPage);
  }, [props?.getLastPage]);

  useEffect(() => {
    props.clearReportingSystem();
    componentDidMount();
    return function cleanup() {
      props.clearReportingSystem();
    };
  }, [selectedRows]);

  async function componentDidMount() {
    await props.getAllWeeklyUsers("goals", 1, selectedRows);
  }

  async function onClickView(player_id, ans_grp_id) {
    setShowModal(true);
    await props.checkPlayerReporting("goals", player_id, ans_grp_id);
  }

  return (
    <div>
      <div className="user-inner-body coach-report-user-display-wrapper">
        <IonCard className="user-dashboard-card coach-report-dashboard-div">
          {/* <IonCardHeader className="user-card-head">
            <h3>Goals</h3>
          </IonCardHeader> */}

          <IonCardContent className="dashboard-card-body coach-table-dispaly-div">
            <div className="dash-table table-main-div">
              <div className="dashtable-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th>
                        <p>Name</p>
                      </th>
                      <th>
                        <p>Last Updated</p>
                      </th>
                      <th>
                        <p>Action</p>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {props?.getAllWeeklyReport?.length > 0 ? (
                      props.getAllWeeklyReport?.map((data, i) => {
                        return (
                          <tr>
                            <td>
                              <div className="coach-table-td-wrapper">
                                <div className="player-image-div">
                                <img
                                    src={
                                      data.imagename ||
                                      "/assets/icon/client-dummy.png"
                                    }
                                    alt="profileimage"
                                  />
                                </div>
                                <p>{data.fullname}</p>
                              </div>
                            </td>
                            <td>
                              <p>
                                {moment(data.created_ts).format(
                                  "MM-DD-YYYY hh:mm A"
                                )}
                              </p>
                            </td>
                            <td>
                              <p className="actionitem">
                                <a
                                  onClick={() =>
                                    onClickView(data.player_id, data.ans_grp_id)
                                  }
                                >
                                  <IonIcon icon={eyeOutline} />
                                </a>
                              </p>
                            </td>
                          </tr>
                        );
                      })
                    ) : props.loading === true ? null : (
                      <p style={{ padding: 10 }}>No Goals Submitted</p>
                    )}
                    {props.loading === true ? (
                      <p style={{ padding: 10 }}>Loading...</p>
                    ) : null}
                  </tbody>
                </table>
                {props?.getAllWeeklyReport?.length > 0 && (
                  <div className="sportal-pagination-main-div">
                    <div className="sportal-pagination-wrapper">
                      <ReactPaginate
                        previousLabel={"<"}
                        nextLabel={">"}
                        pageCount={totalPage}
                        onPageChange={handlePageClick}
                        marginPagesDisplayed={0}
                        pageRangeDisplayed={5}
                        breakLabel="..."
                        containerClassName={"pagination"}
                        previousLinkClassName={"pagination__link"}
                        nextLinkClassName={"pagination__link"}
                        disabledClassName={"pagination__link--disabled"}
                        activeClassName={"pagination__link--active"}
                      />
                    </div>
                    <div className="sportal-rows-page-div">
                      <label htmlFor="invoiceDropdown">Rows:</label>
                      <select
                        id="invoiceDropdown"
                        onChange={handleSelectChange}
                        value={selectedRows}
                      >
                        <option value="" disabled>
                          Rows
                        </option>
                        {rowNumbers.map((rowsNumbers) => (
                          <option key={rowsNumbers} value={rowsNumbers}>
                            {rowsNumbers}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </IonCardContent>
        </IonCard>
        <ViewReportModel
          showModal={showModal}
          setShowModal={setShowModal}
        ></ViewReportModel>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.commonReducer.loading,
    getAllWeeklyReport: state.prsReducer.getAllWeeklyReport,
    getLastPage: state.prsReducer.getLastPage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      checkPlayerReporting,
      cleanUpData,
      getAllWeeklyUsers,
      clearReportingSystem,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(GoalsReport);
