import React, { useState, useEffect } from "react";
import {
  IonContent,
  IonCardTitle,
  IonIcon,
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonCardHeader,
  IonImg,
  IonAlert,
} from "@ionic/react";
import Profile from "./profile";
import ProfileImg from "../../images/client-dummy.png";
import "./myProfile.css";
import { pencilSharp, trash } from "ionicons/icons";
import { Plugins, CameraResultType } from "@capacitor/core";
import { defineCustomElements } from "@ionic/pwa-elements/loader";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";

const { Camera } = Plugins;

const MyProfile = (props) => {
  const { history } = props;

  const [user, setUser] = useState({});
  const [photo, setPhoto] = useState(null);
  const [editProfile, setEditProfile] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  let imageObject;

  useEffect(() => {
    let getUser = localStorage.getItem("userData");
    getUser = JSON.parse(getUser);
    setUser(getUser);
    setPhoto({ dataUrl: getUser?.imagename ? getUser.imagename : null });
    defineCustomElements(window);
  }, []);

  async function takePicture() {
    const image = await Camera.getPhoto({
      quality: 90,
      allowEditing: true,
      resultType: CameraResultType.DataUrl,
    });

    var imageUrl = image.dataUrl;

    if (imageUrl.toLowerCase().includes("image/")) {
      setPhoto(image);
      imageObject = image;
      setErrorMessage(false);
    } else {
      setErrorMessage(true);
    }
  }

  const clearImage = (image) => {
    setPhoto(null);
  };
  return (
    <IonContent>
      <div className="container">
        <div className="profile-start">
          <div className="profile-info">
            <IonCardHeader>
              <IonCardTitle className="inner-page-heading">
                {history?.location?.state?.id ? (
                  <span>User Profile</span>
                ) : (
                  <span>My Profile</span>
                )}
              </IonCardTitle>
            </IonCardHeader>
            <IonCard>
              <IonGrid>
                <IonRow>
                  <IonCol size="12" size-md="5">
                    <div className="dashboard-profile-new">
                      <div className="dashboard-profile-img">
                        {history?.location?.state?.id ? (
                          props.userProfileData?.imagename ? (
                            <IonImg src={props.userProfileData.imagename} />
                          ) : (
                            <IonImg src={ProfileImg} />
                          )
                        ) : photo?.dataUrl ? (
                          <IonImg src={photo.dataUrl} />
                        ) : (
                          <IonImg src={ProfileImg} />
                        )}
                      </div>
                      {errorMessage === true ? (
                        <div className="errormsg">
                          <p>Only Png, Jpeg, Jpg image file format accepted!</p>
                        </div>
                      ) : null}

                      {editProfile === true ? (
                        history?.location?.state?.id ? null : (
                          <div className="profile-update-profile">
                            <a
                              className="primary-bg-color edit-btn"
                              onClick={() => takePicture()}
                            >
                              <IonIcon icon={pencilSharp} />
                            </a>
                            <a
                              className="primary-bg-color delete-btn"
                              onClick={() => setShowAlert(true)}
                            >
                              <IonIcon icon={trash} />
                            </a>
                          </div>
                        )
                      ) : null}
                    </div>
                  </IonCol>
                  <IonCol size="12" size-md="7">
                    <Profile
                      cancelImage={clearImage}
                      imageUrl={photo}
                      setEditProfile={(value) => setEditProfile(value)}
                    ></Profile>
                  </IonCol>
                  <IonCol size="12" size-md="7">
                    {/* <MyForm></MyForm> */}
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCard>

            {history.location.pathname === "/page/myProfile" &&
              user?.role === 1 && (
                <div className="profile-subscription-div">
                  <h4>Subscription</h4>
                  <p className="subscription-active-inactive-text">
                    Your subscription is in
                    {user?.is_payment === 1 && user?.subscribed === 1 ? (
                      <span className="active-text">Active</span>
                    ) : user?.is_payment === 0 && user?.subscribed === 1 ? (
                      <span className="in-active-text">Trial</span>
                    ) : null}
                  </p>
                  <p className="subscription-cancel-info-text">
                    To change or cancel your subscription (or update the payment
                    method for your subscription), please email us at{" "}
                    <a href="mailto:welcome@sportaltennis.com">
                      welcome@sportaltennis.com
                    </a>
                  </p>
                </div>
              )}
            <div style={{ height: '50px' }}>&nbsp;</div>
          </div>
        </div>
      </div>
      <IonAlert
        isOpen={showAlert}
        onDidDismiss={() => setShowAlert(false)}
        cssClass="profileAlert"
        header={""}
        message={"Are you sure?"}
        buttons={[
          {
            text: "No",
            role: "cancel",
            cssClass: "secondary",
            handler: (blah) => {},
          },
          {
            text: "Yes",
            handler: () => {
              setPhoto("");
            },
          },
        ]}
      />
    </IonContent>
  );
};

const mapStateToProps = (state) => {
  return {
    errorMsg: state.commonReducer.errorMsg,
    errorData: state.commonReducer.errorData,
    successMsg: state.commonReducer.successMsg,
    userProfileData: state.userReducer.userProfileData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MyProfile)
);
