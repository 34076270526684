import React from "react";
import {
  IonTabs,
  IonTabBar,
  IonTabButton,
  IonLabel,
  IonRouterOutlet,
  IonContent,
} from "@ionic/react";

import {
  Redirect,
  Route,
  withRouter,
  Switch,
  useHistory,
} from "react-router-dom";

import "./playerReportingSystem.css";
import WeeklyReport from "./weeklyReport";
import MatchingReport from "./matchReporting";
import goalsReport from "./goalsReport";

const Questionnaire = (props) => {
  let history = useHistory();
  const clearFrom = () => {
    history.push("/page/coachReportingSystem");
  };

  return (
    <IonContent>
      <div className="questionnaire-inner-body">
        <div className="back-link-wrapper">
          <a href="javascript:void(0);" onClick={clearFrom}>
            <span className="back-arrow-icon">
              <svg
                
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M20 11H7.83L13.42 5.41L12 4L4 12L12 20L13.41 18.59L7.83 13H20V11Z"
                  fill="#404F8C"
                />
              </svg>
            </span>
            Edit Questions
          </a>
        </div>
        <div className="reporting-heading">{/* <h2>Questionnaire</h2> */}</div>
        <IonTabs>
          <IonTabBar slot="top">
            <IonTabButton tab="weekly" href="/page/questionnaire/weekly">
              <div className="player-top-tab">
                <div className="tablabel">
                  <IonLabel>Check-In</IonLabel>
                </div>
              </div>
            </IonTabButton>
            <IonTabButton
              tab="GoalsReport"
              href="/page/questionnaire/GoalsReport"
            >
              <div className="player-top-tab">
                <div className="tablabel">
                  <IonLabel>Goals</IonLabel>
                </div>
              </div>
            </IonTabButton>
            <IonTabButton tab="match" href="/page/questionnaire/match">
              <div className="player-top-tab">
                <div className="tablabel">
                  <IonLabel>Match report</IonLabel>
                </div>
              </div>
            </IonTabButton>
          </IonTabBar>

          <IonRouterOutlet id="main three">
            <Route
              path="/page/questionnaire/:tab(weekly)"
              component={WeeklyReport}
              exact={true}
            />
            <Route
              path="/page/questionnaire"
              component={WeeklyReport}
              exact={true}
            />
            <Route
              path="/page/questionnaire/:tab(GoalsReport)"
              component={goalsReport}
              exact={true}
            />
            <Route
              path="/page/questionnaire/:tab(match)"
              component={MatchingReport}
            />
          </IonRouterOutlet>
        </IonTabs>
      </div>
    </IonContent>
  );
};

export default withRouter(Questionnaire);
