export const initialState = {
  isQuestionnaire: true,
  darkMode: false,
  playersQuestions: [],
  coachQuestions: [],
  isAnswerSubmitted: false,
  playersAnswersAfterSubmit: [],
  checkPlayerReportingData: [],
  getAllWeeklyReport: [],
  getAllMonthlyReport: [],
  currentGoals: [],
  notificationData: null,
  selectedCoachQuestion: [],
  matchReportSelectedID: [],
  playersSubmittedReport: [],
  checkPlayerSubmittedReportingData: [],
  getLastPage: "",
  getPageNo: "",
  getPerPage: "",
  getTotalRecordPage: "",
  getSubmittedReportPage: "",
  goalsCoachName: "",
  coachImage: "",
  coachQuestionsName: "",
  goalCoachImage:""
};

const prsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "Questionnaire":
      return {
        ...state,
        Questionnaire: action.payload?.data,
      };
    case "Questionnaire_DaysLeft":
      return {
        ...state,
        isQuestionnaire: action.payload?.results,
        coachQuestions: action.payload?.data,
      };
    case "PLAYERS_QUESTIONS":
      return {
        ...state,
        playersQuestions: action.payload?.data,
      };
    case "PLAYERS_COACH_NAME_QUESTIONS":
      return {
        ...state,
        coachQuestionsName: action.payload?.data,
      };
    case "COACH_IMAGE":
      return {
        ...state,
        coachImage: action.payload?.data,
      };
      

    case "GOALS_PLAYERS_COACH_NAME_QUESTIONS":
      return {
        ...state,
        goalsCoachName: action.payload?.data,
      };
      case "GOAL_COACH_IMAGE":
        return {
          ...state,
          goalCoachImage: action.payload?.data,
        };
    case "PLAYERS_ANSWERS_AFTER_SUBMIT":
      return {
        ...state,
        playersAnswersAfterSubmit: action.payload?.data,
        isAnswerSubmitted: action.payload?.results,
      };
    case "CHECK_PLAYER_REPORTS":
      return {
        ...state,
        checkPlayerReportingData: action.payload?.data,
      };
    case "CHECK_PLAYER_SUBMITTED_REPORTS":
      return {
        ...state,
        checkPlayerSubmittedReportingData: action.payload?.data,
      };

    case "GET_ALL_WEEKLY_REPORT":
      return {
        ...state,
        getAllWeeklyReport: action.payload?.data,
        // getAllPlayerReport: action.payload?.data
      };
    case "GET_ALL_PLAYER_SUBMITTED_REPORT":
      return {
        ...state,
        playersSubmittedReport: action.payload?.data,
      };
    case "GET_ALL_MONTHLY_REPORT":
      return {
        ...state,
        getAllMonthlyReport: action.payload?.data,
      };
    case "GET_PLAYERS_CURRENT_GOALS":
      return {
        ...state,
        currentGoals: action.payload?.data,
      };

    case "GET_SELECTED_CURRENT_QUESTION":
      return {
        ...state,
        selectedCoachQuestion: action.payload?.data,
      };

    case "GET_SELECTED_MATCH_REPORT_CURRENT_QUESTION":
      return {
        ...state,
        matchReportSelectedID: action.payload?.data,
      };

    case "GET_LAST_PAGE":
      return {
        ...state,
        getLastPage: action.payload?.data?.data?.last_page,
      };
    case "GET_ALL_PLAYER_SUBMITTED_REPORT_LAST_PAGE":
      return {
        ...state,
        getSubmittedReportPage: action.payload?.data?.data?.last_page,
      };

    case "GET_PAGE_NO":
      return {
        ...state,
        getPageNo: action.payload?.data?.data?.page_no,
      };

    case "GET_TOTAL_RECORD":
      return {
        ...state,
        getPageNo: action.payload?.data?.data?.page_no,
      };

    case "GET_PER_RECORD":
     
      return {
        ...state,
        getTotalRecordPage: action.payload?.data?.data?.per_page,
      };

    case "Clear_Reporting_System":
      return {
        ...state,
        playersQuestions: [],
        coachQuestionsName: "",
        coachImage:"",
        Questionnaire: null,
        selectedCoachQuestion: [],
        isQuestionnaire: null,
        matchReportSelectedID: [],
        playersAnswersAfterSubmit: [],
        getAllWeeklyReport: [],
        playersSubmittedReport: [],
        getAllMonthlyReport: [],
        checkPlayerSubmittedReportingData: [],
        getLastPage: "",
        getPageNo: "",
        getPerPage: "",
        getTotalRecordPage: "",
        goalsCoachName: "",
        goalCoachImage:""
      };

    case "SET_NOTIFICATION":
      return {
        ...state,
        notificationData: action.payload.data,
      };

    default:
      return state;
  }
};

export default prsReducer;
