import {
  IonIcon,
  IonList,
  IonItem,
  IonInput,
  IonImg,
  IonLabel,
  IonSpinner,
  IonAlert,
} from "@ionic/react";
import React, { useState, useEffect } from "react";
import { uploadImage } from "../../Utils/fileUpload";
import { BUCKET_NAME } from "../../Constants/constants";
import { pencilSharp, documentTextOutline, trash } from "ionicons/icons";
import { connect } from "react-redux";
import { changePasswordd, isUser } from "../../redux/actions/authAction";
import {
  editUser,
  getUser,
  getCountry,
  getState,
  getCity,
  deleteTeamLogo,
  cleanUpUserDetails,
} from "../../redux/actions/users";
import { ClearData } from "../../redux/actions/commonAction";
import { matchtoken } from "../../redux/actions/authAction";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import Select from "react-select";

const Profile = (props) => {
  const dispatch = useDispatch();
  const { history } = props;
  const [countryArray, setCountry] = useState([]);
  const [stateArray, setState] = useState([]);
  const [cityArray, setCity] = useState([]);
  const [formData, setFormValue] = useState({
    userid: "",
    fullName: "",
    email: "",
    // bio: "",
    funFact: "",
    contactNo: "",
    class: "",
    major: "",
    homeTown: "",
    residence: "",
    imageUrl: "",
    teamName: "",
    teamLogo: "/assets/icon/team_logo.png",
    city: "",
    state: "",
    country: "",
    latitude: "",
    longitude: "",
  });

  const [errorData, setErrorData] = useState({
    fullName: [],
    email: [],
    // bio: [],
    funFact: [],
    contactNo: [],
    class: [],
    major: [],
    homeTown: [],
    residence: [],
    teamName: [],
    teamLogo: [],
    city: [],
    state: [],
    country: [],
  });
  const [isEdit, showEdit] = useState(false);
  const [logo, setLogo] = useState(null);
  const [user, setUser] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  const [loader, showloader] = useState(false);
  const [defaultCountry, setDefaultCountry] = useState();
  const [defaultState, setDefaultState] = useState();
  const [defaultCity, setDefaultCity] = useState();

  React.useEffect(() => {
    getUserData();
  }, [props?.location?.pathname]);

  useEffect(() => {
    getCountry();
  }, []);

  // const handleDeleteImage = async (props) => {
  //   let temalogo = props.teamLogo;
  //   const segments = temalogo.split("/");
  //   const bktName = segments[segments.length - 2];
  //   const imgName = segments[segments.length - 1];
  //   const imageName = props.email + "/" + bktName + "/" + imgName;
  //   const s3BucketUrl = "https://sportal-image.s3.us-east-2.amazonaws.com";

  //   let data = await axios
  //     .delete(`${s3BucketUrl}/${imageName}`)
  //     .then(async (response) => {
  //       return response;
  //     })
  //     .catch((error) => {
  //       console.error("Error deleting image:", error);
  //     });

  //   if (data.status == 204) {
  //     let response1 = await deleteTeamLogo(props.userId);
  //     if (response1.status === 200) {
  //       getUserData();
  //       dispatch({
  //         type: "LOGO_REMOVE",
  //       });
  //       setLogo("/assets/icon/client-dummy.png");
  //     }
  //   }
  // };

  const handleDeleteImage = async (props) => {
    let response1 = await deleteTeamLogo(props.userId);
    if (response1.status === 200) {
      getUserData();
      dispatch({
        type: "LOGO_REMOVE",
      });
      setLogo("/assets/icon/team_logo.png");
    }
  };

  useEffect(() => {
    let countryyArray = props.countryReducerArray.map((country) => ({
      value: `${country.id}`,
      label: `${country.name}`,
    }));
    setCountry(countryyArray);
    if (
      (props.authData.country !== "" ||
        props.authData.country !== null ||
        props.authData.country !== undefined) &&
      props.countryReducerArray.length > 0
    ) {
      let countryObject = props.countryReducerArray.filter((event) => {
        return event.name === props.authData.country;
      });

      if (countryObject[0]) {
        props.getState(countryObject[0]?.id);
        setDefaultCountry({
          value: `${countryObject[0]?.id}`,
          label: `${countryObject[0]?.name}`,
        });
      }
    }
  }, [props.countryReducerArray]);

  useEffect(() => {
    let stateeArray = props.stateReducerArray.map((country) => ({
      value: `${country.id}`,
      label: `${country.state_name}`,
    }));
    setState(stateeArray);
    if (
      (props.authData.state !== "" ||
        props.authData.state !== null ||
        props.authData.state !== undefined) &&
      props.stateReducerArray.length > 0
    ) {
      let countryObject = props.stateReducerArray.filter((event) => {
        return event.state_name === props.authData.state;
      });
      if (countryObject[0]) {
        props.getCity(countryObject[0]?.id);
        setDefaultState({
          value: `${countryObject[0]?.id}`,
          label: `${countryObject[0]?.state_name}`,
        });
      }
    }
  }, [props.stateReducerArray]);

  useEffect(() => {
    let stateeArray = props.cityReducerArray.map((country) => ({
      value: `${country.id}`,
      label: `${country.city}`,
      longitude: `${country.longitude}`,
      latitude: `${country.latitude}`,
    }));
    setCity(stateeArray);
    if (
      (props.authData.city !== "" ||
        props.authData.city !== null ||
        props.authData.city !== undefined) &&
      props.cityReducerArray.length > 0
    ) {
      let countryObject = props.cityReducerArray.filter((event) => {
        return event.city === props.authData.city;
      });
      if (countryObject.length > 0) {
        setDefaultCity({
          value: `${countryObject[0]?.id}`,
          label: `${countryObject[0]?.city}`,
          longitude: `${countryObject[0]?.longitude}`,
          latitude: `${countryObject[0]?.latitude}`,
        });
        setFormValue((prevState) => ({
          ...prevState,
          country: defaultCountry?.label,
          state: defaultState?.label,
          city: countryObject[0]?.city,
          latitude: countryObject[0]?.latitude,
          longitude: countryObject[0]?.longitude,
        }));
      }
    }
  }, [props.cityReducerArray]);

  const getUserData = async () => {
    let id;
    if (history?.location?.state?.id) {
      id = history.location.state.id;
    } else {
      let adminId = localStorage.getItem("userData");
      id = JSON.parse(adminId).id;
    }

    const getUser = await props.getUser(id);

    setUser(getUser);

    setFormValue({
      userId: getUser?.id ? getUser.id : "",
      fullName: getUser?.fullname ? getUser.fullname : "",
      email: getUser?.email ? getUser?.email : "",
      funFact: getUser?.fun_fact ? getUser.fun_fact : "",
      contactNo: getUser?.contactNo ? getUser?.contactNo.toString() : "",
      class: getUser?.class ? getUser.class : "",
      major: getUser?.major ? getUser.major : "",
      homeTown: getUser?.home_address ? getUser.home_address : "",
      teamName: getUser?.team_name
        ? getUser.team_name
        : props.authData?.team_name
        ? props.authData.team_name
        : user?.team_name
        ? user.team_name
        : "",
      residence: getUser?.residence_address ? getUser.residence_address : "",
      teamLogo: getUser?.team_logo
        ? getUser.team_logo
        : props.authData?.team_logo
        ? props.authData.team_logo
        : user?.team_logo
        ? user.team_logo
        : "/assets/icon/team_logo.png",

      city: props.authData?.city ? props.authData?.city : "",
      state: props.authData?.state ? props.authData?.state : "",
      country: props.authData?.country ? props.authData?.country : "",
    });
  };

  const handleChange = async (formField, event) => {
    if (formField === "teamLogo") {
      let file = event.target.files[0];
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = async () => {
        if (reader?.result) {
          await showloader(true);
          const imagePath = await uploadImage(
            {
              dataUrl: reader.result,
              format:
                file.type == "image/png"
                  ? "png"
                  : file.type === "image/jpeg"
                  ? "jpeg"
                  : file.type === "image/jpg"
                  ? "jpg"
                  : null,
            },
            BUCKET_NAME,
            `${formData.email}/user-team-logo`
          );
          let test = formData;

          test["teamLogo"] = imagePath;
          setFormValue(test);
          setLogo(imagePath);
          showloader(false);
        }
      };
    } else {
      let test = formData;
      test[formField] = event.target.value;
      setFormValue(test);
    }
  };

  const cancelEdit = () => {
    getUserData();
    showEdit(false);
    //props.cancelImage("");
    props.setEditProfile(false);
  };
  const editProfile = async (event) => {
    if (validateForm()) {
      if (props.imageUrl?.dataUrl && props.imageUrl?.format) {
        const imagePath = await uploadImage(
          props.imageUrl,
          BUCKET_NAME,
          `${user.id}/user-images`
        );
        let test = formData;
        test["imageUrl"] = imagePath;
        setFormValue(test);

        await props.editUser(formData);
        let adminId = localStorage.getItem("userData");

        await props.getUser(JSON.parse(adminId).id);

        dispatch(matchtoken());
        setTimeout(() => {
          props.ClearData();
        }, 3000);
        getUserData();
        showEdit(false);
        props.setEditProfile(false);
      } else {
        if (props.imageUrl === "") {
          let test = formData;
          test["imageUrl"] = "";
          setFormValue(test);

          await props.editUser(formData);
          // await props.getUser();
          let adminId = localStorage.getItem("userData");
          await props.getUser(JSON.parse(adminId).id);
          dispatch(matchtoken());
          setTimeout(() => {
            props.ClearData();
          }, 3000);
          getUserData();
          showEdit(false);
          props.setEditProfile(false);
        } else {
          if (props.authData.team_logo == null) {
            let test = formData;
            test["teamLogo"] = null;
            setFormValue(test);
          }

          await props.editUser(formData);
          dispatch(matchtoken());
          // await props.getUser();
          setTimeout(() => {
            props.ClearData();
          }, 3000);
          getUserData();
          showEdit(false);
          props.setEditProfile(false);
        }
      }
    }
  };

  const validateForm = () => {
    let isFormValid = true;
    let testError = {
      fullName: [],
      email: [],
      // bio: [],
      funFact: [],
      //contactNo: [],
      class: [],
      major: [],
      homeTown: [],
      residence: [],
      teamName: [],
      teamLogo: [],
      city: [],
      state: [],
      country: [],
    };
    if (formData.email.trim().length === 0) {
      isFormValid = false;
      testError.email = ["This field is required."];
    }
    if (
      formData.email.trim().length > 0 &&
      /^([a-zA-Z0-9_\.\-]+)@([a-zA-Z0-9_\.\-]+)\.([a-zA-Z]{2,5})$/.test(
        formData.email
      ) === false
    ) {
      isFormValid = false;
      testError.email = ["Email must be a valid email address"];
    }
    // if (formData?.contactNo?.trim().length === 0 && props.checkUserRole === 1) {
    //   isFormValid = false;
    //   testError.contactNo = ["This field is required."];
    // }

    // if (
    //   formData.contactNo &&
    //   formData.contactNo.trim().length > 0 &&
    //   formData.contactNo.trim().length > 15
    // ) {
    //   isFormValid = false;
    //   testError.contactNo = ["Contact Number cannot be gretar than 15"];
    // }
    // if (
    //   formData.contactNo.trim().length > 0 &&
    //   formData.contactNo.match("^[a-zA-Z ]*$")
    // ) {
    //   isFormValid = false;
    //   testError.contactNo = ["Contact Number must be valid"];
    // }

    if (formData.fullName.trim().length === 0) {
      isFormValid = false;
      testError.fullName = ["This field is required."];
    }
    if (
      formData.fullName.trim().length > 0 &&
      formData.fullName.trim().length > 50
    ) {
      isFormValid = false;
      testError.fullName = ["Name length cannot be gretar than 15"];
    }
    // if (formData.bio.trim().length === 0 && props.checkUserRole !== 1) {
    //   isFormValid = false;
    //   testError.bio = ["This field is required."];
    // }
    // if (formData.funFact.trim().length === 0) {
    //   isFormValid = false;
    //   testError.funFact = ["This field is required."];
    // }
    // if (formData.homeTown.trim().length === 0) {
    //   isFormValid = false;
    //   testError.homeTown = ["This field is required."];
    // }
    // if (formData.residence.trim().length === 0 && props.checkUserRole !== 1) {
    //   isFormValid = false;
    //   testError.residence = ["This field is required."];
    // }
    // if (formData.class.trim().length === 0 && props.checkUserRole !== 1) {
    //   isFormValid = false;
    //   testError.class = ["This field is required."];
    // }
    // if (formData.major.trim().length === 0 && props.checkUserRole !== 1) {
    //   isFormValid = false;
    //   testError.major = ["This field is required."];
    // }
    if (formData.teamName.trim().length === 0) {
      isFormValid = false;
      testError.teamName = ["This field is required."];
    }
    if (
      formData.teamName.trim().length > 0 &&
      formData.teamName.trim().length > 50 &&
      props.checkUserRole === 1
    ) {
      isFormValid = false;
      testError.teamName = ["Team Name length cannot be gretar than 50"];
    }
    // if (formData.city.trim().length === 0) {
    //   isFormValid = false;
    //   testError.city = ["This field is required."];
    // }
    // if (formData.state.trim().length === 0) {
    //   isFormValid = false;
    //   testError.state = ["This field is required."];
    // }
    // if (formData.country.trim().length === 0) {
    //   isFormValid = false;
    //   testError.country = ["This field is required."];
    // }
    setErrorData(testError);
    return isFormValid;
  };

  const getCountry = async () => {
    await props.getCountry();
  };

  const handleCountryChange = async (event) => {
    setFormValue((prevState) => ({ ...prevState, country: event.label }));
    setDefaultCountry(event);
    await props.getState(event.value);
  };

  const handleStateChange = async (event) => {
    setFormValue((prevState) => ({ ...prevState, state: event.label }));
    setDefaultState(event);
    setDefaultCity([]);
    setCity([]);
    await props.getCity(event.value);
  };
  const handleCityChange = async (event) => {
    setFormValue((prevState) => ({
      ...prevState,
      city: event.label,
      latitude: event?.latitude,
      longitude: event?.longitude,
    }));
    setDefaultCity(event);
  };
  return (
    <div className="new-profile-list">
      {!isEdit ? (
        <div>
          <IonList lines="none">
            <IonItem>
              <IonLabel>
                <span>Name:</span>
                {user?.fullname ? user.fullname : "N/A"}
              </IonLabel>
            </IonItem>
            {/* {props.checkUserRole === 1 ? null : (
              <IonItem>
                <IonLabel>
                  <span>Bio:</span>
                  {user?.bio ? user.bio : "N/A"}
                </IonLabel>
              </IonItem>
            )} */}
            <IonItem>
              <IonLabel>
                <span>Fun Fact:</span>
                {user?.fun_fact ? user.fun_fact : "N/A"}
              </IonLabel>
            </IonItem>
            <IonItem>
              <IonLabel>
                <span>Email:</span>
                <a>{user?.email ? user.email : "N/A"}</a>
              </IonLabel>
            </IonItem>
            <IonItem>
              <IonLabel>
                <span>
                  Phone:
                  {/* {props.checkUserRole === 1 ? <sup>*</sup> : ""} */}
                </span>
                {user?.contactNo ? user.contactNo : "N/A"}
              </IonLabel>
            </IonItem>
            {user.role === 1 || user.role === 3 ? null : (
              <>
                <IonItem>
                  <IonLabel>
                    <span>Class:</span>
                    {user?.class ? user.class : "N/A"}
                  </IonLabel>
                </IonItem>
                <IonItem>
                  <IonLabel>
                    <span>Major:</span>
                    {user?.major ? user.major : "N/A"}
                  </IonLabel>
                </IonItem>
                {/*   <IonItem>
               <IonLabel>
                 <span>Residence:</span>
                 {user?.residence_address
                   ? user.residence_address
                   : "Residence Address"}
               </IonLabel>
             </IonItem>*/}
              </>
            )}
            <IonItem>
              <IonLabel>
                <span>Hometown:</span>
                {user?.home_address ? user.home_address : "N/A"}
              </IonLabel>
            </IonItem>
            <IonItem>
              <IonLabel>
                <span>Country:</span>
                {user?.country ? user.country : "N/A"}
              </IonLabel>
            </IonItem>
            <IonItem>
              <IonLabel>
                <span>State:</span>
                {user?.state ? user.state : "N/A"}
              </IonLabel>
            </IonItem>
            <IonItem>
              <IonLabel>
                <span>City:</span>
                {user?.city ? user.city : "N/A"}
              </IonLabel>
            </IonItem>
            <IonItem>
              <IonLabel>
                <span>Team Name:</span>
                {props.authData?.team_name ? props.authData?.team_name : "N/A"}
              </IonLabel>
            </IonItem>
            <IonItem>
              <IonLabel>
                <span>Team Logo:</span>
                <IonImg
                  style={{ maxWidth: 180 }}
                  src={
                    props?.authData?.team_logo
                      ? props?.authData?.team_logo
                      : "/assets/icon/team_logo.png"
                  }
                />
              </IonLabel>
            </IonItem>
          </IonList>
          {history?.location?.state?.id ? null : (
            <div className="editprofile-btns">
              <div className="update-section-btn">
                <a
                  className="primary-bg-color edit-btn"
                  onClick={(event) => {
                    showEdit(true);
                    props.setEditProfile(true);
                  }}
                >
                  <IonIcon icon={pencilSharp} /> Edit Profile
                </a>
              </div>

              <div className="mydocuments-btn">
                <a
                  onClick={(e) => {
                    props.history.push("/page/myDocuments");
                  }}
                >
                  <IonIcon icon={documentTextOutline} /> My Documents
                </a>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div>
          <div className="edit-profile-update">
            <form method="post">
              <div className="form-group">
                <IonLabel>
                  Name<sup>*</sup>
                </IonLabel>
                <IonInput
                  type="text"
                  placeholder="First Last"
                  value={formData.fullName}
                  onIonChange={(event) => {
                    handleChange("fullName", event);
                  }}
                ></IonInput>
                {errorData?.fullName[0] ? (
                  <p className="errormsg">{errorData?.fullName[0]}</p>
                ) : null}
              </div>
              {/* {props.checkUserRole !== 1 ? (
                <div className="form-group">
                  <IonLabel>Bio</IonLabel>
                  <IonInput
                    type="text"
                    placeholder="I can sing the alphabet backwards"
                    value={formData.bio}
                    onIonChange={(event) => {
                      handleChange("bio", event);
                    }}
                  ></IonInput>

                  {errorData?.bio[0] ? (
                    <p className="errormsg">{errorData?.bio[0]}</p>
                  ) : null}
                </div>
              ) : null} */}
              <div className="form-group">
                <IonLabel>Fun Fact</IonLabel>
                <IonInput
                  type="text"
                  value={formData.funFact}
                  placeholder="Share a Fun Fact about Yourself"
                  onIonChange={(event) => {
                    handleChange("funFact", event);
                  }}
                ></IonInput>
                {errorData?.funFact[0] ? (
                  <p className="errormsg">{errorData?.funFact[0]}</p>
                ) : null}
              </div>
              <div className="form-group">
                <IonLabel>Email</IonLabel>
                <IonInput
                  type="email"
                  value={formData.email}
                  placeholder="Email"
                  disabled={true}
                  onIonChange={(event) => {
                    handleChange("email", event);
                  }}
                ></IonInput>
                {errorData?.email[0] ? (
                  <p className="errormsg">{errorData?.email[0]}</p>
                ) : null}
              </div>
              <div className="form-group">
                <IonLabel>
                  Phone
                  {/* {props.checkUserRole === 1 ? <sup>*</sup> : ""} */}
                </IonLabel>
                <IonInput
                  type="text"
                  value={formData.contactNo}
                  placeholder="Contact Number (e.g., 857-456-7890)"
                  onIonChange={(event) => {
                    handleChange("contactNo", event);
                  }}
                ></IonInput>
                {/* {errorData?.contactNo[0] ? (
                  <p className="errormsg">{errorData?.contactNo[0]}</p>
                ) : null} */}
              </div>
              {props.checkUserRole === 1 ? null : (
                <>
                  {" "}
                  <div className="form-group">
                    <IonLabel>Class</IonLabel>
                    <IonInput
                      type="text"
                      Your
                      Team
                      Name
                      value={formData.class}
                      placeholder="2023"
                      onIonChange={(event) => {
                        handleChange("class", event);
                      }}
                    ></IonInput>
                    {errorData?.class[0] ? (
                      <p className="errormsg">{errorData?.class[0]}</p>
                    ) : null}
                  </div>
                  <div className="form-group">
                    <IonLabel>Major</IonLabel>
                    <IonInput
                      type="text"
                      value={formData.major}
                      placeholder="Major"
                      onIonChange={(event) => {
                        handleChange("major", event);
                      }}
                    ></IonInput>
                    {errorData?.major[0] ? (
                      <p className="errormsg">{errorData?.major[0]}</p>
                    ) : null}
                  </div>
                  {/*  <div className="form-group">
                  <IonLabel>Residence</IonLabel>
                  <IonInput
                    type="text"
                    placeholder="Mudge House"
                    value={formData.residence}
                    onIonChange={(event) => {
                      handleChange("residence", event);
                    }}
                  ></IonInput>
                  {errorData?.residence[0] ? (
                    <p className="errormsg">{errorData?.residence[0]}</p>
                  ) : null}
                </div>{" "}*/}
                </>
              )}

              <div className="form-group">
                <IonLabel>Hometown</IonLabel>
                <IonInput
                  type="text"
                  placeholder="Hometown"
                  value={formData.homeTown}
                  onIonChange={(event) => {
                    handleChange("homeTown", event);
                  }}
                ></IonInput>
                {errorData?.homeTown[0] ? (
                  <p className="errormsg">{errorData?.homeTown[0]}</p>
                ) : null}
              </div>

              <div className="form-group profile-select-wrapper">
                <IonLabel>Country</IonLabel>
                <Select
                  value={defaultCountry}
                  select={true}
                  name="value"
                  options={countryArray}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={(event) => handleCountryChange(event)}
                />

                {errorData?.country[0] ? (
                  <p className="errormsg">{errorData?.country[0]}</p>
                ) : null}
              </div>

              <div className="form-group profile-select-wrapper">
                <IonLabel>State</IonLabel>
                <Select
                  value={defaultState}
                  name="value"
                  options={stateArray}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={(event) => handleStateChange(event)}
                />
                {errorData?.state[0] ? (
                  <p className="errormsg">{errorData?.state[0]}</p>
                ) : null}
              </div>

              <div className="form-group profile-select-wrapper">
                <IonLabel>City</IonLabel>
                <Select
                  value={defaultCity}
                  name="value"
                  options={cityArray}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={(event) => handleCityChange(event)}
                />
                {errorData?.city[0] ? (
                  <p className="errormsg">{errorData?.city[0]}</p>
                ) : null}
              </div>
              <div
                className="row"
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "flex-start",
                }}
              >
                <div className="col-md-6 form-group">
                  <IonLabel>
                    Team Name {props.checkUserRole === 1 ? <sup>*</sup> : ""}
                  </IonLabel>
                  <IonInput
                    type="text"
                    placeholder="Your Team Name"
                    disabled={props.checkUserRole === 1 ? false : true}
                    value={formData.teamName}
                    onIonChange={(event) => {
                      handleChange("teamName", event);
                    }}
                  ></IonInput>
                  {errorData?.teamName[0] ? (
                    <p className="errormsg">{errorData?.teamName[0]}</p>
                  ) : null}
                </div>

                <div className="col-md-6 form-group">
                  <IonLabel>
                    Team Logo
                    {props.checkUserRole === 1 ? <sup>*</sup> : ""}
                  </IonLabel>
                  <div className="custom-fileinput">
                    <div className="custom-fileinput-inner">
                      {props.checkUserRole === 1 ? (
                        <>
                          <input
                            type="file"
                            accept="image/*"
                            name="teamLogo"
                            className="form-control"
                            onChange={(event) => {
                              handleChange("teamLogo", event);
                            }}
                          />
                          <button type="button">
                            <IonIcon icon={pencilSharp} />
                          </button>
                          <a
                            className="primary-bg-color delete-btn"
                            onClick={() => setShowAlert(true, formData)}
                          >
                            <IonIcon icon={trash} />
                          </a>
                        </>
                      ) : null}

                      {/* <p>page.jpg</p> */}
                    </div>
                    {errorData?.teamLogo[0] ? (
                      <p>{errorData?.teamLogo[0]}</p>
                    ) : null}
                  </div>
                  <IonImg
                    style={{ maxWidth: 180 }}
                    src={
                      logo
                        ? logo
                        : props?.authData?.team_logo ||
                          "/assets/icon/team_logo.png"
                    }
                  />
                  {(() => {
                    if (loader === true) {
                      return (
                        <IonSpinner
                          name="crescent"
                          className="spinner-loader"
                        />
                      );
                    }
                  })()}
                </div>
              </div>
            </form>
          </div>
          <IonAlert
            isOpen={showAlert}
            onDidDismiss={() => setShowAlert(false)}
            cssClass="profileAlert"
            header={""}
            message={"Are you sure delete team logo?"}
            buttons={[
              {
                text: "No",
                //role: "cancel",
                cssClass: "secondary",
                handler: (blah) => {},
              },
              {
                text: "Yes",
                handler: () => {
                  handleDeleteImage(formData);
                },
              },
            ]}
          />
          <div className="update-section">
            <a
              className=""
              onClick={(event) => {
                editProfile(event);

                // cancelEdit();
                // showEdit(false);
              }}
            >
              Update
              {(() => {
                if (props.loading) {
                  return (
                    <IonSpinner name="crescent" className="spinner-loader" />
                  );
                }
              })()}
            </a>
            <a
              className=""
              onClick={(event) => {
                cancelEdit();
              }}
            >
              Cancel
            </a>
          </div>
        </div>
      )}
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.authReducer.isLoggedIn,
    errorMsg: state.commonReducer.errorMsg,
    errorData: state.commonReducer.errorData,
    successMsg: state.commonReducer.successMsg,
    adminMode: state.commonReducer.adminMode,
    loading: state.commonReducer.loading,
    checkUserRole: state.authReducer.checkUserRole,
    authData: state.authReducer.authData,
    stateReducerArray: state.userReducer.stateArray,
    countryReducerArray: state.userReducer.country,
    cityReducerArray: state.userReducer.city,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      changePasswordd,
      isUser,
      ClearData,
      editUser,
      getUser,
      getCountry,
      getState,
      getCity,
      deleteTeamLogo,
      cleanUpUserDetails,
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Profile)
);
