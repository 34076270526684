import React, { useState, useRef } from "react";
import {
  IonContent,
  IonSlides,
  IonSlide,
  IonIcon,
  IonInput,
  IonButton,
  IonCard,
  IonImg,
  IonLabel,
  IonModal,
  IonSpinner,
  IonGrid,
  IonCol,
  IonRow,
} from "@ionic/react";
import { IonInfiniteScroll, IonInfiniteScrollContent } from "@ionic/react";
import { close } from "ionicons/icons";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  getContactList,
  addContact,
  clearContactList,
  searchContactList,
} from "../../redux/actions/contactAction";
import { createGroup, editGroup } from "../../redux/actions/chatAction";
import CoachImg from "../../images/Whistle.svg";
import TeamtImg from "../../images/team.svg";
import StrengthImg from "../../images/gym.svg";
import TrainersImg from "../../images/protector.svg";
import AlumniImg from "../../images/mortarboard.svg";
import CoachImgwhite from "../../images/whistle-white.png";
import TeamtImgwhite from "../../images/team-white.png";
import StrengthImgwhite from "../../images/gym-white.png";
import TrainersImgwhite from "../../images/protector-white.png";
import AlumniImgwhite from "../../images/mortarboard-white.png";
import { searchOutline } from "ionicons/icons";
import Coaches from "../contacts/Coaches";
import Teams from "../contacts/Teams";
import Strength from "../contacts/Strength";
import Trainers from "../contacts/Trainers";
import Alumni from "../contacts/Alumni";
import "../contacts/contact.css";
import PubNub from "pubnub";

const ContactModal = (props) => {
  const ionInfiniteScrollRef = useRef();
  const coachRef = useRef();
  const [activeId, setActiveId] = useState(1);
  const [slidenum, setSlideNum] = useState(6);
  const [showModal, setShowModal] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [disableInfiniteScroll, setDisableInfiniteScroll] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [keyword, setSearchKeyword] = useState("");
  var clearTimeoutHolder = null;
  // const [limitOffset, setLimitOffset] = useState({limit:2,offset:0});
  const [formData, setFormValue] = useState({
    groupName: "",
  });
  const [errorData, setErrorData] = useState({
    groupName: [],
    lengthError: [],
  });
  const limit = 5;
  const [limitOffset, setLimitOffset] = useState({ limit, offset: 0 });

  const slideOpts = {
    initialSlide: 0,
    slidesPerView: slidenum,
  };
  const pubnub_config = {
    publishKey: "pub-c-bcbfc2d8-736e-4a83-9ef1-daf51ae2203e",
    subscribeKey: "sub-c-758c3636-4438-11eb-9ec5-221b84410db5",
    secretKey: "sec-c-OWU4N2UwOWMtM2VlMy00NTBhLTk3MGUtNjk4ZGEwNmRjNzEy",
    logVerbosity: true,
    ssl: true,
    uuid: props.authData?.email,
    authKey: props.authData?.email,
  };

  React.useEffect(() => {
    // document.getElementById("Coaches").click();
    if (window.innerWidth <= 767) {
      setSlideNum(3);
    } else {
      setSlideNum(6);
    }
  }, []);
  React.useEffect(() => {
    if (props.isClick === true) {
      setTimeout(() => {
        document.getElementById("Coaches").click();
      }, 200);
    }
  }, [coachRef]);

  async function fetchData() {
    props.getContactList(activeId, limitOffset);
    setLimitOffset({
      limit,
      offset: limitOffset.offset + limit,
    });

    if (props?.contactList?.data?.length > 0) {
      if (props?.contactList?.data.length >= limit) {
        // setDisableInfiniteScroll(false);
        ionInfiniteScrollRef.current.disabled = false;
      } else {
        // setDisableInfiniteScroll(true);
        ionInfiniteScrollRef.current.disabled = false;
      }
    } else {
      // setDisableInfiniteScroll(false);
      ionInfiniteScrollRef.current.disabled = true;
    }
  }

  const loadMoreItems = async () => {
    if (isSearch === true) {
      // setDisableInfiniteScroll(true);
      ionInfiniteScrollRef.current.disabled = true;
    } else {
      await fetchData();
      // setDisableInfiniteScroll(false);
      ionInfiniteScrollRef.current.disabled = false;
    }
  };

  async function onSubmitForm() {
    if (validateForm()) {
      let mem = props.groupArray;
      mem.push(props.authData.email);
      let reqBody = {
        groupName: formData.groupName,
        channel:
          Math.random().toString(36).substring(7) +
          Math.random().toString(36).substring(7),
        owner: props.authData.email,
        members: props.groupArray,
      };
      await props.createGroup(reqBody);
      await setShowMessage(true);
      setShowModal(false);
      setTimeout(() => {
        setShowMessage(false);
        props.isClose();
      }, 3000);
      // setShowModal(false);
    }
  }
  const handleChange = (formField, event) => {
    let test = formData;
    test[formField] = event.target.value;
    setFormValue(test);
  };
  const validateForm = () => {
    let isFormValid = true;
    let testError = {
      groupName: [],
      lengthError: [],
    };
    if (formData.groupName.trim().length === 0) {
      isFormValid = false;
      testError.groupName = ["Group Name cannot be empty!"];
    }
    if (props.groupArray.length === 0) {
      isFormValid = false;
      testError.lengthError = ["You need to add atleast one member in group."];
    }
    setErrorData(testError);
    return isFormValid;
  };
  const handleSearch = async (e) => {
    e.preventDefault();
    let searchKeyword = e.target.value;
    setSearchKeyword(searchKeyword);
    if (searchKeyword.length > 0) {
      // ionInfiniteScrollRef.current.disabled = false;
      setIsSearch(true);
      if (clearTimeoutHolder !== null) {
        clearTimeout(clearTimeoutHolder);
      }
      clearTimeoutHolder = setTimeout(async () => {
        // await props.clearContactList();
        await props.searchContactList(activeId, searchKeyword);
      }, 1000);
    } else {
      // ionInfiniteScrollRef.current.disabled = true;
      setIsSearch(false);
      if (clearTimeoutHolder !== null) {
        clearTimeout(clearTimeoutHolder);
      }
      clearTimeoutHolder = setTimeout(async () => {
        // debugger;
        await props.clearContactList();
        await setLimitOffset({
          limit,
          offset: limit,
        });
        await props.getContactList(activeId, { limit, offset: 0 });
      }, 2000);
    }
  };
  const onSelectCategory = async (id, value) => {
    setSearchKeyword("");
    setIsSearch(false);
    await props.clearContactList();
    setActiveId(value);
    let i, tablinks;
    tablinks = document.getElementsByClassName("commonClass");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace("active", "");
    }
    await document.getElementById(id).classList.add("active");
    await props.getContactList(value, { limit, offset: 0 });
    await setLimitOffset({ limit, offset: limit });
  };

  const createGroup = () => {
    setShowModal(true);
  };

  const closeAll = () => {
    props.isClose();
  };

  const editGroup = async (e) => {
    e.preventDefault();
    let mem = props.groupArray;
    mem.push(props.authData.email);
    let result = mem.filter(
      (o1) => !props.groupMembers.some((o2) => o2.user === o1)
    );
    let result3 = props.groupMembers.filter(
      (o1) => !mem.some((o2) => o2 === o1.user)
    );
    let result4 = result3.map((v) => {
      return v.user;
    });

    if (result.length > 0) {
      await setPubnub(result, 0, true).then((data) => {});
    }
    if (result4.length > 0) {
      await setPubnub(result4, 0, false).then(async (data2) => {});
    }

    await props.editGroup(
      { incomingArray: mem, channel: props.currentChatRoom.channel },
      props.currentChatRoom.groupId
    );
    closeAll();
  };

  function setPubnub(authkeys, index, value) {
    const pubnub = new PubNub(pubnub_config);
    return new Promise((resolve, reject) => {
      let config;
      config = {
        authKeys: authkeys,
        channels: [props.currentChatRoom.channel],
        read: value, // false to disallow
        write: value, // false to disallow
        // update: value, // false to disallow
        // delete: value,
        ttl: 0,
      };

      pubnub.grant(config, (status) => {
        let i = index + 1;
        console.log(index, "index");
        console.log(status, "pubnub status 11");
        if (status.error === true && index < 2) {
          console.log("in if pubnub");
          resolve(
            setPubnub(authkeys, [props.currentChatRoom.channel], i, true)
          );
        } else {
          resolve(status);
        }

        // handle status
      });
    });
  }

  return (
    <IonContent>
      <div className="container">
        <div className="contact-start">
          <div className="contact-start-header">
            <IonGrid>
              <IonRow>
                <IonCol size="12" size-sm="3">
                  <div className="contact-start-heading">
                    <h2>Contacts</h2>
                  </div>
                </IonCol>
                <IonCol size="12" size-sm="9">
                  <div className="contact-header-right">
                    <div className="contact-searchinput">
                      <div className="contact-searchinput-inner">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search..."
                          value={keyword}
                          onChange={(event) => {
                            handleSearch(event);
                          }}
                        ></input>
                        <IonIcon icon={searchOutline} />
                      </div>
                    </div>
                    {props.isChat === true && props.isClick === false ? (
                      <div className="dash-adduser">
                        <a onClick={() => createGroup()}> Create Group</a>
                      </div>
                    ) : null}

                    {props.isChat === true && props.isClick === true ? (
                      <div className="dash-adduser">
                        <a
                          className="theme-link-btn"
                          onClick={(e) => {
                            editGroup(e);
                          }}
                        >
                          {" "}
                          Save
                          {(() => {
                            if (props.loading) {
                              return (
                                <IonSpinner
                                  name="crescent"
                                  className="spinner-loader"
                                />
                              );
                            }
                          })()}
                        </a>
                      </div>
                    ) : null}
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>
            {showMessage === true ? (
              <div className="success-msg">
                <p>{props.errorMsg ? props.errorMsg : props.successMsg}</p>
              </div>
            ) : null}
          </div>
          <div className="contact-search-box">
            <IonCard>
              <IonSlides options={slideOpts} scrollbar="true">
                {/* <IonSlide>
                  <ion-button>
                    <IonIcon icon={searchOutline} />
                  </ion-button>
                </IonSlide> */}
                <IonSlide
                  id="Coaches"
                  className="commonClass default"
                  onClick={(e) => onSelectCategory("Coaches", 1)}
                >
                  <a>
                    <IonImg className="coachImg-blue" src={CoachImg} />
                    <IonImg className="coachImg-white" src={CoachImgwhite} />
                    Coaches
                  </a>
                </IonSlide>
                <IonSlide
                  id="TEAM"
                  className="commonClass"
                  onClick={(e) => onSelectCategory("TEAM", 2)}
                >
                  <a>
                    <IonImg className="coachImg-blue" src={TeamtImg} />
                    <IonImg className="coachImg-white" src={TeamtImgwhite} />
                    TEAM
                  </a>
                </IonSlide>
                <IonSlide
                  id="STRENGTH"
                  className="commonClass"
                  onClick={(e) => onSelectCategory("STRENGTH", 3)}
                >
                  <a>
                    <IonImg className="coachImg-blue" src={StrengthImg} />
                    <IonImg className="coachImg-white" src={StrengthImgwhite} />
                    STRENGTH
                  </a>
                </IonSlide>
                <IonSlide
                  id="TRAINERS"
                  className="commonClass"
                  onClick={(e) => onSelectCategory("TRAINERS", 4)}
                >
                  <a>
                    <IonImg className="coachImg-blue" src={TrainersImg} />
                    <IonImg className="coachImg-white" src={TrainersImgwhite} />
                    TRAINERS
                  </a>
                </IonSlide>
                <IonSlide
                  id="ALUMNI"
                  className="commonClass"
                  onClick={(e) => onSelectCategory("ALUMNI", 5)}
                >
                  <a>
                    <IonImg className="coachImg-blue" src={AlumniImg} />
                    <IonImg className="coachImg-white" src={AlumniImgwhite} />
                    ALUMNI
                  </a>
                </IonSlide>
              </IonSlides>
            </IonCard>
          </div>
          <div className="dashboard-contact-list">
            {activeId === 1 ? (
              <Coaches
                isChat={props?.isChat ? props.isChat : null}
                closeChat={closeAll}
                payload={props.contactList}
                loading={props.loading}
                loadMoreItems={loadMoreItems}
                disableInfiniteScroll={disableInfiniteScroll}
                ionInfiniteScrollRef={ionInfiniteScrollRef}
                getContactList={props.getContactList}
                setLimitOffset={setLimitOffset}
                ref={coachRef}
              ></Coaches>
            ) : activeId === 2 ? (
              <Teams
                isChat={props?.isChat ? props.isChat : null}
                closeChat={closeAll}
                payload={props.contactList}
                loading={props.loading}
                loadMoreItems={loadMoreItems}
                disableInfiniteScroll={disableInfiniteScroll}
                ionInfiniteScrollRef={ionInfiniteScrollRef}
              ></Teams>
            ) : activeId === 3 ? (
              <Strength
                isChat={props?.isChat ? props.isChat : null}
                closeChat={closeAll}
                payload={props.contactList}
                loading={props.loading}
                loadMoreItems={loadMoreItems}
                disableInfiniteScroll={disableInfiniteScroll}
                ionInfiniteScrollRef={ionInfiniteScrollRef}
              ></Strength>
            ) : activeId === 4 ? (
              <Trainers
                isChat={props?.isChat ? props.isChat : null}
                closeChat={closeAll}
                payload={props.contactList}
                loading={props.loading}
                loadMoreItems={loadMoreItems}
                disableInfiniteScroll={disableInfiniteScroll}
                ionInfiniteScrollRef={ionInfiniteScrollRef}
              ></Trainers>
            ) : activeId === 5 ? (
              <Alumni
                isChat={props?.isChat ? props.isChat : null}
                closeChat={closeAll}
                payload={props.contactList}
                loading={props.loading}
                loadMoreItems={loadMoreItems}
                disableInfiniteScroll={disableInfiniteScroll}
                ionInfiniteScrollRef={ionInfiniteScrollRef}
              ></Alumni>
            ) : null}
            {/* {props.loading ===true ? <p>Loading...</p> : null} */}
          </div>
        </div>
      </div>

      <IonModal
        isOpen={showModal}
        cssClass="contact-add-modal"
        swipeToClose={true}
        backdropDismiss={false}
        onDidDismiss={() => {
          // props.ClearData();
          setShowModal(false);
          setErrorData({
            groupName: [],
            lengthError: [],
          });
        }}
      >
        <div className="addcontact-popup">
          <div className="popup-header">
            <h5>Create Group</h5>
            <div className="errormsg">
              <p>{props?.errorMsg}</p>
            </div>
            <IonButton onClick={() => setShowModal(false)}>
              <IonIcon icon={close} />
            </IonButton>
          </div>
          <form>
            <IonGrid>
              <IonRow>
                <IonCol size="12" size-md="12">
                  <div className="form-group">
                    <IonLabel>
                      Group Name <sup>*</sup>
                    </IonLabel>
                    {errorData?.lengthError[0] ? (
                      <p className="errormsg">{errorData?.lengthError[0]}</p>
                    ) : null}
                    <IonInput
                      type="text"
                      name="groupName"
                      className="form-control"
                      onIonChange={(event) => {
                        handleChange("groupName", event);
                      }}
                    ></IonInput>
                    {errorData?.groupName[0] ? (
                      <p className="errormsg">{errorData?.groupName[0]}</p>
                    ) : null}
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>

            <div className="form-group-center">
              <IonButton
                className="theme-gradient-btn"
                onClick={(event) => {
                  onSubmitForm();
                }}
              >
                Create Group
                {(() => {
                  if (props.loading) {
                    return (
                      <IonSpinner name="crescent" className="spinner-loader" />
                    );
                  }
                })()}
              </IonButton>
            </div>
          </form>
        </div>
      </IonModal>
    </IonContent>
  );
};

const mapStateToProps = (state) => {
  // console.log(state,"in modal")
  return {
    contactList: state.contactReducer.contactList,
    addContactData: state.contactReducer.addContactData,
    loading: state.commonReducer.loading,
    checkUserRole: state.authReducer.checkUserRole,
    groupArray: state.chatReducer.groupArray,
    currentChatRoom: state.chatReducer.currentChatRoom,
    authData: state.authReducer.authData,
    errorMsg: state.commonReducer.errorMsg,
    successMsg: state.commonReducer.successMsg,
    allGroupMembers: state.chatReducer.allGroupMembers,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getContactList,
      addContact,
      clearContactList,
      searchContactList,
      createGroup,
      editGroup,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactModal);
