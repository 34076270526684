import React, { useEffect, useState } from "react";
import { withRouter, useHistory } from "react-router-dom";
import { IonGrid, IonRow, IonCol, IonButton, IonSpinner } from "@ionic/react";
import { connect, useDispatch } from "react-redux";
import "./playerReportingSystem.css";
import { addPlayerAnswers } from "../../redux/actions/prsAction";
import { toast } from "../../Utils/toast";
import { getCoachGoalsName } from "../../redux/actions/prsAction";

const GoalsReport = (props) => {
  const dispatch = useDispatch();
  let history = useHistory();
  const [inputs, setInputs] = useState([]);
  const [nextId, setNextId] = useState(1);

  useEffect(() => {
    getCoach();
  }, []);

  const getCoach = async (e, id, value) => {
    await dispatch(await getCoachGoalsName("match_report"));
  };

  const handleInputChange = (id, value, index) => {
    const newInputs = inputs.map((input, index1) => {
      if (index1 === index) {
        if (!value) {
          return {
            ...input,
            value,
            error: "This field is required",
          };
        } else {
          return {
            ...input,
            value,
            error: "",
          };
        }
      }
      return input;
    });

    setInputs(newInputs);
  };

  const clearFrom = () => {
    setInputs([]);
    history.push("/page/playerSubmitReportSystem");
  };

  const handleAddInput = (id) => {
    let isError = false;
    const newInputs = inputs.map((ele) => {
      if (!ele.value) {
        isError = true;
        return {
          ...ele,
          error: "This field is required",
        };
      }
      return ele;
    });
    setInputs(newInputs);

    if (isError) {
      const newInputs = inputs.map((ele) => {
        if (!ele.value) {
          isError = true;
          return {
            ...ele,
            error: "This field is required",
          };
        }
        return {
          ...ele,
          error: null,
        };
      });
      setInputs(newInputs);
    } else {
      const newInputs1 = inputs.map((ele, index) => {
        return {
          id: index + 1,
          ...ele,
          error: null,
        };
      });
      setInputs([...newInputs1, { id: newInputs1.length + 1, value: "" }]);
      setNextId(nextId + 1);
    }
  };

  const handleDeleteInput = (index) => {
    const filteredInputs = inputs.filter((input,index1) =>index1  !== index);
    setInputs(filteredInputs);
    setNextId(nextId - 1);
  };

  const onSubmit = async () => {
    let isError = false;
    const newInputs = inputs.map((ele) => {
      if (!ele.value) {
        isError = true;
        return {
          ...ele,
          error: "This field is required",
        };
      }
      return ele;
    });
    setInputs(newInputs);
    if (!isError) {
      const player_id = JSON.parse(localStorage.getItem("userData")).id;
      let finalArray = [];

      await inputs.map(async (data, index) => {
        await finalArray.push([data.value, 0, player_id, 0]);
      });

      await dispatch(await addPlayerAnswers(finalArray));
      toast.success("Goals successfully submitted");
      setInputs([]);
    }
  };

  return (
    <div className="reporting-mid-tab">
      <IonGrid>
        <IonRow>
          <IonCol size="12" size-md="12">
            <div className="reporting-mid-right">
              <div className="tab-content">
                <div className="tab-pane active" id="wellness">
                  <div className="reporting-tabbox reporting-tab-display-wrapper">
                    <div className="reporting-tabbox-head">
                      <div className="player-header-div player-header-content">
                        <div className="player-image-div">
                          <img
                            src={
                              props?.goalCoachImage ||
                              "/assets/icon/client-dummy.png"
                            }
                            alt="profileimage"
                          />
                        </div>
                        <div className="player-detail-div">
                          <h5 className="player-name">
                            {props?.goalsCoachName}
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div className="coach-para-text-div">
                      <p className="coach-para-text">
                        Enter some goals for the future. Goals may be tennis,
                        fitness, health, mindfulness, or nutrition related. Be
                        as specific as possible.
                      </p>
                    </div>
                    <div>
                      <div className="input-report-display-main-div">
                        <div className="input-display-div">
                          {inputs.map((input, index) => (
                            <div className="main-clone-input-wrapper">
                              <div className="number-display-div">
                                <h3>{index + 1}</h3>
                              </div>
                              <div
                                key={input + 1}
                                className="input-delete-btn-div"
                              >
                                <div className="input-error-display-div">
                                  <input
                                    type="text"
                                    value={input.value}
                                    placeholder="Type answer here"
                                    onChange={(e) =>
                                      handleInputChange(
                                        input.id,
                                        e.target.value,
                                        index
                                      )
                                    }
                                  />
                                  {input.error && (
                                    <span className="reporting-tabbox-ans-error">
                                      {input.error}
                                    </span>
                                  )}
                                </div>
                                <button
                                  onClick={() => handleDeleteInput(index)}
                                  className="delete-btn"
                                >
                                  <i>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="18"
                                      height="18"
                                      viewBox="0 0 18 18"
                                      fill="none"
                                    >
                                      <path
                                        d="M4.5 14.25C4.5 15.075 5.175 15.75 6 15.75H12C12.825 15.75 13.5 15.075 13.5 14.25V6.75C13.5 5.925 12.825 5.25 12 5.25H6C5.175 5.25 4.5 5.925 4.5 6.75V14.25ZM13.5 3H11.625L11.0925 2.4675C10.9575 2.3325 10.7625 2.25 10.5675 2.25H7.4325C7.2375 2.25 7.0425 2.3325 6.9075 2.4675L6.375 3H4.5C4.0875 3 3.75 3.3375 3.75 3.75C3.75 4.1625 4.0875 4.5 4.5 4.5H13.5C13.9125 4.5 14.25 4.1625 14.25 3.75C14.25 3.3375 13.9125 3 13.5 3Z"
                                        fill="#EA3323"
                                      />
                                    </svg>
                                  </i>
                                </button>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                      <button onClick={handleAddInput} className="add-goal-btn">
                        <i>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="19"
                            viewBox="0 0 18 19"
                            fill="none"
                          >
                            <path
                              d="M13.5 10.25H9.75V14C9.75 14.4125 9.4125 14.75 9 14.75C8.5875 14.75 8.25 14.4125 8.25 14V10.25H4.5C4.0875 10.25 3.75 9.9125 3.75 9.5C3.75 9.0875 4.0875 8.75 4.5 8.75H8.25V5C8.25 4.5875 8.5875 4.25 9 4.25C9.4125 4.25 9.75 4.5875 9.75 5V8.75H13.5C13.9125 8.75 14.25 9.0875 14.25 9.5C14.25 9.9125 13.9125 10.25 13.5 10.25Z"
                              fill="#404F8C"
                            />
                          </svg>
                        </i>
                        Add Goal
                      </button>
                      <div className="reporting-tabbox-btn report-tab-button-wrapper">
                        <button
                          className={"cancel-btn-div"}
                          onClick={clearFrom}
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          color="none"
                          onClick={onSubmit}
                          disabled={inputs.length === 0}
                          className={
                            inputs.length === 0
                              ? "disabled-btn submit-btn-wrapper"
                              : "submit-btn-wrapper"
                          }
                        >
                          Submit
                          {(() => {
                            if (props.loading) {
                              return (
                                <IonSpinner
                                  name="crescent"
                                  // style={{ color: "black" }}
                                  className="spinner-loader"
                                />
                              );
                            }
                          })()}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    playersQuestions: state.prsReducer.playersQuestions,
    isAnswerSubmitted: state.prsReducer.isAnswerSubmitted,
    playersAnswersAfterSubmit: state.prsReducer.playersAnswersAfterSubmit,
    loading: state.commonReducer.loading,
    goalsCoachName: state.prsReducer.goalsCoachName,
    goalCoachImage: state.prsReducer.goalCoachImage,
  };
};

export default withRouter(connect(mapStateToProps)(GoalsReport));
