import {
  IonContent,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
} from "@ionic/react";

import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { withRouter } from "react-router-dom";
import {
  logOut,
  cardOutline,
  homeOutline,
  homeSharp,
  person,
  peopleOutline,
  peopleSharp,
  bookOutline,
  bookSharp,
  videocamOutline,
  videocamSharp,
  chatbubbleOutline,
  chatbubbleSharp,
  calendarOutline,
  calendarSharp,
  heartOutline,
  heartSharp,
  clipboardOutline,
  clipboardSharp,
  logoPlaystation,
} from "ionicons/icons";
import "./Menu.css";
import User from "../images/default_team_logo.png";
import { connect } from "react-redux";
import { logoutUser } from "../redux/actions/authAction";
import { fcmToken } from "../redux/actions/users";
import { bindActionCreators } from "redux";
import { getPlatforms } from "@ionic/react";
import playSightImage from "../images/playsightp.png";
import AssistantImage from "../images/assistant-coaches-icon.png";
import SportalTaksImage from "../images/sporttalk-icon.png";

const paymentPages = [
  {
    title: "Payment",
    url: "/page/payment",
    iosIcon: cardOutline,
    mdIcon: cardOutline,
  },
  {
    title: "Logout",
    // url: "#",
    iosIcon: logOut,
    mdIcon: logOut,
  },
];
const adminPages = [
  {
    title: "Dashboard",
    url: "/page/coach-dashboard",
    iosIcon: homeOutline,
    mdIcon: homeSharp,
  },
  {
    title: "Assistant Coaches",
    url: "/page/assistant-listing",
    iosIcon: peopleOutline,
    mdIcon: peopleSharp,
  },
  {
    title: "Athletes",
    url: "/page/player-listing",
    iosIcon: peopleOutline,
    mdIcon: peopleSharp,
  },
  {
    title: "Calendar",
    url: "/page/calendar",
    iosIcon: calendarOutline,
    mdIcon: calendarSharp,
  },

  {
    title: "Player Reporting System",
    url: "/page/coachReportingSystem",
    iosIcon: clipboardOutline,
    mdIcon: clipboardSharp,
  },
  {
    title: "Contacts",
    url: "/page/contacts",
    iosIcon: bookOutline,
    mdIcon: bookSharp,
  },
  // {
  //   title: "Zoom",
  //   url: "/page/zoom",
  //   iosIcon: videocamOutline,
  //   mdIcon: videocamSharp,
  // },
  {
    title: "PlaySight",
    url: "/page/playSight",
    iosIcon: logoPlaystation,
    mdIcon: logoPlaystation,
  },
  {
    title: "Sport-Talk (Coming soon)",
    // url: "/page/sportTalks",
    iosIcon: chatbubbleOutline,
    mdIcon: chatbubbleSharp,
  },
  {
    title: "Logout",
    // url: "#",
    iosIcon: logOut,
    mdIcon: logOut,
  },
];
const assistantPages = [
  {
    title: "Dashboard",
    url: "/page/assistant-dashboard",
    iosIcon: homeOutline,
    mdIcon: homeSharp,
  },
  {
    title: "Athletes",
    url: "/page/player-listing",
    iosIcon: peopleOutline,
    mdIcon: peopleSharp,
  },
  {
    title: "Calendar",
    url: "/page/calendar",
    iosIcon: calendarOutline,
    mdIcon: calendarSharp,
  },

  {
    title: "Player Reporting System",
    url: "/page/coachReportingSystem",
    iosIcon: clipboardOutline,
    mdIcon: clipboardSharp,
  },
  {
    title: "Contacts",
    url: "/page/contacts",
    iosIcon: bookOutline,
    mdIcon: bookSharp,
  },
  // {
  //   title: "Zoom",
  //   url: "/page/zoom",
  //   iosIcon: videocamOutline,
  //   mdIcon: videocamSharp,
  // },
  {
    title: "PlaySight",
    url: "/page/playSight",
    iosIcon: logoPlaystation,
    mdIcon: logoPlaystation,
  },
  {
    title: "Sport-Talk(Coming soon)",
    // url: "/page/sportTalks",
    iosIcon: chatbubbleOutline,
    mdIcon: chatbubbleSharp,
  },
  {
    title: "Logout",
    // url: "#",
    iosIcon: logOut,
    mdIcon: logOut,
  },
];

const playerPages = [
  {
    title: "Dashboard",
    url: "/page/dashboard",
    iosIcon: homeOutline,
    mdIcon: homeSharp,
  },
  {
    title: "Calendar",
    url: "/page/calendar",
    iosIcon: calendarOutline,
    mdIcon: calendarSharp,
  },

  {
    title: "Player Reporting System",
    url: "/page/playerSubmitReportSystem",
    iosIcon: clipboardOutline,
    mdIcon: clipboardSharp,
  },
  {
    title: "Contacts",
    url: "/page/contacts",
    iosIcon: bookOutline,
    mdIcon: bookSharp,
  },
  // {
  //   title: "Zoom",
  //   url: "/page/zoom",

  //   iosIcon: videocamOutline,
  //   mdIcon: videocamSharp,
  // },
  {
    title: "PlaySight",
    url: "/page/playSight",
    iosIcon: logoPlaystation,
    mdIcon: logoPlaystation,
  },
  {
    title: "Sport-Talk(Coming soon)",
    // url: "/page/sportTalks",
    iosIcon: chatbubbleOutline,
    mdIcon: chatbubbleSharp,
  },
  {
    title: "Logout",
    // url: "#",
    iosIcon: logOut,
    mdIcon: logOut,
  },
];

const Menu = (props) => {
  const location = useLocation();
  const [isLog, setLog] = React.useState(false);

  useEffect(() => {}, [props?.authData?.team_logo]);

  const logout = async (event, title) => {
    event.preventDefault();
    if (title.title === "Logout") {
      let webb = getPlatforms().some(
        (r) => ["desktop", "mobileweb"].indexOf(r) >= 0
      );
      // alert(getPlatforms())
      if (!webb) {
        let data = { user: props.authData.id, fcmToken: "" };
        await props.fcmToken(data);
      } else {
      }
      await props.logoutUser();
      props.history.push("/login");
    } else {
      props.history.push(title.url);
    }
  };

  const onImageError = (e) => {
    e.target.src = "/assets/icon/team_logo.png";
  };

  return (
    <IonMenu contentId="main" type="overlay" className="dashion-menu">
      <IonContent>
        <div className="sidebar-head">
          <div className="sidebar-user">
            <div className="sidebar-user-img">
              <div className="sidebar-user-img-inner">
                <img
                  onError={onImageError}
                  src={
                    props?.authData?.team_logo
                      ? props?.authData?.team_logo
                      : "/assets/icon/team_logo.png"
                  }
                />
              </div>
            </div>
            <div className="sidebar-username">
              <h2>{props?.authData?.team_name}</h2>
            </div>
          </div>
        </div>

        <IonList id="inbox-list" className="menulist">
          {props.checkUserRole === 1
            ? props.checkIsPayment === 1
              ? adminPages.map((appPage, index) => {
                  return (
                    <IonMenuToggle
                      key={index}
                      autoHide={false}
                      className={appPage.title === "Logout" ? "logOutMenu" : ""}
                    >
                      {appPage.title === "PlaySight" ? (
                        <IonItem
                          className={
                            location.pathname === appPage.url ? "selected" : ""
                          }
                          lines="none"
                          detail={false}
                          href="https://playsight.com/"
                          target="_blank"
                        >
                          <div className="sidebar-middiv" tabIndex="0">
                            {/* <IonIcon
                          slot="start"
                          ios={appPage.iosIcon}
                          md={appPage.mdIcon}
                        /> */}
                            <IonImg src={playSightImage}></IonImg>
                            <IonLabel>{appPage.title}</IonLabel>
                          </div>
                        </IonItem>
                      ) : appPage.title === "Assistant Coaches" ||
                        appPage.title === "Sport-Talk" ? (
                        <IonItem
                          className={
                            location.pathname === appPage.url ? "selected" : ""
                          }
                          // routerLink={appPage.url}
                          routerDirection="none"
                          lines="none"
                          detail={false}
                          onClick={(event) => {
                            logout(event, appPage);
                          }}
                        >
                          <div className="sidebar-middiv" tabIndex="0">
                            {/* <IonIcon
                          slot="start"
                          ios={appPage.iosIcon}
                          md={appPage.mdIcon}
                        /> */}
                            {appPage.title === "Assistant Coaches" ? (
                              <IonImg src={AssistantImage}></IonImg>
                            ) : (
                              <IonImg src={SportalTaksImage}></IonImg>
                            )}

                            <IonLabel>{appPage.title}</IonLabel>
                          </div>
                        </IonItem>
                      ) : (
                        <IonItem
                          className={
                            location.pathname === appPage.url ? "selected" : ""
                          }
                          // routerLink={appPage.url}
                          routerDirection="none"
                          lines="none"
                          detail={false}
                          onClick={(event) => {
                            logout(event, appPage);
                          }}
                        >
                          <div className="sidebar-middiv" tabIndex="0">
                            <IonIcon
                              slot="start"
                              ios={appPage.iosIcon}
                              md={appPage.mdIcon}
                            />

                            <IonLabel>{appPage.title}</IonLabel>
                          </div>
                        </IonItem>
                      )}
                    </IonMenuToggle>
                  );
                })
              : paymentPages.map((appPage, index) => {
                  return (
                    <IonMenuToggle
                      key={index}
                      autoHide={false}
                      className={appPage.title === "Logout" ? "logOutMenu" : ""}
                    >
                      <IonItem
                        className={
                          location.pathname === appPage.url ? "selected" : ""
                        }
                        // routerLink={appPage.url}
                        routerDirection="none"
                        lines="none"
                        detail={false}
                        onClick={(event) => {
                          logout(event, appPage);
                        }}
                      >
                        <div className="sidebar-middiv" tabIndex="0">
                          <IonIcon
                            slot="start"
                            ios={appPage.iosIcon}
                            md={appPage.mdIcon}
                          />
                          <IonLabel>{appPage.title}</IonLabel>
                        </div>
                      </IonItem>
                    </IonMenuToggle>
                  );
                })
            : props.checkUserRole === 2
            ? assistantPages.map((appPage, index) => {
                if (props?.authData?.zoomLink) {
                  return (
                    <IonMenuToggle
                      key={index}
                      autoHide={false}
                      className={appPage.title === "Logout" ? "logOutMenu" : ""}
                    >
                      {appPage.title === "PlaySight" ? (
                        <IonItem
                          className={
                            location.pathname === appPage.url ? "selected" : ""
                          }
                          lines="none"
                          detail={false}
                          href="https://playsight.com/"
                          target="_blank"
                        >
                          <div className="sidebar-middiv" tabIndex="0">
                            {/* <IonIcon
                          slot="start"
                          ios={appPage.iosIcon}
                          md={appPage.mdIcon}
                        /> */}
                            <IonImg src={playSightImage}></IonImg>
                            <IonLabel>{appPage.title}</IonLabel>
                          </div>
                        </IonItem>
                      ) : appPage.title === "Sport-Talk" ? (
                        <IonItem
                          className={
                            location.pathname === appPage.url ? "selected" : ""
                          }
                          // routerLink={appPage.url}
                          routerDirection="none"
                          lines="none"
                          detail={false}
                          onClick={(event) => {
                            logout(event, appPage);
                          }}
                        >
                          <div className="sidebar-middiv" tabIndex="0">
                            {/* <IonIcon
                          slot="start"
                          ios={appPage.iosIcon}
                          md={appPage.mdIcon}
                        /> */}
                            <IonImg src={SportalTaksImage}></IonImg>

                            <IonLabel>{appPage.title}</IonLabel>
                          </div>
                        </IonItem>
                      ) : (
                        <IonItem
                          className={
                            location.pathname === appPage.url ? "selected" : ""
                          }
                          // routerLink={appPage.url}
                          routerDirection="none"
                          lines="none"
                          detail={false}
                          onClick={(event) => {
                            logout(event, appPage);
                          }}
                        >
                          <div className="sidebar-middiv" tabIndex="0">
                            <IonIcon
                              slot="start"
                              ios={appPage.iosIcon}
                              md={appPage.mdIcon}
                            />
                            <IonLabel>{appPage.title}</IonLabel>
                          </div>
                        </IonItem>
                      )}
                    </IonMenuToggle>
                  );
                } else {
                  if (appPage.title === "Zoom") {
                    return null;
                  } else {
                    return (
                      <IonMenuToggle
                        key={index}
                        autoHide={false}
                        className={
                          appPage.title === "Logout" ? "logOutMenu" : ""
                        }
                      >
                        {appPage.title === "PlaySight" ? (
                          <IonItem
                            className={
                              location.pathname === appPage.url
                                ? "selected"
                                : ""
                            }
                            lines="none"
                            detail={false}
                            href="https://playsight.com/"
                            target="_blank"
                          >
                            <div className="sidebar-middiv" tabIndex="0">
                              {/* <IonIcon
                          slot="start"
                          ios={appPage.iosIcon}
                          md={appPage.mdIcon}
                        /> */}
                              <IonImg src={playSightImage}></IonImg>
                              <IonLabel>{appPage.title}</IonLabel>
                            </div>
                          </IonItem>
                        ) : appPage.title === "Sport-Talk" ? (
                          <IonItem
                            className={
                              location.pathname === appPage.url
                                ? "selected"
                                : ""
                            }
                            routerDirection="none"
                            lines="none"
                            detail={false}
                            onClick={(event) => {
                              logout(event, appPage);
                            }}
                          >
                            <div className="sidebar-middiv" tabIndex="0">
                              <IonImg src={SportalTaksImage}></IonImg>

                              <IonLabel>{appPage.title}</IonLabel>
                            </div>
                          </IonItem>
                        ) : (
                          <IonItem
                            className={
                              location.pathname === appPage.url
                                ? "selected"
                                : ""
                            }
                            // routerLink={appPage.url}
                            routerDirection="none"
                            lines="none"
                            detail={false}
                            onClick={(event) => {
                              logout(event, appPage);
                            }}
                          >
                            <div className="sidebar-middiv" tabIndex="0">
                              <IonIcon
                                slot="start"
                                ios={appPage.iosIcon}
                                md={appPage.mdIcon}
                              />
                              <IonLabel>{appPage.title}</IonLabel>
                            </div>
                          </IonItem>
                        )}
                      </IonMenuToggle>
                    );
                  }
                }
              })
            : playerPages.map((appPage, index) => {
                if (props?.authData?.zoomLink) {
                  return (
                    <IonMenuToggle
                      key={index}
                      autoHide={false}
                      className={appPage.title === "Logout" ? "logOutMenu" : ""}
                    >
                      {appPage.title === "PlaySight" ? (
                        <IonItem
                          className={
                            location.pathname === appPage.url ? "selected" : ""
                          }
                          lines="none"
                          detail={false}
                          href="https://playsight.com/"
                          target="_blank"
                        >
                          <div className="sidebar-middiv" tabIndex="0">
                            {/* <IonIcon
                          slot="start"
                          ios={appPage.iosIcon}
                          md={appPage.mdIcon}
                        /> */}
                            <IonImg src={playSightImage}></IonImg>
                            <IonLabel>{appPage.title}</IonLabel>
                          </div>
                        </IonItem>
                      ) : appPage.title === "Sport-Talk" ? (
                        <IonItem
                          className={
                            location.pathname === appPage.url ? "selected" : ""
                          }
                          // routerLink={appPage.url}
                          routerDirection="none"
                          lines="none"
                          detail={false}
                          onClick={(event) => {
                            logout(event, appPage);
                          }}
                        >
                          <div className="sidebar-middiv" tabIndex="0">
                            {/* <IonIcon
                          slot="start"
                          ios={appPage.iosIcon}
                          md={appPage.mdIcon}
                        /> */}
                            <IonImg src={SportalTaksImage}></IonImg>

                            <IonLabel>{appPage.title}</IonLabel>
                          </div>
                        </IonItem>
                      ) : (
                        <IonItem
                          className={
                            location.pathname === appPage.url ? "selected" : ""
                          }
                          // routerLink={appPage.url}
                          routerDirection="none"
                          lines="none"
                          detail={false}
                          onClick={(event) => {
                            logout(event, appPage);
                          }}
                        >
                          <div className="sidebar-middiv" tabIndex="0">
                            <IonIcon
                              slot="start"
                              ios={appPage.iosIcon}
                              md={appPage.mdIcon}
                            />
                            <IonLabel>{appPage.title}</IonLabel>
                          </div>
                        </IonItem>
                      )}
                    </IonMenuToggle>
                  );
                } else {
                  if (appPage.title === "Zoom") {
                    return null;
                  } else {
                    return (
                      <IonMenuToggle
                        key={index}
                        autoHide={false}
                        className={
                          appPage.title === "Logout" ? "logOutMenu" : ""
                        }
                      >
                        {appPage.title === "PlaySight" ? (
                          <IonItem
                            className={
                              location.pathname === appPage.url
                                ? "selected"
                                : ""
                            }
                            lines="none"
                            detail={false}
                            href="https://playsight.com/"
                            target="_blank"
                          >
                            <div className="sidebar-middiv" tabIndex="0">
                              <IonImg src={playSightImage}></IonImg>
                              <IonLabel>{appPage.title}</IonLabel>
                            </div>
                          </IonItem>
                        ) : appPage.title === "Sport-Talk" ? (
                          <IonItem
                            className={
                              location.pathname === appPage.url
                                ? "selected"
                                : ""
                            }
                            // routerLink={appPage.url}
                            routerDirection="none"
                            lines="none"
                            detail={false}
                            onClick={(event) => {
                              logout(event, appPage);
                            }}
                          >
                            <div className="sidebar-middiv" tabIndex="0">
                              {/* <IonIcon
                          slot="start"
                          ios={appPage.iosIcon}
                          md={appPage.mdIcon}
                        /> */}
                              <IonImg src={SportalTaksImage}></IonImg>

                              <IonLabel>{appPage.title}</IonLabel>
                            </div>
                          </IonItem>
                        ) : (
                          <IonItem
                            className={
                              location.pathname === appPage.url
                                ? "selected"
                                : ""
                            }
                            // routerLink={appPage.url}
                            routerDirection="none"
                            lines="none"
                            detail={false}
                            onClick={(event) => {
                              logout(event, appPage);
                            }}
                          >
                            <div className="sidebar-middiv" tabIndex="0">
                              <IonIcon
                                slot="start"
                                ios={appPage.iosIcon}
                                md={appPage.mdIcon}
                              />
                              <IonLabel>{appPage.title}</IonLabel>
                            </div>
                          </IonItem>
                        )}
                      </IonMenuToggle>
                    );
                  }
                }
              })}
        </IonList>
      </IonContent>
    </IonMenu>
  );
};
const mapStateToProps = (state) => {
  return {
    isRegistered: state.authReducer.isRegistered,
    isLoggedIn: state.authReducer.isLoggedIn,
    userToken: state.authReducer.userToken,
    authData: state.authReducer.authData,
    checkUserRole: state.authReducer.checkUserRole,
    checkIsPayment: state.authReducer.checkIsPayment,
    zoomPayload: state.zoomReducer.zoomPayload,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ logoutUser, fcmToken }, dispatch);
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Menu));
