import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import {
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonList,
  IonItem,
  IonCheckbox,
  IonSpinner,
} from "@ionic/react";
import { useDispatch } from "react-redux";
import {
  getQuestionsForCoach,
  addQuestionsForCoach,
} from "../../redux/actions/prsAction";
import { connect } from "react-redux";

const MonthlyReport = (props) => {
  const dispatch = useDispatch();
  const [selected, setSelected] = useState([]);
  const [errorMessage, setErrorMessage] = useState(false);

  React.useEffect(() => {
    componentDidMount();
  }, []);

  React.useEffect(() => {
    dispatch({
      type: "Clear_Reporting_System",
      selectedCoachQuestion: [],
    });
  }, []);

  React.useEffect(() => {
    setSelected([...props.matchReportSelectedID]);
  }, [props.matchReportSelectedID]);

  async function componentDidMount() {
    await dispatch(
      await getQuestionsForCoach({
        type: "match_report",
        c_id: JSON.parse(localStorage.getItem("userData")).id,
      })
    );
  }

  const OnClickSubmit = async () => {
    const data = {
      question_ids: selected,
      type: "match_report",
      c_id: JSON.parse(localStorage.getItem("userData")).id,
    };

    if (data?.question_ids.length < 1) {
      setErrorMessage(true);
    } else {
      setErrorMessage(false);
      await dispatch(await addQuestionsForCoach(data));
      await dispatch(
        await getQuestionsForCoach({
          type: "match_report",
          c_id: JSON.parse(localStorage.getItem("userData")).id,
        })
      );
      // setSelected([]);
    }
  };

  const handleChangeAllQuestion = (event) => {
    if (event.target.checked) {
      const finalQ = props?.Questionnaire?.data.map((aItem) => aItem.id);
      setSelected(finalQ);
      return;
    } else {
      setSelected([]);
    }
  };

  const handleChange = async (event) => {
    const target = event.target;
    var value = Number(target.value);

    if (target.checked) {
      setSelected((oldArray) => [...oldArray, value]);
    } else {
      let filteredArray = await selected.filter((val) => val !== value);
      await setSelected(filteredArray);
    }
  };

  return (
    <div className="reporting-mid-tab">
      <IonGrid>
        <IonRow>
          <IonCol size="12" size-md="12">
            <div className="reporting-mid-right">
              <div className="tab-content">
                <div className="tab-pane active" id="wellness">
                  <div className="reporting-tabbox">
                    <div className="reporting-tabbox-mid goals-report-box-wrapper">
                      <div className="question-main-wrapper">
                      <label class="input-label all-question-input-label">
                        All Questions
                        <input
                          type="checkbox"
                          id="horns"
                          name="horns"
                          checked={
                            props?.Questionnaire?.data.length ===
                            selected.length
                          }
                          onChange={(e) => handleChangeAllQuestion(e)}
                        />
                        <span class="checkmark"></span>
                        </label>
                        {/* <p>All Questions</p> */}
                      </div>
                      <div className="add-questionairre">
                        <IonList lines="none">
                          <div>
                            {props?.Questionnaire?.data.length > 0 ? (
                              props?.Questionnaire?.data?.map((data, index) => {
                                return (
                                  <div key={index}>
                                    <IonItem key={index}>
                                    <label class="input-label">
                                      {data.question}
                                      <input
                                        type="checkbox"
                                        value={data.id}
                                        id={data.id}
                                        name="helloby"
                                        checked={selected.includes(data.id)}
                                        onChange={(e) => handleChange(e)}
                                      />
                                      <span class="checkmark"></span>
                                      </label>
                                      {/* <IonLabel>{data.question}</IonLabel> */}
                                    </IonItem>
                                  </div>
                                );
                              })
                            ) : (
                              <div className="questionnaire-detal">
                                <div className="questionnaire-detal-heading">
                                  {props?.loading === true ? (
                                    <div className="loading-pera">
                                      <p>Loading...</p>
                                    </div>
                                  ) : (
                                    <h5>No Question Added.</h5>
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                          {errorMessage === true ? (
                            <div className="errorMessage">
                              <p>Please select at least one question</p>
                            </div>
                          ) : null}
                          <div className="questionairre-submit-btn">
                            <a onClick={OnClickSubmit}>
                              Submit{" "}
                              {(() => {
                                if (props.loading) {
                                  return (
                                    <IonSpinner
                                      name="crescent"
                                      className="spinner-loader"
                                    />
                                  );
                                }
                              })()}
                            </a>
                          </div>
                        </IonList>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    Questionnaire: state.prsReducer.Questionnaire,
    isQuestionnaire: state.prsReducer.isQuestionnaire,
    matchReportSelectedID: state.prsReducer.matchReportSelectedID,
    coachQuestions: state.prsReducer.coachQuestions,
    loading: state.commonReducer.loading,
  };
};

export default withRouter(connect(mapStateToProps)(MonthlyReport));
