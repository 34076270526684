import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { IonGrid, IonRow, IonCol, IonList } from "@ionic/react";
import { connect } from "react-redux";

const GoalsReport = (props) => {
  return (
    <div className="reporting-mid-tab">
      <IonGrid>
        <IonRow>
          <IonCol size="12" size-md="12">
            <div className="reporting-mid-right">
              <div className="tab-content">
                <div className="tab-pane active" id="wellness">
                  <div className="reporting-tabbox">
                    <div className="reporting-tabbox-mid goals-report-box-wrapper">
                      <div className="add-questionairre">
                        <IonList lines="none">
                          <div className="question-main-wrapper">
                            <p>All Questions</p>
                          </div>
                          <div className="enter-goals-text">
                            <p>
                              {" "}
                              Enter some goals for the future. Goals may be tennis, fitness, health, mindfulness, or nutrition related. Be as specific as possible.
                            </p>
                          </div>
                        </IonList>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    Questionnaire: state.prsReducer.Questionnaire,
    isQuestionnaire: state.prsReducer.isQuestionnaire,
    coachQuestions: state.prsReducer.coachQuestions,
    loading: state.commonReducer.loading,
  };
};

export default withRouter(connect(mapStateToProps)(GoalsReport));
