import React, { useEffect } from "react";

import {
  IonTabs,
  IonTabBar,
  IonTabButton,
  IonLabel,
  IonRouterOutlet,
  IonContent,
  IonImg,
} from "@ionic/react";
import { Route, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import LikeGrey from "../../images/like-grey.png";
import LikeBlue from "../../images/like-blue.png";
import GoalGrey from "../../images/goal-grey.png";
import GoalBlue from "../../images/goal-blue.png";
import { getQuestionById } from "../../redux/actions/prsAction";
import { changeReadStatus } from "../../redux/actions/zoomAction";
import { getNotifications } from "../../redux/actions/users";
import { useDispatch } from "react-redux";
import "./playerReportingSystem.css";
import WeeklyReport from "./weeklyReport";
import MatchingReport from "./matchReporting";
import goalsReport from "./goalsReport";


const PRS = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    componentDidMount();
  }, []);

  useEffect(() => {
    shouldComponentUpdate();
  }, [props.notificationData]);

  async function componentDidMount() {
    if (props.location?.state?.event?.insertId) {
      await getPRS(props.location.state.event.insertId);
    } else if (props.location?.state?.category) {
      let notifData = props.location?.state;
      if (
        notifData.category === 1 ||
        notifData.category === 2 ||
        notifData.category === 3
      ) {
        await props.history.push("/page/playerReportingSystem/weekly");
        if (notifData.category === 1) {
          if (document.getElementById("Wellness")) {
            document.getElementById("Wellness").click();
          }
        } else if (notifData.category === 2) {
          if (document.getElementById("Tennis")) {
            document.getElementById("Tennis").click();
          }
        } else {
          if (document.getElementById("Goals")) {
            document.getElementById("Goals").click();
          }
        }
      } else if (
        notifData.category === 4 ||
        notifData.category === 5 ||
        notifData.category === 6
      ) {
        await props.history.push("/page/playerReportingSystem/match");
        if (notifData.category === 4) {
          if (document.getElementById("Tennis_Match_Report")) {
            document.getElementById("Tennis_Match_Report").click();
          }
        } else if (notifData.category === 5) {
          if (document.getElementById("Health")) {
            document.getElementById("Health").click();
          }
        } else {
          if (document.getElementById("Team")) {
            document.getElementById("Team").click();
          }
        }
      }
    }
  }

  async function shouldComponentUpdate() {
    let notifData = await props.notificationData;
    if (notifData) {
      if (
        notifData.category === 1 ||
        notifData.category === 2 ||
        notifData.category === 3
      ) {
        await props.history.push("/page/playerReportingSystem/weekly");
        if (notifData.category === 1) {
          if (document.getElementById("Wellness")) {
            document.getElementById("Wellness").click();
          }
        } else if (notifData.category === 2) {
          if (document.getElementById("Tennis")) {
            document.getElementById("Tennis").click();
          }
        } else {
          if (document.getElementById("Goals")) {
            document.getElementById("Goals").click();
          }
        }
      } else if (
        notifData.category === 4 ||
        notifData.category === 5 ||
        notifData.category === 6
      ) {
        await props.history.push("/page/playerReportingSystem/match");
        if (notifData.category === 4) {
          if (document.getElementById("Tennis_Match_Report")) {
            document.getElementById("Tennis_Match_Report").click();
          }
        } else if (notifData.category === 5) {
          if (document.getElementById("Health")) {
            document.getElementById("Health").click();
          }
        } else {
          if (document.getElementById("Team")) {
            document.getElementById("Team").click();
          }
        }
      } else {
      }
      notif();
    }
  }
  const notif = async () => {
    await props.changeReadStatus(props?.location?.state?.id);
    await dispatch({ type: "CLEAN_DATA", payload: [] });
    await props.getNotifications({ page: 0 });
  };

  const getPRS = async (id) => {
    await props.getQuestionById(id);
  };
  return (
    <IonContent>
      <div className="reporting-inner-body coach-report-body-wrapper">
        <div className="reporting-inner-body-top">
          <div className="player-reporting-heading">
            <h2>Player Reporting System</h2>
          </div>
        </div>

        <IonTabs className="coach-tabs-display-div">
          <IonTabBar slot="top">
            <IonTabButton
              tab="weekly"
              href="/page/playerReportingSystem/weekly"
            >
              <div className="player-top-tab">
                <div className="tablabel">
                  <IonLabel>Check-In</IonLabel>
                </div>
              </div>
            </IonTabButton>
            <IonTabButton tab="goals" href="/page/playerReportingSystem/goals">
              <div className="player-top-tab">
                <div className="tablabel">
                  <IonLabel>Goals</IonLabel>
                </div>
              </div>
            </IonTabButton>
            <IonTabButton tab="match" href="/page/playerReportingSystem/match">
              <div className="player-top-tab">
                <div className="tablabel">
                  <IonLabel>Match Report</IonLabel>
                </div>
              </div>
            </IonTabButton>
          </IonTabBar>

          <IonRouterOutlet id="main three">
            <Route
              path="/page/playerReportingSystem/:tab(weekly)"
              component={WeeklyReport}
              exact={true}
            />
            <Route
              path="/page/playerReportingSystem"
              component={WeeklyReport}
              exact={true}
            />
            <Route
              path="/page/playerReportingSystem/:tab(goals)"
              component={goalsReport}
              exact={true}
            />
            <Route
              path="/page/playerReportingSystem/:tab(match)"
              component={MatchingReport}
            />
          </IonRouterOutlet>
        </IonTabs>
      </div>
    </IonContent>
  );
};

const mapStateToProps = (state) => {
  return {
    notificationData: state.prsReducer.notificationData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { getQuestionById, changeReadStatus, getNotifications },
    dispatch
  );
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PRS));
