//@flow

export default function timeDifference(current, previous) {

    var msPerMinute = 60 * 1000;
    var msPerHour = msPerMinute * 60;
    var msPerDay = msPerHour * 24;
    var msPerMonth = msPerDay * 30;
    var msPerYear = msPerDay * 365;
  
    var elapsed = current - previous;
  
    if (elapsed < msPerMinute) {
         return Math.floor(elapsed/1000) + ' seconds ago';   
    }
  
    else if (elapsed < msPerHour) {
         return Math.floor(elapsed/msPerMinute) + ' minutes ago';   
    }
  
    else if (elapsed < msPerDay ) {
         return Math.floor(elapsed/msPerHour ) + ' hours ago';   
    }
  
    else if (elapsed < msPerMonth) {
        return Math.floor(elapsed/msPerDay) + ' days ago';   
    }
  
    else {
     var month_name = function(dt){
         let mlist = [ "Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec" ];
          return mlist[dt.getMonth()]
     };
     var lastDate = new Date(previous);
     let day = lastDate.getDate();
     let month = month_name(lastDate);
     let year = lastDate.getFullYear().toString();

     var timeString = lastDate.toLocaleTimeString();
     var hourEnd = timeString.indexOf(":");
     var H = +timeString.substr(0, hourEnd);
     var h = H % 12 || 12;
     var ampm = H < 12 ? " AM" : " PM";
     timeString = h + timeString.substr(hourEnd, 3) + ampm;

     let dateFormate = `${day} ${month} ${year} at ${timeString}`;
        return dateFormate;   
    }
  }