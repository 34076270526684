export const initialState = {
    userData: "",
    addUser: "",
    userListing:[],
    playerListing:[],
    assistantListing:[],
    limitOffset:{limit: 8,offset:0},
    userProfileData:{},
    userNotifications:[],
    isNotificationFound:true,
    unreadNotificationCount:0,
    userDocuments:[],
    country:[],
    city:[],
    stateArray:[]
}


 const userReducer = (state = initialState, action) => {
    switch (action.type) {
            case "ADD_USER":
            return {
                ...state,
                addUser: action.payload
            };
            case "GET_USER_DETAILS":
            return {
                ...state,
                userListing: [...state.userListing, ...action.payload.data] 
            };
            case "USER_PROFILE_DATA":
            return {
                ...state,
                userProfileData: action.payload.data
            };
            case "PLAYER_DETAILS":
                return {
                    ...state,
                    playerListing: action.payload.data
                };
            case "ASSISTANT_DETAILS":
                return {
                    ...state,
                    assistantListing:action.payload.data
                };
                
            
            case "CLEAN_USERS_DETAILS":
            return {
                ...state,
                userListing: action.payload,
                // userNotifications:[]
            };
            case "CLEAN_DATA":
            return {
                ...state,
                userNotifications:[],
                unreadNotificationCount:0
            };
            case "SET_LIMIT_OFFSET":
            return {
                ...state,
                limitOffset: action.payload
            };
            case "USER_NOTIFICATIONS":
                return{
                    ...state,
                    userNotifications: [...state.userNotifications, ...action.payload.data],
                    isNotificationFound:action.payload.dataFound,
                    unreadNotificationCount:action.payload.count
                }

            case "USER_DOCUMENTS":
                return{
                    ...state,
                    userDocuments:action.payload.data
                }
                case "CLEAR_USER_DOCUMENTS":
                    return{
                        ...state,
                        userDocuments:[]
                    }

                case "COUNTRY":
                    return{
                        ...state,
                        country:action.payload.data
                    }
                case "STATE":
                    return{
                        ...state,
                        stateArray:action.payload.data
                    }
                case "CITY":
                    return{
                        ...state,
                        city:action.payload.data
                    }
        default:
            return state;
    }
}

export default userReducer;