import React from 'react';
import { IonContent} from '@ionic/react';



const NotFound404 = () => {

  return (
   <IonContent><div className="dash-inner-body"><h1>Page Not Found!</h1></div></IonContent>
  );
};

export default NotFound404;
