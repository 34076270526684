import { IonIcon, IonButton, IonModal } from "@ionic/react";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { close } from "ionicons/icons";
import { cleanUpData } from "../../redux/actions/prsAction";
import { useDispatch } from "react-redux";

const PlayerSubmittedViewReportModel = (props) => {
  const dispatch = useDispatch();
  const [reportData, setReportData] = useState([]);

  useEffect(() => {
    formatReportingData();
  }, [props.checkPlayerSubmittedReportingData]);

  async function formatReportingData() {
    var finalArr = await Object.values(
      props?.checkPlayerSubmittedReportingData?.reduce(
        (
          result,
          {
            id,
            type,
            answer,
            question_id,
            player_id,
            couch_question_id,
            created_ts,
            question,
            category,
            date_diff,
            fullname,
            imagename
          }
        ) => {
          // Create new group
          if (!result[category])
            result[category] = {
              category,
              newData: [],
            };
          // Append to group
          result[category].newData.push({
            id,
            answer,
            type,
            question_id,
            player_id,
            couch_question_id,
            created_ts,
            question,
            date_diff,
            fullname,
            imagename
          });
          return result;
        },
        {}
      )
    );

    setReportData(finalArr);
  }

  return (
    <IonModal
      isOpen={props.showModal}
      cssClass="player-report-popup report-athlete-modal-wrapper"
      swipeToClose={true}
      backdropDismiss={false}
      onDidDismiss={() => {
        dispatch(cleanUpData());
        props.setShowModal(false);
      }}
      onWillPresent={() => {}}
    >
      <div className="adduser-popup">
        <div className="popup-header">
          <div className="player-header-div">
            <div className="player-image-div">
              <img
                src={
                  reportData[0]?.newData[0]?.imagename ||
                  "/assets/icon/client-dummy.png"
                }
                alt="profileimage"
              />
            </div>

            <div className="player-detail-div">
              <h5 className="player-name">
                {reportData[0]?.newData[0]?.fullname}
              </h5>
              <p className="player-date">
                {reportData[0]?.newData[0]?.type === "weekly_checkin"
                  ? "Check In"
                  : reportData[0]?.newData[0]?.type === "match_report"
                  ? "Match Report"
                  : "Goals"}
                <span>
                  {moment(reportData[0]?.newData[0]?.created_ts).format(
                    "MM-DD-YYYY hh:mm A"
                  )}
                </span>
              </p>
            </div>
          </div>

          <div className="errormsg">
            <p>{props?.errorMsg}</p>
          </div>
          <IonButton onClick={() => props.setShowModal(false)}>
            <IonIcon icon={close} />
          </IonButton>
        </div>

        {reportData?.map((data, i) => {
          return (
            <>
              <div className="popup-mid">
                {data.newData.map((newobj, index) => {
                  if (
                    newobj?.type === "match_report" ||
                    newobj?.type === "weekly_checkin"
                  ) {
                    return (
                      <div className="question-display-wrapper" key={index}>
                        <div className="question-content-wrapper">
                          <div className="question-div">
                            <p>Q</p>
                          </div>
                          <div className="question-display-div">
                            <p>{newobj.question}</p>
                          </div>
                        </div>
                        <div className="ans-content-wrapper">
                          <div className="ans-div">
                            <p>A</p>
                          </div>
                          <div className="ans-display-div">
                            <p>{newobj.answer}</p>
                          </div>
                        </div>
                      </div>
                    );
                  }
                  if (!newobj?.type) {
                    return (
                      <div
                        className="question-display-wrapper goals-numerical-question-wrapper"
                        key={index}
                      >
                        <div className="ans-content-wrapper">
                          <div className="ans-div">
                            <p>{index + 1}</p>
                          </div>
                          <div className="ans-display-div">
                            <p>{newobj.answer}</p>
                          </div>
                        </div>
                      </div>
                    );
                  }
                })}
              </div>
            </>
          );
        })}
      </div>
    </IonModal>
  );
};

const mapStateToProps = (state) => {
  return {
    checkPlayerSubmittedReportingData:
      state.prsReducer.checkPlayerSubmittedReportingData,
  };
};

export default withRouter(
  connect(mapStateToProps)(PlayerSubmittedViewReportModel)
);
