export const initialState = {
  chatRoom: null,
  currentChatRoom: null,
  allChatRoom: [],
  groupArray: [],
  allGroups: [],
  allGroupMembers: [],
  GroupChatData: {},
};

const chatReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_CHAT_ROOM":
      return {
        ...state,
        chatRoom: action.payload.data,
      };
    case "CHANGE_CHAT_ROOM":
      return {
        ...state,
        currentChatRoom: action.payload,
      };
    case "GET_ALL_CHAT_ROOM":
      return {
        ...state,
        allChatRoom: action.payload.data,
      };

    case "CREATE_GROUP_ARRAY":
      return {
        ...state,
        groupArray: action.payload,
      };
    case "GET_GROUP_ARRAY":
      return {
        ...state,
        allGroups: action.payload.data,
      };
    case "EMPTY_ARRAY":
      return {
        ...state,
        groupArray: [],
        allGroupMembers: [],
      };
    case "GET_GROUP_MEMBERS":
      return {
        ...state,
        allGroupMembers: action.payload.data,
      };
    case "GET__MEMBERS":
      return {
        ...state,
        allGroupMembers: action.payload.data,
      };
    case "CHAT_DATA":
      return {
        ...state,
        GroupChatData: action.payload,
      };
    default:
      return state;
  }
};

export default chatReducer;
