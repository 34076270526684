import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonList,
  IonItem,
  IonInput,
  IonButton,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonImg,
} from "@ionic/react";
import React, { useEffect } from "react";
import Target from "../../../images/whistle.png";
import {Link,withRouter} from "react-router-dom";
import { getAssistantListing } from "../../../redux/actions/users";
import { useDispatch, connect } from "react-redux";

const AssistantCoachList = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAssistantListing({ limit: 5, offset: 0, role: 2, userRole: 1 }));
  }, []);

  return (
    <IonCard className="coach-dashboard-card">
      <IonCardHeader className="dashboard-card-head">
        <h3>Assistant Coaches</h3>
        <a href="javascript:void(0)" title="ASSISTANT COACH LIST">
          <div className="dashboard-card-icon">
            <div className="dashboard-card-icon-inner" onClick={(e)=>{props.history.push("/page/assistant-listing")}}>
              <IonImg src={Target} />
            </div>
          </div>
        </a>
      </IonCardHeader>
      <IonCardContent className="dashboard-card-body">
        <div className="dash-table">
          <div className="dashtable-responsive">
            <table className="table">
              <thead>
                <tr className="tableHead">
                  <td>
                    <p>Coach Name</p>
                  </td>
                </tr>
              </thead>
              
                {props?.assistantListing?.length > 0 ? props.assistantListing?.map((data, i) => {
                  return (
                    <tbody>
                    <tr>
                      <td>
                        <p>
                          <a>{data.fullname}</a>
                        </p>
                      </td>
                    </tr>
                    </tbody>
                  );
                }) : props.loading === true ? <p style={{padding: 10}}>Loading...</p>: <p style={{padding: 10}}>No assistant coaches added.</p> }
              
            </table>
          </div>
        {props?.assistantListing?.length > 0 ? <div className="dash-table-bottom">
            {/* <a href="#">See More</a> */}
            <Link to="/page/assistant-listing">See More</Link>
          </div> : null}
          
        </div>
      </IonCardContent>
    </IonCard>
  );
};

const mapStateToProps = (state) => {
  return {
    assistantListing: state.userReducer.assistantListing,
    loading: state.commonReducer.loading,
  };

};

export default withRouter(connect(mapStateToProps)(AssistantCoachList));
