import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonList,
  IonItem,
  IonInput,
  IonButton,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonImg,
} from "@ionic/react";
import React, { useEffect } from "react";
import Target from "../../../images/dash-target.svg";
import { getPlayerCurrentGoals } from "../../../redux/actions/prsAction";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";

const CurrentGoals = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    componentDidMount();
  }, []);

  async function componentDidMount() {
    await dispatch(await getPlayerCurrentGoals());
  }

  return (
    <IonCard className="dashboard-card">
      <IonCardHeader className="dashboard-card-head">
        <h3>CURRENT GOALS</h3>
        <a title="Edit Goals">
          <div className="dashboard-card-icon">
            <div className="dashboard-card-icon-inner">
              <img src={Target} />
            </div>
          </div>
        </a>
      </IonCardHeader>
      <IonCardContent className="dashboard-card-body">
        <div className="dash-table">
          <div className="dashtable-responsive">
            <table className="table">
              <tbody>
                {props?.currentGoals?.length > 0 ? (
                  props?.currentGoals?.map((data, i) => {
                    return (
                      <tr className="tableCol">
                        <td>
                          <p
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <span className="logls-round"></span>
                            {data.answer.length < 100
                              ? data.answer
                              : data.answer.substring(0, 100) + "....."}
                          </p>
                        </td>
                      </tr>
                    );
                  })
                ) : props.loading === true ? (
                  <p style={{ padding: 10 }}>Loading...</p>
                ) : (
                  <p style={{ padding: 10 }}>
                    You don’t have any current goals.
                  </p>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </IonCardContent>
    </IonCard>
  );
};

const mapStateToProps = (state) => {
  return {
    currentGoals: state.prsReducer.currentGoals,
  };
};

export default connect(mapStateToProps)(CurrentGoals);
