import Dashboard from './Dashboard/dashboard';
import CalendarClass from "./calendar/calendar";
import SportTalks from "./sportTalks/sportTalks";
import Zoom from "./zoom/zoom";
import Contacts from "./contacts/contact";
import PRS from "./playerReportingSystem/playerReportingSystem";
import CRS from "./coachReportingSystem/playerReportingSystem";
import MyProfile from "./myProfile/myProfile";
import CoachDashboard from "./coachDashboard/coachDashboard";
import Stripe from "./stripe/stripe";
import AssistantCoachListing from "./assistantCoachListing/assistantCoach";
import PlayerListing from "./playersListing/players";
// import AssistantDashboard from "./assistantDashboard/assistantDashboard";
import Questionnaire from "./questionairre/playerReportingSystem"
import Chat from "./chat/chat"
import MyForms from "./myProfile/myForms"
import PSRS from "../components/playerSubmitReportSystem/playerSubmittedReportingSystem"

import PlayLink from "./playLink/playLink";
export {
Dashboard,
CalendarClass,
SportTalks,
PRS,
Zoom,
Contacts,
MyProfile,
CoachDashboard,
AssistantCoachListing,
Stripe,
PlayerListing,
CRS,
MyForms,
PlayLink,
PSRS,
// AssistantDashboard,
Questionnaire,
Chat
}