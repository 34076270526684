import React, { useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { connect } from "react-redux";
import {
  getStripeAction,
  changeUserPaymentStatus,
  savePaymentResponse,
} from "../../redux/actions/stripeAction";
import { checkPayment } from "../../redux/actions/authAction";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
// import CardSection from "./cardSection";
import { IonButton, IonSpinner } from "@ionic/react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import "./cardSection.css";

const URL = process.env.REACT_APP_API_URL;

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

function CheckoutForm(props) {
  const history = useHistory();

  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isButtonDisabled, setButtonDisabled] = useState(true);
  const stripe = useStripe();
  const elements = useElements();

  const handleCardChange = (event) => {
    setButtonDisabled(event.empty || !event.complete);
    setError(event.error ? event.error.message : null);
  };

  const handleSubmit = async (event) => {
    setIsLoading(true);
    event.preventDefault();

    if (!stripe || !elements || isButtonDisabled) {
      setIsLoading(false);
      //setError("Please enter your payment card details.");
      //return;
    }

    const { paymentMethod, error } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });

    let paymentMethodId = null;
    if (error) {
      setError(error.message);
      setIsLoading(false);
      return;
    } else {
      paymentMethodId = paymentMethod.id;
    }

    const data =
      (await getStripeAction(
        props.product.id,
        props?.authData?.email,
        paymentMethodId
      )) ?? null;
    const clientSecret =
      data !== undefined && data !== null ? data.clientSecret : null;
    const subscriptionId =
      data !== undefined && data !== null ? data.subscriptionId : null;

    if (
      clientSecret !== undefined &&
      clientSecret !== null &&
      clientSecret !== false
    ) {
      const cardElement = elements.getElement(CardElement);

      await stripe
        .confirmCardPayment(clientSecret, {
          payment_method: {
            card: cardElement,
            billing_details: {
              name: props.authData.fullname,
            },
          },
        })
        .then(async function (result) {
          if (result.error === undefined) {
            var intervalId = window.setInterval(async function () {
              let user;
              const userData = JSON.parse(localStorage.getItem("userData"));
              const res = await axios({
                method: "get",
                url: `${URL}/api/v1/user/${userData.id}`,
                headers: {
                  authorization: localStorage.getItem("user"),
                },
              });
              user = res.data.data.data;
              if (
                user !== null &&
                user !== undefined &&
                user.stripe_customer_id !== null &&
                user.stripe_customer_id !== undefined &&
                user.stripe_customer_id.length > 0
              ) {
                clearInterval(intervalId);
                setIsLoading(false);
                props.callback();
                // props.checkPayment({ isPayment: 1 });
                // const authUserData = this.props.authData;
                // await (authUserData.is_payment = 1);
                // localStorage.setItem("userData", JSON.stringify(authUserData));
                // this.props.history.push("/page/coach-dashboard");
                // history.push("/page/coach-dashboard");
                // const form = document.createElement('form');
                // form.method = "POST";
                // form.action = window.location.href;
                // document.body.appendChild(form);
                // form.submit();
                // window.location.href = window.location.href;
                // window.location.reload();
              }
            }, 2500);
          } else {
            setIsLoading(false);
            setError(result.error.message);
          }
        });
    } else if (subscriptionId !== undefined && subscriptionId !== null) {
      var intervalId = window.setInterval(async function () {
        let user;
        const userData = JSON.parse(localStorage.getItem("userData"));
        const res = await axios({
          method: "get",
          url: `${URL}/api/v1/user/${userData.id}`,
          headers: {
            authorization: localStorage.getItem("user"),
          },
        });
        user = res.data.data.data;
        if (
          user !== null &&
          user !== undefined &&
          user.stripe_customer_id !== null &&
          user.stripe_customer_id !== undefined &&
          user.stripe_customer_id.length > 0
        ) {
          clearInterval(intervalId);
          setIsLoading(false);
          props.callback();
        }
      }, 2500);
    } else {
      setIsLoading(false);
      setError("There was an error making the purchase");
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && isButtonDisabled) {
      event.preventDefault();
    }
  };

  return (
    <>
      <div className="stripe">
        <div className="stripe-card">
          <div className="stripe-card-head">
            <h2>Card Details</h2>
            {error ? (
              <div className="errormsg">
                <p>{error}</p>
              </div>
            ) : null}
          </div>

          <div className="stripe-card-body">
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label>Username:</label>
                <p>{props?.authData?.fullname}</p>
              </div>
              <div className="form-group">
                <label>Email Address:</label>
                <p>{props?.authData?.email}</p>
              </div>
              <div className="form-group">
                <label>Team Name:</label>
                <p>{props?.authData?.team_name}</p>
              </div>
              <div className="form-group">
                <label>Payment Amount:</label>
                <p>
                  {Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                  }).format(props.product.unit_amount_decimal / 100)}
                </p>
              </div>
              <CardElement
                onChange={handleCardChange}
                onKeyPress={handleKeyPress}
                options={CARD_ELEMENT_OPTIONS}
              />
              <div className="stripe-card-btn">
                <IonButton
                  className="gradient-btn-blue"
                  color="none"
                  //disabled={isButtonDisabled}
                  type="submit"
                >
                  Let’s Go!
                  {(() => {
                    if (isLoading) {
                      return (
                        <IonSpinner
                          name="crescent"
                          className="spinner-loader"
                        />
                      );
                    }
                  })()}
                </IonButton>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
  // }
}

const mapStateToProps = (state) => {
  return {
    clientSecret: state.stripeReducer.clientSecret,
    errorMsg: state.commonReducer.errorMsg,
    successMsg: state.commonReducer.successMsg,
    authData: state.authReducer.authData,
    loading: state.commonReducer.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getStripeAction,
      checkPayment,
      changeUserPaymentStatus,
      savePaymentResponse,
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CheckoutForm)
);
