import {
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonGrid,
  IonRow,
  IonCol,
  IonToggle,
  IonIcon,
  IonList,
  IonItem,
  IonInput,
  IonButton,
  IonImg,
  IonLabel,
  IonModal,
  IonSplitPane,
  IonSpinner,
} from "@ionic/react";
import React, { useState, useRef } from "react";
import { useParams } from "react-router";
import { close } from "ionicons/icons";
import "./Page.css";
import logoblue from "../images/logo-blue.png";
import logowhite from "../images/logo.png";
import Client from "../images/client-dummy.png";
import { changeReadStatus } from "../redux/actions/zoomAction";
import { IonRouterOutlet } from "@ionic/react";
import {
  Redirect,
  Route,
  Switch,
  withRouter,
  Link,
  useLocation,
} from "react-router-dom";
import { menuController } from "@ionic/core";
import { menuOutline, notifications, information } from "ionicons/icons";
import {
  isUser,
  changePasswordd,
  matchtoken,
} from "../redux/actions/authAction";
import { ClearData, changeMode } from "../redux/actions/commonAction";
import {
  getNotifications,
  notificationSetting,
  getUser,
} from "../redux/actions/users";
// import { getNotifications } from "../../redux/actions/users";
import timeDifference from "../Utils/timediffrence";
import moment from "moment";
import NotFound404 from "./notFound";
import { useDispatch } from "react-redux";
import {
  Dashboard,
  CalendarClass,
  SportTalks,
  Contacts,
  //Zoom,
  PRS,
  PSRS,
  MyProfile,
  CoachDashboard,
  AssistantCoachListing,
  Stripe,
  PlayerListing,
  CRS,
  Chat,
  MyForms,
  PlayLink,
  // AssistantDashboard,
  Questionnaire,
} from "../components/index";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fcmToken } from "../redux/actions/users";
import { getPlatforms } from "@ionic/react";
import {
  Plugins,
  PushNotification,
  PushNotificationToken,
  PushNotificationActionPerformed,
  KeyboardInfo,
} from "@capacitor/core";

const { PushNotifications } = Plugins;
const Page = (props) => {
  const location = useLocation();
  const wrapperRef = useRef();
  const dropdownRef = useRef();
  const { name } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [authData, setAuthData] = useState({});
  const [letChange, setChange] = useState();
  const [checked, setChecked] = useState(false);
  const [checkNoti, setCheckNoti] = useState(false);
  const [notifOpen, openNotif] = useState(false);
  const [isNotifData, setIsNotif] = useState(true);
  const [notifCount, setNotifCount] = useState(0);
  const successCallback = handleClickOutside.bind(this);
  const [page, setPage] = useState(0);
  const [showDropDown, setShowDropDown] = useState(false);
  const [showAdvSetting, setShowAdvSetting] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [notifArray, setNotifArray] = useState([]);
  const [isWeb, setWeb] = useState(false);
  const [formData, setFormValue] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const dispatch = useDispatch();
  const [errorData, setErrorData] = useState({
    oldPassword: [],
    newPassword: [],
    confirmPassword: [],
  });
  React.useEffect(() => {
    props.isUser();
    menuController.enable(true);
    dispatch({ type: "CLEAN_DATA" });
    props.getNotifications({ page });
    setChecked(JSON.parse(localStorage.getItem("darkMode")));
    document.addEventListener("mousedown", successCallback);
    let webb = getPlatforms().some(
      (r) => ["desktop", "mobileweb"].indexOf(r) >= 0
    );
    // alert(getPlatforms())
    if (!webb) {
      setWeb(false);
    } else {
      setWeb(true);
    }
    if (!webb) {
      (async () => {
        await PushNotifications.register();

        if (localStorage.getItem("fcmToken")) {
          let data = {
            user: props.authData.id,
            fcmToken: localStorage.getItem("fcmToken"),
          };
          await props.fcmToken(data);
        } else {
          await PushNotifications.addListener("registration", async (token) => {
            localStorage.setItem("fcmToken", token.value);
            let data = { user: props.authData.id, fcmToken: token.value };
            await props.fcmToken(data);
          });
        }
      })();
    }
    return function cleanup() {
      document.removeEventListener("mousedown", successCallback);
    };
  }, [props.getNotifications]);
  React.useEffect(() => {
    dispatch(matchtoken());
  }, [location]);

  React.useEffect(() => {
    if (
      props.authData.id !== authData.id ||
      props.authData.is_notification !== authData.is_notification
    ) {
      setAuthData(props.authData);
      if (props?.authData?.is_notification === 1) {
        setCheckNoti(true);
      }
    }
  }, [props.authData]);

  const jsCoreDateCreator = (dateString) => {
    // dateString HAS to be in this format "YYYY-MM-DD HH:MM:SS"
    let dateParam = dateString.split(/[\s-:]/);
    dateParam[1] = (parseInt(dateParam[1], 10) - 1).toString();
    return new Date(...dateParam);
  };

  // const onClickNoti = () => {

  //   updateNotificationSetting()
  // };

  React.useEffect(() => {
    // dispatch({ type: "CLEAN_DATA", payload: [] });
    setNotifArray(props.userNotifications);
    setIsNotif(props.isNotificationFound);
    setNotifCount(props.unreadNotificationCount);
  }, [props.userNotifications, props.isNotificationFound]);

  const menuToggle = () => {
    menuController.toggle();
  };
  const handleChange = (formField, event) => {
    let test = formData;
    test[formField] = event.target.value;
    setFormValue(test);
  };

  const updateNotificationSetting = async (e) => {
    let data = {
      user: props.authData.id,
      notification: e.detail.checked === true ? 1 : 0,
    };
    await props.notificationSetting(data);
    setCheckNoti(e.detail.checked);
    await dispatch(matchtoken());
  };

  function handleClickOutside(event) {
    if (wrapperRef && !wrapperRef.current.contains(event.target)) {
      openNotif(false);
    }
    if (dropdownRef && !dropdownRef.current.contains(event.target)) {
      let element = document.getElementById("dropdown-menu");
      if (element?.classList) {
        element.classList.remove("showdrop-menu");
        setShowDropDown(false);
        setShowAdvSetting(false);
      }
      // setShowDropDown(false)
    }
  }

  function closeMenu(event) {
    // if (dropdownRef) {
    //   let element = document.getElementById("dropdown-menu");
    //   if(element.classList){
    //     element.classList.remove("showdrop-menu");
    //   }
    // }
  }

  const checkPassword = () => {
    let user = localStorage.getItem("userData");
    user = JSON.parse(user);
    if (user.password) {
      setChange(true);
      setShowModal(true);
    } else {
      setChange(false);
      setShowModal(true);
    }
  };

  const validateForm = () => {
    let isFormValid = true;
    let testError = { oldPassword: [], newPassword: [], confirmPassword: [] };
    if (formData.oldPassword.trim().length === 0) {
      isFormValid = false;
      testError.oldPassword = ["This field is required."];
    }
    if (
      formData.oldPassword.trim().length > 0 &&
      (formData.oldPassword.trim().length < 6 ||
        formData.oldPassword.trim().length > 15)
    ) {
      isFormValid = false;
      testError.oldPassword = [
        "Old Password length must be between 6 to 15 characters",
      ];
    }
    if (formData.newPassword.trim().length === 0) {
      isFormValid = false;
      testError.newPassword = ["This field is required."];
    }
    if (
      formData.newPassword.trim().length > 0 &&
      (formData.newPassword.trim().length < 6 ||
        formData.newPassword.trim().length > 15)
    ) {
      isFormValid = false;
      testError.newPassword = [
        "New Password length must be between 6 to 15 characters",
      ];
    }
    if (formData.confirmPassword.trim().length === 0) {
      isFormValid = false;
      testError.confirmPassword = ["This field is required."];
    }
    if (
      formData.oldPassword.trim().length > 0 &&
      (formData.confirmPassword.trim().length < 6 ||
        formData.confirmPassword.trim().length > 15)
    ) {
      isFormValid = false;
      testError.confirmPassword = [
        "Confirm Password length must be between 6 to 15 characters",
      ];
    }
    if (
      formData.newPassword.trim().length > 0 &&
      formData.confirmPassword.trim().length > 0 &&
      formData.newPassword.trim() !== formData.confirmPassword.trim()
    ) {
      isFormValid = false;
      testError.confirmPassword = [
        "Confirm Password must be same as New Password",
      ];
    }

    setErrorData(testError);
    return isFormValid;
  };

  const changePassword = async (event) => {
    // event.preventDefault()
    validateForm();
    if (validateForm()) {
      await props.ClearData();
      await props.isUser();
      formData["id"] = props?.authData?.id;
      await props.changePasswordd(formData);
      setFormValue({
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
      });
      setTimeout(() => setShowModal(false), 2000);
      setTimeout(() => {
        dispatch({
          type: "CLEARDATA",
          payload: {},
        });
      }, 3000);
    }
  };

  const handleDarkMode = (e) => {
    setChecked(e.detail.checked);
    props.changeMode(e.detail.checked);
    localStorage.setItem("darkMode", e.detail.checked);
  };

  const handleLoadMore = async () => {
    let pageNo = page;
    setPage(pageNo + 1);
    await props.getNotifications({ page: pageNo + 1 });
  };

  const showNotificationBar = () => {
    openNotif(true);
  };
  const handleNotifClick = async (notif) => {
    let event = await JSON.parse(notif.event_details);
    await openNotif(false);
    if (notif?.type === "calendar event") {
      await props.changeReadStatus(notif.id);
      await dispatch({ type: "CLEAN_DATA", payload: [] });
      await props.getNotifications({ page: 0 });
      await props.history.push({
        pathname: "/page/calendar",
        state: { event, id: notif.id },
      });
    } else if (notif?.type === "Add Question") {
      if (props.checkUserRole === 2) {
        await props.history.push({
          pathname: "/page/coachReportingSystem",
          state: { event, id: notif.id },
        });
      } else if (props.checkUserRole === 3) {
        await props.history.push({
          pathname: "/page/playerReportingSystem",
          state: { event, id: notif.id },
        });
      }
    }
  };
  return (
    <IonPage>
      <IonHeader className="top-header">
        <IonGrid>
          <IonRow>
            <IonCol size="12" size-sm="4">
              <div className="main-header-logo">
                <IonImg className="logo-blue" src={logoblue} />
                <IonImg className="logo-white" src={logowhite} />
              </div>
            </IonCol>
            <IonCol size="12" size-sm="8">
              <div className="main-header-right">
                <div className="main-header-botification header-profile-notification-wrapper">
                  <div className="notilist">
                    <div className="notilist-item">
                      <div className="notifications-drop" ref={wrapperRef}>
                        {/* <a
                          // href="#"
                          onClick={(event) => {
                            showNotificationBar();
                          }}
                        >
                          <IonIcon icon={notifications} />{" "}
                          {notifCount > 0 ? <span>{notifCount}</span> : null}
                        </a> */}

                        <div
                          className={
                            notifOpen
                              ? "notifications-dropbox" + " drop-show"
                              : "notifications-dropbox"
                          }
                        >
                          <div className="notifications-dropbox-inner">
                            <div className="notifications-dropbox-top">
                              <h2>Notifications</h2>
                            </div>

                            {props?.userNotifications.length > 0 ? (
                              <div className="notifications-dropbox-mid">
                                {props?.userNotifications.map((item) => (
                                  <div
                                 
                                    className={
                                      item.read_status === 0
                                        ? "notifications-dropbox-list unread"
                                        : "notifications-dropbox-list"
                                    }
                                  >
                                    <a
                                      onClick={(event) => {
                                        handleNotifClick(item);
                                      }}
                                    >
                                      <div className="notifications-detail">
                                        <div className="notifications-detail-heading">
                                          <h5>{item.title}</h5>
                                          {/* <p>
                                            {timeDifference(
                                              new Date(),
                                              jsCoreDateCreator(
                                                moment(item.date).format(
                                                  "YYYY-MM-DD HH:MM:SS"
                                                )
                                              )
                                            )}
                                          </p> */}
                                        </div>
                                        <div className="notifications-detail-pera">
                                          <p>{item.content}</p>
                                        </div>
                                      </div>
                                    </a>
                                  </div>
                                ))}
                                {isNotifData ? (
                                  <div className="notifications-dropbox-btn">
                                    <IonButton
                                      onClick={(event) => {
                                        handleLoadMore();
                                      }}
                                    >
                                      Load more
                                    </IonButton>
                                  </div>
                                ) : (
                                  <div className="notifications-dropbox-list">
                                    <p>No more data found</p>
                                  </div>
                                )}
                              </div>
                            ) : (
                              <div className="notifications-dropbox-list">
                                <div className="nonotificationstext">
                                  <p>No Notifications Found</p>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="notilist-item"
                      onClick={() => setShowInfoModal(true)}
                    >
                      <a title="Help" className="info-help-link">
                        <IonIcon icon={information} />
                      </a>
                    </div>
                  </div>
                </div>

                <div className="main-header-user">
                  <div className="dropdown" ref={dropdownRef}>
                    <a
                      className="dropdown-toggle"
                      onClick={() => {
                        setShowDropDown(!showDropDown);
                        setShowAdvSetting(false);
                      }}
                    >
                      <div className="drop-userimg">
                        {props?.authData?.imagename ? (
                          <img src={props.authData.imagename} />
                        ) : (
                          <img src={Client} />
                        )}
                      </div>
                      <h4>{props?.authData?.fullname}</h4>
                    </a>
                    {showDropDown === true ? (
                      <div
                        className="dropdown-menu showdrop-menu"
                        id="dropdown-menu"
                      >
                        <IonList lines="none">
                          <IonItem>
                            <div className="sidebar-middiv" tabIndex="0">
                              <Link
                                to="/page/myProfile"
                                className="dropdown-item"
                                onClick={() => setShowDropDown(false)}
                              >
                                View My Profile
                              </Link>
                            </div>
                          </IonItem>
                          <IonItem>
                            <div className="sidebar-middiv" tabIndex="0">
                              <a
                                className="dropdown-item"
                                onClick={() => {
                                  checkPassword();
                                  setShowDropDown(false);
                                }}
                              >
                                Change Password
                              </a>
                            </div>
                          </IonItem>
                          {/* 
                          <IonItem className="drop-parents-menu">
                            <div
                              className="sidebar-middiv"
                              tabIndex="0"
                              onClick={() => {
                                setShowAdvSetting(!showAdvSetting);
                                setShowDropDown(true);
                              }}
                            >
                              <a className="dropdown-item">Account Settings</a>
                            </div>
                            {showAdvSetting === true ? (
                              <div className="drop-sub-menu">
                                <IonList lines="none">
                                  <IonItem>
                                    <div
                                      className="sidebar-middiv"
                                      tabIndex="0"
                                    >
                                      <a
                                        className="dropdown-item"
                                        onClick={() => {
                                          setShowDropDown(false);
                                        }}
                                      >
                                        <span className="dropdown-item-name">
                                          Notifications
                                        </span>
                                        <label className="switchbtn">
                                          <IonToggle
                                            color="tertiary"
                                            onIonChange={(e) =>
                                              updateNotificationSetting(e)
                                            }
                                            checked={checkNoti}
                                          />
                                        </label>
                                      </a>
                                    </div>
                                  </IonItem>
                                  <IonItem>
                                    <div
                                      className="sidebar-middiv"
                                      tabIndex="0"
                                    >
                                      <a className="dropdown-item">
                                        <span className="dropdown-item-name">
                                          Dark Mode
                                        </span>
                                        <label className="switchbtn">
                                          <IonToggle
                                            checked={checked}
                                            color="tertiary"
                                            onIonChange={(e) =>
                                              handleDarkMode(e)
                                            }
                                          />
                                        </label>
                                      </a>
                                    </div>
                                  </IonItem>
                                </IonList>
                              </div>
                            ) : null}
                          </IonItem>
                          */}
                        </IonList>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>

        <IonToolbar>
          <IonButtons
            slot="start"
            className="kalyug"
            onClick={(event) => {
              menuToggle();
            }}
          >
            <IonIcon icon={menuOutline} />
          </IonButtons>
        </IonToolbar>
        <IonModal
          isOpen={showModal}
          cssClass="update-password-modal"
          swipeToClose={true}
          onDidDismiss={() => setShowModal(false)}
        >
          {letChange ? (
            <div className="change-password-popup">
              <div className="popup-header">
                <h5>Update Password</h5>
                <IonButton onClick={() => setShowModal(false)}>
                  <IonIcon icon={close} />
                </IonButton>
                {props?.errorMsg ? (
                  <div className="errormsg">
                    <p>{props.errorMsg}</p>
                  </div>
                ) : props?.successMsg ? (
                  <div className="successmsg">
                    <p>{props.successMsg}</p>
                  </div>
                ) : null}
              </div>
              <form>
                <div className="form-group">
                  <IonLabel>
                    Old Password<sup>*</sup>
                  </IonLabel>
                  <div className="password-input">
                    <IonInput
                      type="password"
                      name="oldPassword"
                      onIonChange={(event) => {
                        handleChange("oldPassword", event);
                      }}
                    ></IonInput>
                    <p className="errormsg">
                      {errorData?.oldPassword && errorData.oldPassword[0]}
                    </p>
                  </div>
                </div>

                <div className="form-group">
                  <IonLabel>
                    New Password<sup>*</sup>
                  </IonLabel>
                  <div className="password-input">
                    <IonInput
                      type="password"
                      name="newPassword"
                      onIonChange={(event) => {
                        handleChange("newPassword", event);
                      }}
                    ></IonInput>
                    {errorData.newPassword[0] ? (
                      <p className="errormsg">{errorData.newPassword[0]}</p>
                    ) : null}
                  </div>
                </div>

                <div className="form-group">
                  <IonLabel>
                    Confirm Password<sup>*</sup>
                  </IonLabel>
                  <div className="password-input">
                    <IonInput
                      type="password"
                      name="confirmPassword"
                      onIonChange={(event) => {
                        handleChange("confirmPassword", event);
                      }}
                    ></IonInput>
                    {errorData.confirmPassword[0] ? (
                      <p className="errormsg">{errorData.confirmPassword[0]}</p>
                    ) : null}
                  </div>
                </div>

                <div className="form-group-center">
                  <IonButton
                    onClick={(event) => {
                      changePassword(event);
                    }}
                  >
                    Update Password
                    {(() => {
                      if (props.loading) {
                        return (
                          <IonSpinner
                            name="crescent"
                            className="spinner-loader"
                          />
                        );
                      }
                    })()}
                  </IonButton>
                </div>
              </form>
            </div>
          ) : (
            <div className="change-password-popup">
              <div className="popup-header">
                You can't change password from here as you used social login
                feature. Kindly logout and choose our Forgot password feature in
                order to reset your password!
              </div>
            </div>
          )}
        </IonModal>

        {/* info modal start */}
        <IonModal
          isOpen={showInfoModal}
          cssClass="info-modal"
          swipeToClose={true}
          onDidDismiss={() => setShowInfoModal(false)}
        >
          <div className="info-modal-inner">
            <div className="popup-header">
              <h5>Support Details</h5>
              <IonButton onClick={() => setShowInfoModal(false)}>
                <IonIcon icon={close} />
              </IonButton>
            </div>
            <div className="popup-body">
              <IonGrid>
                <IonRow>
                  <IonCol size="12" size-sm="6">
                    <div className="form-group">
                      <label>Email:</label>
                      <a href="mailto:welcome@sportaltennis.com">
                        welcome@sportaltennis.com
                      </a>
                    </div>
                  </IonCol>
                  {/*
                  <IonCol size="12" size-sm="6">
                    <div className="form-group">
                      <label>Phone Number:</label>
                      <a href="tel:407-905-4470">407-905-4470</a>
                    </div>
                  </IonCol>
                   */}
                </IonRow>
              </IonGrid>
            </div>
          </div>
        </IonModal>

        {/* info modal end */}
      </IonHeader>

      <IonContent>
        <IonRouterOutlet id="mainTwo ">
          {props.checkUserRole === 1 ? (
            props.checkIsPayment === 1 ? (
              <Switch>
                <Route
                  path="/page/coach-dashboard"
                  component={CoachDashboard}
                  exact
                />
                <Route
                  path="/page/assistant-listing"
                  component={AssistantCoachListing}
                  exact
                />
                <Route
                  path="/page/player-listing"
                  component={PlayerListing}
                  exact
                />
                <Route path="/page/calendar" component={CalendarClass} exact />
                {/* <Route path="/page/sportTalks" component={SportTalks} exact /> */}
                <Route path="/page/myDocuments" component={MyForms} exact />
                <Route path="/page/contacts" component={Contacts} exact />
                {/* <Route path="/page/zoom" component={Zoom} exact /> */}
                <Route path="/page/playSight" component={PlayLink} exact />
                <Route path="/page/myProfile" component={MyProfile} exact />
                <Route path="/page/userProfile" component={MyProfile} exact />
                <Route
                  path="/page/questionnaire"
                  component={Questionnaire}
                  exact
                />
                <Route
                  path="/page/coachReportingSystem"
                  component={CRS}
                  exact
                />
                <Route
                  path="/page/coachReportingSystem/:id"
                  component={CRS}
                  exact
                />
                <Route
                  path="/page/questionnaire"
                  component={Questionnaire}
                  exact
                />

                <Route
                  path="/page/questionnaire/:id"
                  component={Questionnaire}
                  exact
                />
                <Redirect from="/page/:id" to="/page/coach-dashboard" exact />
              </Switch>
            ) : (
              <Switch>
                <Route path="/page/payment" component={Stripe} exact />
                <Redirect from="/page/:id" to="/page/payment" exact />
              </Switch>
            )
          ) : props.checkUserRole === 2 ? (
            <Switch>
              <Route
                path="/page/assistant-dashboard"
                component={CoachDashboard}
                exact
              />
              <Route
                path="/page/player-listing"
                component={PlayerListing}
                exact
              />
              {/* <Route path="/page/sportTalks" component={SportTalks} exact /> */}
              <Route path="/page/myDocuments" component={MyForms} exact />
              <Route path="/page/calendar" component={CalendarClass} exact />
              {/* <Route path="/page/sportTalks" component={SportTalks} exact /> */}
              <Route path="/page/contacts" component={Contacts} exact />
              {/* <Route path="/page/zoom" component={Zoom} exact /> */}
              <Route path="/page/playSight" component={PlayLink} exact />
              <Route path="/page/myProfile" component={MyProfile} exact />
              <Route path="/page/coachReportingSystem" component={CRS} exact />
              <Route
                path="/page/coachReportingSystem/:id"
                component={CRS}
                exact
              />
              <Route path="/page/userProfile" component={MyProfile} exact />
              <Redirect from="/page/:id" to="/page/assistant-dashboard" exact />
            </Switch>
          ) : (
            <Switch>
              <Route path="/page/dashboard" component={Dashboard} exact />
              {/* <Route path="/page/zoom" component={Zoom} exact /> */}
              <Route path="/page/contacts" component={Contacts} exact />
              <Route path="/page/calendar" component={CalendarClass} exact />
              <Route
                path="/page/playerSubmitReportSystem"
                component={PSRS}
                exact
              />
               <Route
                path="/page/playerReportingSystem"
                component={PRS}
                exact
              />
              <Route path="/page/playSight" component={PlayLink} exact />
              {/* <Route path="/page/sportTalks" component={SportTalks} exact /> */}
              <Route
                path="/page/playerSubmitReportSystem/:id"
                component={PSRS}
                exact
              />
              <Route
                path="/page/playerReportingSystem/:id"
                component={PRS}
                exact
              />
              <Route path="/page/myDocuments" component={MyForms} exact />
              <Route path="/page/myProfile" component={MyProfile} exact />
              <Route path="/page/chat" component={Chat} exact />
              <Route path="/*" component={NotFound404} exact />
            </Switch>
          )}
        </IonRouterOutlet>
      </IonContent>
    </IonPage>
  );
};

const mapStateToProps = (state) => {
  return {
    isRegistered: state.authReducer.isRegistered,
    isLoggedIn: state.authReducer.isLoggedIn,
    userToken: state.authReducer.userToken,
    errorMsg: state.commonReducer.errorMsg,
    errorData: state.commonReducer.errorData,
    authData: state.authReducer.authData,
    successMsg: state.commonReducer.successMsg,
    darkMode: state.commonReducer.darkMode,
    userNotifications: state.userReducer.userNotifications,
    checkUserRole: state.authReducer.checkUserRole,
    checkIsPayment: state.authReducer.checkIsPayment,
    isNotificationFound: state.userReducer.isNotificationFound,
    unreadNotificationCount: state.userReducer.unreadNotificationCount,
    loading: state.commonReducer.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      isUser,
      ClearData,
      changePasswordd,
      changeMode,
      getNotifications,
      notificationSetting,
      getUser,
      changeReadStatus,
      fcmToken,
    },
    dispatch
  );
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Page));
