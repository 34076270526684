import axios from "axios";
import { handleError, handleSuccess } from "./commonAction";
const URL = process.env.REACT_APP_API_URL;

export function getQuestionsForCoach(values) {
  return async function (dispatch) {
    try {
      dispatch({ type: "LOADING_STARTED" });
      const res = await axios({
        method: "get",
        headers: {
          authorization: localStorage.getItem("user"),
        },
        url: `${URL}/api/v1/projectReportingSystem/coach?type=${values.type}&c_id=${values.c_id}`,
      });
      dispatch({
        type: "Questionnaire",
        payload: { data: res.data.data },
      });
      dispatch({
        type: "GET_SELECTED_CURRENT_QUESTION",
        payload: { data: res.data.data.selected_question_ids },
      });
      dispatch({
        type: "GET_SELECTED_MATCH_REPORT_CURRENT_QUESTION",
        payload: { data: res.data.data.selected_question_ids },
      });
      dispatch({ type: "LOADING_COMPLETED" });
    } catch (error) {
      dispatch(handleError(error));
      dispatch({ type: "LOADING_COMPLETED" });
    }
  };
}

export function addQuestionsForCoach(values) {
  return async function (dispatch) {
    try {
      dispatch({ type: "LOADING_STARTED" });
      const res = await axios({
        method: "post",
        url: `${URL}/api/v1/projectReportingSystem/coach`,
        data: { ...values },
        headers: {
          authorization: localStorage.getItem("user"),
        },
      });
      dispatch({ type: "LOADING_COMPLETED" });
    } catch (error) {
      dispatch(handleError(error));
      dispatch({ type: "LOADING_COMPLETED" });
    }
  };
}

export function checkQuestionSubmission(todaysDate, endDate, category) {
  return async function (dispatch) {
    try {
      dispatch({ type: "LOADING_STARTED" });
      const res = await axios({
        method: "get",
        url: `${URL}/api/v1/projectReportingSystem/check?todaysDate=${todaysDate}&endDate=${endDate}&category=${category}&userId=${
          JSON.parse(localStorage.getItem("userData")).id
        }`,
        headers: {
          authorization: localStorage.getItem("user"),
        },
      });

      dispatch({
        type: "Questionnaire_DaysLeft",
        payload: { results: res.data.results, data: res.data.data },
      });
      dispatch({ type: "LOADING_COMPLETED" });
    } catch (error) {
      dispatch(handleError(error));
      dispatch({ type: "LOADING_COMPLETED" });
    }
  };
}

export function getPlayerQuestions(type) {
  return async function (dispatch) {
    try {
      dispatch({ type: "LOADING_STARTED" });
      const res = await axios({
        method: "get",
        url: `${URL}/api/v1/projectReportingSystem/player?type=${type}&user_id=${
          JSON.parse(localStorage.getItem("userData")).id
        }`,
        headers: {
          authorization: localStorage.getItem("user"),
        },
      });

      dispatch({
        type: "PLAYERS_QUESTIONS",
        payload: { data: res?.data?.data?.data },
        playerAnsStatus: res?.data?.data?.isFound,
      });
      dispatch({
        type: "PLAYERS_COACH_NAME_QUESTIONS",
        payload: { data: res?.data?.data?.fullname },
      });
      dispatch({
        type: "COACH_IMAGE",
        payload: { data: res?.data?.data?.imagename },
      });
      dispatch({ type: "LOADING_COMPLETED" });
    } catch (error) {
      dispatch(handleError(error));
      dispatch({ type: "LOADING_COMPLETED" });
    }
  };
}

export function getCoachGoalsName() {
  return async function (dispatch) {
    try {
      dispatch({ type: "LOADING_STARTED" });
      const res = await axios({
        method: "get",
        url: `${URL}/api/v1/projectReportingSystem/getCoachName?user_id=${
          JSON.parse(localStorage.getItem("userData")).id
        }`,
        headers: {
          authorization: localStorage.getItem("user"),
        },
      });
      dispatch({
        type: "GOALS_PLAYERS_COACH_NAME_QUESTIONS",
        payload: { data: res?.data?.data?.fullname },
      });
      dispatch({
        type: "GOAL_COACH_IMAGE",
        payload: { data: res?.data?.data?.imagename },
      });
      dispatch({ type: "LOADING_COMPLETED" });
    } catch (error) {
      dispatch(handleError(error));
      dispatch({ type: "LOADING_COMPLETED" });
    }
  };
}

export function addPlayerAnswers(values) {
  return async function (dispatch) {
    try {
      dispatch({ type: "LOADING_STARTED" });
      const res = await axios({
        method: "post",
        url: `${URL}/api/v1/projectReportingSystem/player`,
        data: { questionsArray: values },
      });

      dispatch({
        type: "PLAYERS_ANS_STATUS",
        payload: { data: res?.data?.data?.isRegistered },
      });
      dispatch({ type: "LOADING_COMPLETED" });
    } catch (error) {
      dispatch(handleError(error));
      dispatch({ type: "LOADING_COMPLETED" });
    }
  };
}
export function checkPlayerAnswerSubmission(todaysDate, endDate, category) {
  return async function (dispatch) {
    try {
      dispatch({ type: "LOADING_STARTED" });
      const res = await axios({
        method: "get",
        url: `${URL}/api/v1/projectReportingSystem/playerCheck?todaysDate=${todaysDate}&endDate=${endDate}&category=${category}&userId=${
          JSON.parse(localStorage.getItem("userData")).id
        }`,
      });
      dispatch({
        type: "PLAYERS_ANSWERS_AFTER_SUBMIT",
        payload: {
          results: res?.data?.data?.isFound,
          data: res.data?.data?.data,
        },
      });
      dispatch({ type: "LOADING_COMPLETED" });
    } catch (error) {
      dispatch({ type: "LOADING_COMPLETED" });
      dispatch(handleError(error));
    }
  };
}

export function clearReportingSystem() {
  return async function (dispatch) {
    dispatch({
      type: "Clear_Reporting_System",
    });
  };
}

export function checkPlayerReporting(type, player_id, ans_grp_id) {
  return async function (dispatch) {
    try {
      dispatch({ type: "LOADING_STARTED" });
      const res = await axios({
        method: "get",
        url: `${URL}/api/v1/projectReportingSystem/checkPlayersReport?type=${type}&player_id=${player_id}&ans_grp_id=${ans_grp_id}`,
      });

      dispatch({
        type: "CHECK_PLAYER_REPORTS",
        payload: { data: res?.data?.data?.data },
      });
      dispatch({ type: "LOADING_COMPLETED" });
    } catch (error) {
      dispatch(handleError(error));
      dispatch({ type: "LOADING_COMPLETED" });
    }
  };
}

export function checkPlayerSubmittedReporting(type, player_id, ans_grp_id) {
  return async function (dispatch) {
    try {
      dispatch({ type: "LOADING_STARTED" });
      const res = await axios({
        method: "get",
        url: `${URL}/api/v1/projectReportingSystem/checkPlayersReport?type=${type}&player_id=${player_id}&ans_grp_id=${ans_grp_id}`,
      });

      dispatch({
        type: "CHECK_PLAYER_SUBMITTED_REPORTS",
        payload: { data: res?.data?.data?.data },
      });
      dispatch({ type: "LOADING_COMPLETED" });
    } catch (error) {
      dispatch(handleError(error));
      dispatch({ type: "LOADING_COMPLETED" });
    }
  };
}

export function getPlayerCurrentGoals() {
  return async function (dispatch) {
    try {
      dispatch({ type: "LOADING_STARTED" });
      const res = await axios({
        method: "get",
        url: `${URL}/api/v1/projectReportingSystem/getDashboardGoals?user_id=${
          JSON.parse(localStorage.getItem("userData")).id
        }`,
      });

      dispatch({
        type: "GET_PLAYERS_CURRENT_GOALS",
        payload: { data: res?.data?.data?.data },
      });
      dispatch({ type: "LOADING_COMPLETED" });
    } catch (error) {
      dispatch(handleError(error));
      dispatch({ type: "LOADING_COMPLETED" });
    }
  };
}

export function getAllWeeklyUsers(type, page = 1, selectedInvoice) {
  return async function (dispatch) {
    try {
      dispatch({ type: "LOADING_STARTED" });
      const res = await axios({
        method: "get",
        url: `${URL}/api/v1/projectReportingSystem/getAllUsers?per_page=${selectedInvoice}&page_no=${page}&role= ${
          JSON.parse(localStorage.getItem("userData")).role
        } &type=${type}&userId=${
          JSON.parse(localStorage.getItem("userData")).id
        }`,
      });

      dispatch({
        type: "GET_ALL_WEEKLY_REPORT",
        payload: { data: res?.data?.data?.data },
      });
      dispatch({
        type: "GET_LAST_PAGE",
        payload: { data: res?.data },
      });
      dispatch({
        type: "GET_PAGE_NO",
        payload: { data: res?.data },
      });
      dispatch({
        type: "GET_TOTAL_RECORD",
        payload: { data: res?.data },
      });
      dispatch({
        type: "GET_PER_RECORD",
        payload: { data: res?.data },
      });
      dispatch({ type: "LOADING_COMPLETED" });
    } catch (error) {
      dispatch({ type: "LOADING_FAILURE" });
      dispatch(handleError(error));
    }
  };
}

export function getPlayerSubmittedUsers(page = 1, selectedInvoice) {
  return async function (dispatch) {
    try {
      dispatch({ type: "LOADING_STARTED" });
      const res = await axios({
        method: "get",
        url: `${URL}/api/v1/projectReportingSystem/getPlayersAnswers?per_page=${selectedInvoice}&page_no=${page}&user_id=${
          JSON.parse(localStorage.getItem("userData")).id
        }`,
      });

      dispatch({
        type: "GET_ALL_PLAYER_SUBMITTED_REPORT",
        payload: { data: res?.data?.data?.data },
      });
      dispatch({
        type: "GET_ALL_PLAYER_SUBMITTED_REPORT_LAST_PAGE",
        payload: { data: res?.data },
      });
      dispatch({ type: "LOADING_COMPLETED" });
    } catch (error) {
      dispatch({ type: "LOADING_FAILURE" });
      dispatch(handleError(error));
    }
  };
}

// export function getAllMatchUsers(type, page = 1, selectedInvoice) {
//   return async function (dispatch) {
//     try {
//       dispatch({ type: "LOADING_STARTED" });
//       const res = await axios({
//         method: "get",
//         url: `${URL}/api/v1/projectReportingSystem/getAllUsers?per_page=${selectedInvoice}&page_no=${page}&type=${type}&userId=${
//           JSON.parse(localStorage.getItem("userData")).id
//         }`,
//       });

//       dispatch({
//         type: "GET_ALL_MONTHLY_REPORT",
//         payload: { data: res?.data?.data?.data },
//       });
//       dispatch({
//         type: "GET_MATCH_LAST_PAGE",
//         payload: { data: res?.data },
//       });

//       dispatch({ type: "LOADING_COMPLETED" });
//     } catch (error) {
//       dispatch({ type: "LOADING_FAILURE" });
//       dispatch(handleError(error));
//     }
//   };
// }

export function cleanUpData(todaysDate, category, type) {
  return async function (dispatch) {
    dispatch({
      type: "CHECK_PLAYER_REPORTS",
      payload: { data: [] },
    });
  };
}

export function getQuestionById(id) {
  return async function (dispatch) {
    try {
      dispatch({ type: "LOADING_STARTED" });
      const res = await axios({
        method: "get",
        url: `${URL}/api/v1/projectReportingSystem/getquestionbyid/${id}`,
      });
      dispatch({
        type: "SET_NOTIFICATION",
        payload: { data: res.data.data.data },
      });
      dispatch({ type: "LOADING_COMPLETED" });
    } catch (error) {
      dispatch({ type: "LOADING_FAILURE" });
      dispatch(handleError(error));
    }
  };
}
