import React, { useState, useEffect, useRef } from "react";
import {
  IonIcon,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonImg,
  IonModal,
  IonButton,
  useIonViewWillEnter,
  HTMLIonInfiniteScrollElement,
} from "@ionic/react";

import { useHistory } from "react-router";
import DashCalander from "../../images/dash-calendar.svg";
import AddUserModel from "../addUserModel/addUerModel";
import { connect } from "react-redux";
import {
  addUserAction,
  getUserDetails,
  deleteUser,
  deleteUserDetails,
  cleanUpUserDetails,
} from "../../redux/actions/users";

import { bindActionCreators } from "redux";
import { IonInfiniteScroll, IonInfiniteScrollContent } from "@ionic/react";
import {
  eyeOutline,
  trashOutline,
  eyeOffOutline,
  close,
  contractOutline,
} from "ionicons/icons";
import "./assistantCoach.css";

const AssistantListing = (props) => {
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const [showVerifyModel, setShowVerifyModel] = useState(false);
  const [showVerifyDeleteModel, setShowVerifyDeleteModel] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [userId, setUserId] = useState({});
  const [disableInfiniteScroll, setDisableInfiniteScroll] = useState(false);
  const limit = 15;
  const [limitOffset, setLimitOffset] = useState({
    limit,
    offset: 0,
    role: 2,
  });

  const ionInfiniteScrollAssisRef = useRef();

  useEffect(() => {
    return () => {
      props.cleanUpUserDetails();
    };
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  // useIonViewWillEnter(async () => {
  //   await fetchData();
  // },[]);
  async function fetchData() {
    props.getUserDetails({
      limit: limit,
      offset: limitOffset.offset,
      role: 2,
      userRole: JSON.parse(localStorage.getItem("userData")).role,
    });
    setLimitOffset({
      limit,
      offset: limitOffset.offset + limit,
      role: 2,
    });

    //setDisableInfiniteScroll(false);
    if (props?.userListing?.length > 0) {
      if (props.userListing.length >= limit) {
        // setDisableInfiniteScroll(false);
        ionInfiniteScrollAssisRef.current.disabled = false;
      } else {
        // setDisableInfiniteScroll(true);
        ionInfiniteScrollAssisRef.current.disabled = false;
      }
    } else {
      // setDisableInfiniteScroll(false);
      ionInfiniteScrollAssisRef.current.disabled = true;
    }
  }

  const loadMoreItems = async () => {
    await fetchData();
    ionInfiniteScrollAssisRef.current.disabled = true;
  };

  return (
    <IonContent>
      <div className="user-inner-body">
        <div className="userdash-head">
          <IonGrid>
            <IonRow>
              <IonCol size="12" size-md="6">
                <div className="dash-heading">
                  {/* <h2>Assistant Coach Listing</h2> */}
                  {/* <p>Player Listing</p> */}
                </div>
              </IonCol>
              <IonCol size="12" size-md="6">
                <div className="dash-adduser">
                  <a onClick={() => setShowModal(true)}>Add Assistant Coach</a>
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
          {successMessage === true ? (
            <div className="success-msg">
              <p>User Added Successfully</p>
            </div>
          ) : null}
        </div>

        <IonCard className="user-dashboard-card">
          <IonCardHeader className="user-card-head">
            <h3>Assistant Coaches</h3>
          </IonCardHeader>
          <IonCardContent className="dashboard-card-body">
            <div className="dash-table">
              <div className="dashtable-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <td>
                        <p>Name</p>
                      </td>
                      <td>
                        <p>Email</p>
                      </td>
                      <td>
                        <p>Role</p>
                      </td>
                      <td>
                        <p>Status</p>
                      </td>
                      <td>
                        <p className="text-center">Action</p>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {props?.userListing?.length > 0 ? (
                      props.userListing?.map((data, i) => {
                        return (
                          <tr>
                            <td>
                              <p>{data.fullname}</p>
                            </td>
                            <td>
                              <p>{data.email}</p>
                            </td>
                            <td>
                              <p>{data.role === 2 ? "Assistant" : null}</p>
                            </td>
                            <td>
                              <p>
                                {data.is_login === 1 ? "Registered" : "Pending"}
                              </p>
                            </td>
                            <td>
                              <div className="actionitem">
                                {/* {data.is_login === 1 ? (
                                <a title="View Profile">
                                  <IonIcon
                                    icon={eyeOutline}
                                    onClick={() => {
                                      history.push({
                                        pathname: "/page/userProfile",
                                        state: { id: data.user_id },
                                      });
                                    }}
                                  />
                                </a>
                              ) : (
                                <a 
                                title="User haven't logged in yet!"
                                >
                                  <IonIcon icon={eyeOffOutline} />
                                </a>
                              )} */}

                                {data.is_login === 1 ? (
                                  <a
                                    onClick={() => {
                                      history.push({
                                        pathname: "/page/userProfile",
                                        state: { id: data.user_id },
                                      });
                                    }}
                                    className="gradient-btn"
                                  >
                                    View
                                  </a>
                                ) : null}
                                {/* <a href="#" className="gradient-btn">Edit </a> */}
                                {data.is_active === 1 ? (
                                  <a
                                    onClick={() => {
                                      setShowVerifyModel(true);
                                      setUserId({
                                        id: data.user_id,
                                        setValue: 0,
                                      });
                                    }}
                                    className="gradient-btn"
                                  >
                                    Disable
                                  </a>
                                ) : (
                                  <a
                                    onClick={() => {
                                      setShowVerifyModel(true);
                                      setUserId({
                                        id: data.user_id,
                                        setValue: 1,
                                      });
                                    }}
                                    className="gradient-btn"
                                  >
                                    Enable
                                  </a>
                                )}
                                {data.is_active === 0 ? (
                                  <a
                                    title="Delete User"
                                    className="gradient-btn"
                                    onClick={() => {
                                      setShowVerifyDeleteModel(true);
                                      setUserId(data.user_id);
                                    }}
                                  >
                                    Delete
                                  </a>
                                ) : (
                                  ""
                                )}
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    ) : props.loading === true ? null : (
                      <p style={{ padding: 10, width: "100vw" }}>
                        No Assistant Coach Added.
                      </p>
                    )}
                    {props.loading === true ? (
                      <p style={{ padding: 10 }}>Loading...</p>
                    ) : null}
                    <IonInfiniteScroll
                      ref={ionInfiniteScrollAssisRef}
                      threshold="10%"
                      position="bottom"
                      disabled={false}
                      onIonInfinite={loadMoreItems}
                    >
                      <IonInfiniteScrollContent
                        loadingSpinner="bubbles"
                        loadingText="Loading data..."
                      ></IonInfiniteScrollContent>
                    </IonInfiniteScroll>
                  </tbody>
                </table>
              </div>
            </div>
          </IonCardContent>
        </IonCard>
      </div>

      <AddUserModel
        setShowModal={setShowModal}
        showModal={showModal}
        setSuccessMessage={setSuccessMessage}
        cleanUpUser={props.cleanUpUserDetails}
      ></AddUserModel>

      <IonModal
        isOpen={showVerifyModel}
        // cssClass="my-custom-class"
        cssClass="deleteuser-popup"
        swipeToClose={true}
        onDidDismiss={() => setShowVerifyModel(false)}
      >
        <div className="deleteuser-popup">
          <div className="popup-header">
            <h5>Are you sure?</h5>
            <IonButton onClick={() => setShowVerifyModel(false)}>
              <IonIcon icon={close} />
            </IonButton>
            <div className="errormsg">
              <p>{props?.errorMsg}</p>
            </div>
          </div>
          <div className="popup-body">
            <div className="popup-action-btn">
              <IonButton
                color="none"
                onClick={async () => {
                  await props.deleteUser(userId);
                  setLimitOffset({ limit, offset: 0, role: 2 });
                  setShowVerifyModel(false);
                  props.cleanUpUserDetails();
                  props.getUserDetails({
                    limit,
                    offset: 0,
                    role: 2,
                    userRole: JSON.parse(localStorage.getItem("userData")).role,
                  });
                }}
              >
                Yes
              </IonButton>
              <IonButton color="none" onClick={() => setShowVerifyModel(false)}>
                Cancel
              </IonButton>
            </div>
          </div>
        </div>
      </IonModal>

      <IonModal
        isOpen={showVerifyDeleteModel}
        // cssClass="my-custom-class"
        cssClass="deleteuser-popup"
        swipeToClose={true}
        onDidDismiss={() => setShowVerifyDeleteModel(false)}
      >
        <div className="deleteuser-popup">
          <div className="popup-header">
            <h5>Are you sure?</h5>
            <IonButton onClick={() => setShowVerifyDeleteModel(false)}>
              <IonIcon icon={close} />
            </IonButton>
            <div className="errormsg">
              <p>{props?.errorMsg}</p>
            </div>
          </div>
          <div className="popup-body">
            <div className="popup-action-btn">
              <IonButton
                color="none"
                onClick={async () => {
                  await props.deleteUserDetails(userId);
                  setLimitOffset({ limit, offset: 0, role: 2 });
                  setShowVerifyDeleteModel(false);
                  props.cleanUpUserDetails();
                  props.getUserDetails({
                    limit,
                    offset: 0,
                    role: 2,
                    userRole: JSON.parse(localStorage.getItem("userData")).role,
                  });
                }}
              >
                Yes
              </IonButton>
              <IonButton
                color="none"
                onClick={() => setShowVerifyDeleteModel(false)}
              >
                Cancel
              </IonButton>
            </div>
          </div>
        </div>
      </IonModal>
    </IonContent>
  );
};

const mapStateToProps = (state) => {
  return {
    addUser: state.userReducer.addUser,
    errorMsg: state.commonReducer.errorMsg,
    successMsg: state.commonReducer.successMsg,
    userListing: state.userReducer.userListing,
    authData: state.authReducer.authData,
    loading: state.commonReducer.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      addUserAction,
      getUserDetails,
      deleteUser,
      deleteUserDetails,
      cleanUpUserDetails,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AssistantListing);
