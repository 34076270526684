import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import {
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonList,
  IonItem,
  // IonCheckbox,
  IonSpinner,
} from "@ionic/react";
import { useDispatch } from "react-redux";
import {
  getQuestionsForCoach,
  addQuestionsForCoach,
} from "../../redux/actions/prsAction";
import { connect } from "react-redux";

const WeeklyReport = (props) => {
  const dispatch = useDispatch();
  const [selected, setSelected] = useState([]);
  const [errorMessage, setErrorMessage] = useState(false);
  // const [checkValue, setCheckValue] = useState(false);

  React.useEffect(() => {
    componentDidMount();
  }, []);

  React.useEffect(() => {
    dispatch({
      type: "Clear_Reporting_System",
      matchReportSelectedID: [],
    });
  }, []);

  // useEffect(() => {
  //   if (
  //     props?.Questionnaire?.data &&
  //     props.Questionnaire.data.length === selected.length
  //   ) {
  //     const checkboxId = "checkId";
  //     const isSelected = document.getElementById(checkboxId);
  //     isSelected.checked = true;
  //     setCheckValue(true);
  //   }
  //   // if (props.selectedCoachQuestion?.length > 0) {
  //   //   const checkboxId = "checkId";
  //   //   const isSelected = document.getElementById(checkboxId);
  //   //   isSelected.checked = true;
  //   //   setCheckValue(true);
  //   // }
  // }, [props?.Questionnaire?.data]);

  React.useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    // props.selectedCoachQuestion?.filter((e) => {
    //   const isSelected = document.getElementById(e);
    //   isSelected.checked = true;
    // });
    setSelected([...props.selectedCoachQuestion]);
  }, [props.selectedCoachQuestion]);

  // useEffect(() => {
  //   console.log(selected, "selected=1234567890");

  //   if (selected?.length < 0) {
  //     // eslint-disable-next-line no-unused-expressions
  //     props.selectedCoachQuestion?.filter((e) => {
  //       const isSelected = document.getElementById(e);
  //       isSelected.checked = false;
  //     });
  //     setSelected([]);
  //   }
  // }, [selected]);

  async function componentDidMount() {
    const userId = JSON.parse(localStorage.getItem("userData")).id;
    await dispatch(
      await getQuestionsForCoach({
        type: "weekly_checkin",
        c_id: userId,
      })
    );
  }

  const OnClickSubmit = async () => {
    const data = {
      question_ids: selected,
      type: "weekly_checkin",
      c_id: JSON.parse(localStorage.getItem("userData")).id,
    };
    if (data?.question_ids.length < 1) {
      setErrorMessage(true);
    } else {
      setErrorMessage(false);
      await dispatch(await addQuestionsForCoach(data));
      await dispatch(
        await getQuestionsForCoach({
          type: "weekly_checkin",
          c_id: JSON.parse(localStorage.getItem("userData")).id,
        })
      );
      // setSelected([]);
    }
  };

  
  // const handleChangeAllQuestion = (event) => {
  //   console.log("ssss-1");
  //   // setInit(false);
  //   // console.log("first")
  //   console.log("alll",props.Questionnaire);
  //   if ( event.target.checked) {
  //     console.log('fill')

  //     const finalQ = props?.Questionnaire?.data?.map((data) => {
  //       const isSelected = document.getElementById(data.id);
  //       isSelected.checked = true;
  //       return data.id;
  //     });
  //     setSelected(finalQ);
  //   } else {
  //     console.log('clear')
  //     // eslint-disable-next-line no-unused-expressions
  //     props?.Questionnaire?.data?.map((data) => {
  //       const isSelected = document.getElementById(data.id);
  //       isSelected.checked = false;
  //     });

  //     console.log("ssss-2");
  //     setSelected([]);
  //   }
  // };

  const handleChangeAllQuestion = (event) => {
    if (event.target.checked) {
      const finalQ = props?.Questionnaire?.data.map((aItem) => aItem.id);
      setSelected(finalQ);
      return;
    } else {
      // console.log('clear')
      // // eslint-disable-next-line no-unused-expressions
      // props?.Questionnaire?.data?.map((data) => {
      //   const isSelected = document.getElementById(data.id);
      //   isSelected.checked = false;
      // });

      // console.log("ssss-2");
      setSelected([]);
    }
  };

  const handleChange = async (event) => {
    const target = event.target;
    var value = Number(target.value);
    // if (
    //   props?.Questionnaire?.data &&
    //   props.Questionnaire.data.length === [...selected, value].length
    // ) {
    //   const checkboxId = "horns";
    //   const isSelected = document.getElementById(checkboxId);
    //   isSelected.checked = true;

    //   setCheckValue(true);
    // }

    if (target.checked) {
      setSelected((oldArray) => [...oldArray, value]);
    } else {
      let filteredArray = await selected.filter((val) => val !== value);
      await setSelected(filteredArray);
    }
  };

  return (
    <div className="reporting-mid-tab">
      <IonGrid>
        <IonRow>
          <IonCol size="12" size-md="12">
            <div className="reporting-mid-right">
              <div className="tab-content">
                <div className="tab-pane active" id="wellness">
                  <div className="reporting-tabbox">
                    <div className="reporting-tabbox-mid goals-report-box-wrapper">
                      <div className="question-main-wrapper">
                        {/* <IonCheckbox
                          color="tertiary"
                          slot="start"
                          // value={checkValue}
                          // checked
                          id="checkId"
                          onIonChange={(e) => {
                            handleChangeAllQuestion(e);
                          }}
                        /> */}
                        <label class="input-label all-question-input-label">
                          All Questions
                          <input
                            type="checkbox"
                            id="horns"
                            name="horns"
                            checked={
                              props?.Questionnaire?.data.length ===
                              selected.length
                            }
                            onChange={(e) => handleChangeAllQuestion(e)}
                          />
                          <span class="checkmark"></span>
                        </label>
                        {/* <p>All Questions</p> */}
                      </div>
                      <div className="add-questionairre">
                        <IonList lines="none">
                          <div>
                            {props?.Questionnaire?.data.length > 0 ? (
                              props?.Questionnaire?.data?.map((data, index) => {
                                return (
                                  <div key={index}>
                                    <IonItem key={index}>
                                      {/* <IonCheckbox
                                        color="tertiary"
                                        slot="start"
                                        value={data.id}
                                        id={data.id}
                                        onIonChange={(e) => {
                                          handleChange(
                                            // console.log('data.id', data.id),
                                            e,
                                            data.id
                                            // data.question,
                                            // data.category
                                          );
                                        }}
                                      /> */}
                                      <label class="input-label">
                                        {data.question}
                                        <input
                                          type="checkbox"
                                          value={data.id}
                                          id={data.id}
                                          name="helloby"
                                          checked={selected.includes(data.id)}
                                          onChange={(e) => handleChange(e)}
                                        />
                                        <span class="checkmark"></span>
                                        {/* <IonLabel>{data.question}</IonLabel> */}
                                      </label>
                                    </IonItem>
                                  </div>
                                );
                              })
                            ) : (
                              <div className="questionnaire-detal">
                                <div className="questionnaire-detal-heading">
                                  {props?.loading === true ? (
                                    <div className="loading-pera">
                                      <p>Loading...</p>
                                    </div>
                                  ) : (
                                    <h5>No Question Added.</h5>
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                          {errorMessage === true ? (
                            <div className="errorMessage">
                              <p>Please select at least one question</p>
                            </div>
                          ) : null}
                          <div className="questionairre-submit-btn">
                            <a onClick={OnClickSubmit}>
                              Submit{" "}
                              {(() => {
                                if (props.loading) {
                                  return (
                                    <IonSpinner
                                      name="crescent"
                                      className="spinner-loader"
                                    />
                                  );
                                }
                              })()}
                            </a>
                          </div>
                        </IonList>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    Questionnaire: state.prsReducer.Questionnaire,
    isQuestionnaire: state.prsReducer.isQuestionnaire,
    selectedCoachQuestion: state.prsReducer.selectedCoachQuestion,
    coachQuestions: state.prsReducer.coachQuestions,
    loading: state.commonReducer.loading,
  };
};

export default withRouter(connect(mapStateToProps)(WeeklyReport));
