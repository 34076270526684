import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import { Route, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ReactPaginate from "react-paginate";
import {
  IonCardContent,
  IonIcon,
  IonCard,
} from "@ionic/react";
import { Link } from "react-router-dom";
import { eyeOutline } from "ionicons/icons";
import {
  clearReportingSystem,
  getPlayerSubmittedUsers,
  checkPlayerSubmittedReporting,
} from "../../redux/actions/prsAction";
import "./playerSubmittedReportingSystem.css";
import PlayerSubmittedviewReportModel from "./playerSubmittedviewReportModel";

const PSRS = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [totalPage, setTotalPage] = useState();
  const [selectedRows, setSelectedRows] = useState(5);

  const handleSelectChange = (event) => {
    setSelectedRows(event.target.value);
  };

  useEffect(() => {
    setTotalPage(props?.getSubmittedReportPage);
  }, [props?.getSubmittedReportPage]);

  const rowNumbers = [5, 10, 15, 20, 25];

  async function handlePageClick(e) {
    await props.getPlayerSubmittedUsers(e.selected + 1, selectedRows);
  }

  async function onClickView(type, player_id, ans_grp_id) {
    const goalsType = "goals";
    setShowModal(true);

    await props.checkPlayerSubmittedReporting(
      type ? type : goalsType,
      player_id,
      ans_grp_id
    );
  }

  useEffect(() => {
    props.clearReportingSystem();
    componentDidMount();
    return function cleanup() {
      props.clearReportingSystem();
    };
  }, [selectedRows]);

  async function componentDidMount() {
    await props.getPlayerSubmittedUsers( 1, selectedRows);
  }

  return (
    <div className="player-submit-report-table-main-page-wrapper">
      <div>
        <div className="submit-report-header-wrapper">
          <div className="reporting-heading">
            <h3>Submitted Report</h3>
          </div>
          <div className="submit-report-btn-div">
            <Link to="/page/playerReportingSystem">Submit New Report</Link>
          </div>
        </div>
      </div>
      <div className="user-inner-body submit-report-table-div">
        <IonCard className="user-dashboard-card dashboard-table-wrapper">
          <IonCardContent className="dashboard-card-body table-content-body">
            <div className="dash-table">
              <div className="dashtable-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th>
                        <p>Question Type</p>
                      </th>
                      <th>
                        <p>Date</p>
                      </th>
                      <th>
                        <p className="text-center">Action</p>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {props?.playersSubmittedReport?.length > 0 ? (
                      props.playersSubmittedReport?.map((data, i) => {
                        return (
                          <tr>
                            <td>
                              <p>
                                {data.type === "weekly_checkin"
                                  ? "Check In"
                                  : data.type === "match_report"
                                  ? "Match Report"
                                  : "Goals"}
                              </p>
                            </td>
                            <td>
                              <p className="date-text">
                                
                                {moment(data.created_ts).format(
                                  "MM-DD-YYYY hh:mm A"
                                )}
                              </p>
                            </td>
                            <td>
                              <p className="actionitem">
                                <a
                                  onClick={() =>
                                    onClickView(
                                      data.type,
                                      data.player_id,
                                      data.ans_grp_id
                                    )
                                  }
                                >
                                  <IonIcon icon={eyeOutline} />
                                </a>
                              </p>
                            </td>
                          </tr>
                        );
                      })
                    ) : props.loading === true ? null : (
                      <p style={{ padding: 10 }}>No submitted report found.</p>
                    )}
                    {props.loading === true ? (
                      <p style={{ padding: 10 }}>Loading...</p>
                    ) : null}
                  </tbody>
                </table>
                {props?.playersSubmittedReport?.length > 0 && (
                  <div className="sportal-pagination-main-div">
                    <div className="sportal-pagination-wrapper">
                      <ReactPaginate
                        previousLabel={"<"}
                        nextLabel={">"}
                        pageCount={totalPage}
                        onPageChange={handlePageClick}
                        marginPagesDisplayed={0}
                        pageRangeDisplayed={5}
                        breakLabel="..."
                        containerClassName={"pagination"}
                        previousLinkClassName={"pagination__link"}
                        nextLinkClassName={"pagination__link"}
                        disabledClassName={"pagination__link--disabled"}
                        activeClassName={"pagination__link--active"}
                      />
                    </div>
                    <div className="sportal-rows-page-div">
                      <label htmlFor="invoiceDropdown">Rows:</label>
                      <select
                        id="invoiceDropdown"
                        onChange={handleSelectChange}
                        value={selectedRows}
                      >
                        <option value="" disabled>
                          Rows
                        </option>
                        {rowNumbers.map((rowsNumbers) => (
                          <option key={rowsNumbers} value={rowsNumbers}>
                            {rowsNumbers}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </IonCardContent>
        </IonCard>
        <PlayerSubmittedviewReportModel
          showModal={showModal}
          setShowModal={setShowModal}
        ></PlayerSubmittedviewReportModel>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.commonReducer.loading,
    playersSubmittedReport: state.prsReducer.playersSubmittedReport,
    getSubmittedReportPage: state.prsReducer.getSubmittedReportPage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getPlayerSubmittedUsers,
      clearReportingSystem,
      checkPlayerSubmittedReporting,
    },
    dispatch
  );
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PSRS));
