import {
  IonContent,
  IonInput,
  IonButton,
  IonLabel,
  IonImg,
  IonSpinner,
  IonIcon,
} from "@ionic/react";
import { IonItem, IonCheckbox } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { eyeOffOutline, eyeOutline } from "ionicons/icons";
import { menuController } from "@ionic/core";
import loginlogo from "../images/logo-blue.png";
import "./login.css";
import { withRouter, Link } from "react-router-dom";
import { ClearData } from "../redux/actions/commonAction";
import { connect, useDispatch } from "react-redux";
import {
  loginAction,
  isUser,
  googleLogin,
  getTeamDetail,
} from "../redux/actions/authAction";
import { fcmToken } from "../redux/actions/users";
import GoogleLogin from "./googleLogin";
import FacebookLogin from "./facebookLogin";
import { getPlatforms } from "@ionic/react";
import { bindActionCreators } from "redux";
import PrivatePolicy from "./privatePolicy";
import CryptoJS from "react-native-crypto-js";
import TNC from "./termsAndConditions";
import {
  Plugins,
  PushNotification,
  PushNotificationToken,
  PushNotificationActionPerformed,
  KeyboardInfo,
} from "@capacitor/core";
import OtpVerification from "../components/addOtpModel/otpVerification";

const { PushNotifications } = Plugins;
const Login = (props) => {
  const dispatch = useDispatch();
  const [formData, setFormValue] = useState({ email: "", password: "" });
  const [errorData, setErrorData] = useState({ email: [], password: [] });
  const [isShowPassword, setPasswordType] = useState(false);
  const [isWeb, setWeb] = useState(false);
  const [privatePolicy, setPrivatePolicy] = useState(false);
  const [tnc, setTnc] = useState(false);
  const [checked, setChecked] = useState();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch({
        type: "SIGNUP",
        payload: "",
      });
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  React.useEffect(() => {
    (async () => {
      props.ClearData();
      menuController.enable(false);

      let webb = getPlatforms().some(
        (r) => ["desktop", "mobileweb"].indexOf(r) >= 0
      );
      // alert(getPlatforms())
      if (!webb) {
        setWeb(false);
      } else {
        setWeb(true);
      }

      let rememberme = await localStorage.getItem("rememberme");
      if (rememberme) {
        setChecked(true);
        rememberme = CryptoJS.AES.decrypt(rememberme, "remembermesecret$#");
        rememberme = rememberme.toString(CryptoJS.enc.Utf8);
        rememberme = JSON.parse(rememberme);
        // this.state.formValues = rememberme;
        setFormValue({
          email: rememberme.email,
          password: rememberme.password,
        });

        // setUserData(rememberme);
      } else {
        setChecked(false);
      }
    })();
  }, []);

  const handleChange = (formField, event) => {
    let test = formData;
    test[formField] = event.target.value;
    setFormValue(test);
  };

  const validateForm = () => {
    let isFormValid = true;
    let testError = { email: [], password: [] };
    if (formData.email.trim().length === 0) {
      isFormValid = false;
      testError.email = ["This field is required."];
    }
    if (
      formData.email.trim().length > 0 &&
      /^([a-zA-Z0-9_\.\-]+)@([a-zA-Z0-9_\.\-]+)\.([a-zA-Z]{2,5})$/.test(
        formData.email
      ) === false
    ) {
      isFormValid = false;
      testError.email = ["Email must be a valid email address."];
    }
    if (formData.password.trim().length === 0) {
      isFormValid = false;
      testError.password = ["This field is required."];
    }
    if (
      formData.password.trim().length > 0 &&
      (formData.password.trim().length < 6 ||
        formData.password.trim().length > 15)
    ) {
      isFormValid = false;
      testError.password = [
        "Password length must be between 6 to 15 characters.",
      ];
    }
    setErrorData(testError);
    return isFormValid;
  };

  async function onSubmitForm(e) {
    if (validateForm()) {
      formData["timeZone"] = Intl.DateTimeFormat().resolvedOptions().timeZone;
      await props.loginAction(formData);

      if (checked) {
        const rememberme = CryptoJS.AES.encrypt(
          JSON.stringify(formData),
          "remembermesecret$#"
        ).toString();
        localStorage.setItem("rememberme", rememberme);
      } else {
        localStorage.removeItem("rememberme");
      }

      if (props.isLoggedIn) {
        await props.getTeamDetail(props.authData.id);
        props.ClearData();
        if (localStorage.getItem("fcmToken")) {
          let data = {
            user: props.authData.id,
            fcmToken: localStorage.getItem("fcmToken"),
          };

          await props.fcmToken(data);
        } else {
          if (!isWeb) {
            await PushNotifications.register();

            // On succcess, we should be able to receive notifications
            await PushNotifications.addListener(
              "registration",
              async (token) => {
                localStorage.setItem("fcmToken", token.value);
                let data = { user: props.authData.id, fcmToken: token.value };
                await props.fcmToken(data);
              }
            );
          }
        }
        // if(props.userFcmToken){
        //   let data= {user: props.authData.id, fcmToken:props.userFcmToken}
        //   props.fcmToken(data)
        // }
        if (!isWeb) {
          PushNotifications.register();

          // On succcess, we should be able to receive notifications
          PushNotifications.addListener("registration", (token) => {
            localStorage.setItem("fcmToken", token.value);
            let data = {
              user: props.authData.id,
              fcmToken: localStorage.getItem("fcmToken"),
            };
            props.fcmToken(data);
          });
        }
      } else {
        let data = props.errorData;
        await setErrorData(data);
      }
    }
  }


  return (
    <IonContent>
      <div className="login">
        <div className="login-inner">
          <div className="login-logo">
            <IonImg src={loginlogo} />
          </div>

          {props?.errorMsg ? (
            <div className="errormsg">
              <p>{props?.errorMsg}</p>
            </div>
          ) : props?.isRegistered ? (
            <div className="successmsg">
              <p>{props.isRegistered}</p>
            </div>
          ) : null}

          <div className="login-head">
            <h2>Log In</h2>
          </div>
          <div className="login-body">
            <form>
              <div className="form-group">
                <IonLabel>Email Address</IonLabel>
                <IonInput
                  // type="text"
                  type="text"
                  value={formData.email}
                  name="email"
                  className="form-control"
                  onIonChange={(event) => {
                    handleChange("email", event);
                  }}
                ></IonInput>
                <p> {errorData?.email && errorData.email[0]}</p>
              </div>
              <div className="form-group">
                <IonLabel>Password</IonLabel>
                <div className="showpassword-input">
                  <IonInput
                    type={isShowPassword == true ? "text" : "password"}
                    name="password"
                    value={formData.password}
                    onIonChange={(event) => {
                      handleChange("password", event);
                    }}
                    className="form-control"
                  ></IonInput>
                  {isShowPassword === true ? (
                    <a onClick={() => setPasswordType(false)}>
                      <IonIcon icon={eyeOutline} />
                    </a>
                  ) : (
                    <a onClick={() => setPasswordType(true)}>
                      <IonIcon icon={eyeOffOutline} />
                    </a>
                  )}
                </div>

                <p> {errorData?.password && errorData.password[0]}</p>
              </div>
              <div className="check-forgot">
                <div className="custom-checkbox-inline">
                  <IonCheckbox
                    className="custom-checkbox"
                    checked={checked}
                    onIonChange={(e) => setChecked(e.detail.checked)}
                  />
                  <IonLabel>Remember Me</IonLabel>
                </div>

                <div className="forgot-password">
                  {/* <Link to={"/signup"}>Don't have Account? SignUp</Link> */}
                  {/* <a className="forgot-text">Forgot Password?</a> */}
                  <Link to="/forgotPassword" className="forgot-text">
                    Forgot Password?
                  </Link>
                </div>
              </div>
              <div className="button-login">
                <IonButton
                  className="gradient-btn-blue"
                  color="none"
                  onClick={(event) => {
                    onSubmitForm(event);
                  }}
                >
                  Log In
                  {(() => {
                    if (props.loading) {
                      return (
                        <IonSpinner
                          name="crescent"
                          className="spinner-loader"
                        />
                      );
                    }
                  })()}
                </IonButton>
              </div>

              {/*<div className="button-login">
                {isWeb === true ? (
                  <div className="login-social-heading">
                    <p>Or Log In with</p>
                  </div>
                ) : null}

                <div className="login-social-btn">
                  {isWeb === true ? <GoogleLogin></GoogleLogin> : null}
                  {isWeb === true ? <FacebookLogin></FacebookLogin> : null}
                </div>
              </div> */}
              <div className="haveaccount">
                <p>
                  Don't have an account? <Link to={"/signup"}>Sign Up</Link>
                </p>
              </div>
              <div className="private-policy">
                <p>
                  By logging in, you agree to our<br></br>{" "}
                  <a
                    href="#"
                    onClick={() => {
                      setPrivatePolicy(true);
                    }}
                  >
                    Privacy Policy
                  </a>{" "}
                  and{" "}
                  <a
                    href="#"
                    onClick={() => {
                      setTnc(true);
                    }}
                  >
                    Terms & Conditions
                  </a>
                  .
                </p>
              </div>
              <OtpVerification
                name="login-otp"
                formData={formData}

                // setShowModal={setShowModal}
                // showModal={showModal}
                // setSuccessMessage={setSuccessMessage}
                // cleanUpUser={props.cleanUpUserDetails}
              />
            </form>

            <PrivatePolicy
              showPrivatePolicy={privatePolicy}
              setPrivatePolicy={() => {
                setPrivatePolicy(false);
              }}
            />
            <TNC
              showPrivatePolicy={tnc}
              setPrivatePolicy={() => {
                setTnc(false);
              }}
            />
          </div>
        </div>
      </div>
    </IonContent>
  );
};

const mapStateToProps = (state) => {
  return {
    isRegistered: state.authReducer.isRegistered,
    isLoggedIn: state.authReducer.isLoggedIn,
    userToken: state.authReducer.userToken,
    errorMsg: state.commonReducer.errorMsg,
    errorData: state.commonReducer.errorData,
    authData: state.authReducer.authData,
    successMsg: state.commonReducer.successMsg,
    loading: state.commonReducer.loading,
    userFcmToken: state.authReducer.userFcmToken,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { loginAction, isUser, ClearData, googleLogin, fcmToken, getTeamDetail },
    dispatch
  );
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
