import React, { useEffect, useState } from "react";
import "./privatePolicy.css";
import { getPageContent } from "../redux/actions/pageAction";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

const TermsConditions = (props) => {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState(null);
  const getData = async () => {
    const result = await props.getPageContent("terms-and-conditions");
    if (result?.data?.data) {
      setTitle(result.data.data.title);
      setContent(result.data.data.content);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div className="popup-header">
        <h5
          dangerouslySetInnerHTML={{
            __html: title ? title : `Loading...`,
          }}
        ></h5>
        {props.showPrivatePolicy}
      </div>
      <div
        className="private-policy-detail"
        dangerouslySetInnerHTML={{
          __html: content ? content : ` `,
        }}
      ></div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getPageContent }, dispatch);
};

export default connect(null, mapDispatchToProps)(TermsConditions);
