import {
  IonContent,
  IonInput,
  IonButton,
  IonLabel,
  IonImg,
  IonSpinner,
  IonIcon
} from "@ionic/react";
import React, { useState, useEffect } from "react";
import { menuController } from "@ionic/core";
import loginlogo from "../images/logo-blue.png";
import "./changePassword.css";
import { connect } from "react-redux";
import { changePasswordAction } from "../redux/actions/authAction";
import { handleError, ClearData } from "../redux/actions/commonAction";
import { eyeOffOutline, eyeOutline } from "ionicons/icons";
import { bindActionCreators } from "redux";
const queryString = require("query-string");

const ChangePassword = (props) => {
  const [isShowPassword, setPasswordType] = useState(false);
  const [isShowPassword1, setPasswordType1] = useState(false);
  const [resetKey, setResetKey] = useState(null);
  const [formData, setFormValue] = useState({
    newPassword: "",
    confirmPassword: "",
    resetKey: "",
  });
  const [errorData, setErrorData] = useState({
    newPassword: [],
    confirmPassword: [],
  });
  useEffect(() => {
    props.ClearData();
    menuController.enable(false);
    if (props?.location?.search) {
      setResetKey(queryString.parse(props.location.search));
    }
  }, []);
  const handleChange = (formField, event) => {
    let test = formData;
    test[formField] = event.target.value;
    setFormValue(test);
  };

  const validateForm = () => {
    let isFormValid = true;
    let testError = { newPassword: [], confirmPassword: [] };
    if (formData.newPassword.trim().length === 0) {
      isFormValid = false;
      testError.newPassword = ["New Password cannot be empty!"];
    }
    if (
      formData.newPassword.trim().length > 0 &&
      (formData.newPassword.trim().length < 6 ||
        formData.newPassword.trim().length > 15)
    ) {
      isFormValid = false;
      testError.newPassword = [
        "New Password length must be between 6 to 15 characters",
      ];
    }
    if (formData.confirmPassword.trim().length === 0) {
      isFormValid = false;
      testError.confirmPassword = ["Confirm Password cannot be empty!"];
    }
    if (
      formData.confirmPassword.trim().length > 0 &&
      (formData.confirmPassword.trim().length < 6 ||
        formData.confirmPassword.trim().length > 15)
    ) {
      isFormValid = false;
      testError.confirmPassword = [
        "Confirm Password length must be between 6 to 15 characters",
      ];
    }
    if (
      formData.newPassword.trim().length > 0 &&
      formData.confirmPassword.trim().length > 0 &&
      formData.newPassword.trim() !== formData.confirmPassword.trim()
    ) {
      isFormValid = false;
      testError.confirmPassword = [
        "Confirm Password must be same as New Password",
      ];
    }

    setErrorData(testError);
    return isFormValid;
  };
  async function onSubmitForm(event) {
    if (validateForm()) {
      formData.resetKey = resetKey.accessKey;
      props.handleError({ message: "" });
      const resp = await props.changePasswordAction(formData);
      if (resp?.data?.success) {
        props.history.push("/login");
      }
    }
  }
  return (
    <IonContent>
      <div className="change-password">
        <div className="login-inner">
          <div className="login-logo">
            <IonImg src={loginlogo} />
          </div>
          <div className="login-head">
            <h2>Change Password</h2>
            {props?.errorMsg ? (
              <div className="errormsg">
                <p>{props.errorMsg}</p>
              </div>
            ) : props?.successMsg ? (
              <div className="successmsg">
                <p>{props.successMsg}</p>
              </div>
            ) : null}
          </div>
          <div className="login-body">
            <form>
              <div className="form-group">
                <IonLabel>
                  New Password<sup>*</sup>
                </IonLabel>
                <div className="showpassword-input">
                  <IonInput
                    type={isShowPassword == true ? "text" : "password"}
                    name="newPassword"
                    className="form-control"
                    value={formData.newPassword}
                    style={{ color: "black" }}
                    onIonChange={(event) => {
                      handleChange("newPassword", event);
                    }}
                  ></IonInput>
                  {isShowPassword === true ? (
                    <a onClick={() => setPasswordType(false)}>
                      <IonIcon icon={eyeOutline} />
                    </a>
                  ) : (
                    <a onClick={() => setPasswordType(true)}>
                      <IonIcon icon={eyeOffOutline} />
                    </a>
                  )}
                </div>

                {errorData.newPassword[0] ? (
                  <p>{errorData.newPassword[0]}</p>
                ) : null}
              </div>
              <div className="form-group">
                <IonLabel>
                  Confirm Password<sup>*</sup>
                </IonLabel>
                <div className="showpassword-input">
                  <IonInput
                    type={isShowPassword1 == true ? "text" : "password"}
                    name="confirmNewPassword"
                    className="form-control"
                    value={formData.confirmPassword}
                    style={{ color: "black" }}
                    onIonChange={(event) => {
                      handleChange("confirmPassword", event);
                    }}
                  ></IonInput>
                  {isShowPassword1 === true ? (
                    <a onClick={() => setPasswordType1(false)}>
                      <IonIcon icon={eyeOutline} />
                    </a>
                  ) : (
                    <a onClick={() => setPasswordType1(true)}>
                      <IonIcon icon={eyeOffOutline} />
                    </a>
                  )}
                </div>
                {errorData.confirmPassword[0] ? (
                  <p>{errorData.confirmPassword[0]}</p>
                ) : null}
              </div>
              <div className="button-login">
                <IonButton
                  className="gradient-btn-blue"
                  color="none"
                  onClick={(event) => {
                    onSubmitForm(event);
                  }}
                >
                  Save
                  {(() => {
                    if (props.loading) {
                      return (
                        <IonSpinner
                          name="crescent"
                          className="spinner-loader"
                        />
                      );
                    }
                  })()}
                </IonButton>
              </div>
            </form>
          </div>
        </div>
      </div>
    </IonContent>
  );
};

const mapStateToProps = (state) => {
  return {
    errorMsg: state.commonReducer.errorMsg,
    successMsg: state.commonReducer.successMsg,
    loading:state.commonReducer.loading
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { changePasswordAction, handleError, ClearData },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
