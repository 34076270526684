import React, { useEffect } from "react";
import {
  IonTabs,
  IonTabBar,
  IonTabButton,
  IonLabel,
  IonRouterOutlet,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
} from "@ionic/react";
import { useDispatch } from "react-redux";
import { Route, withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getQuestionById } from "../../redux/actions/prsAction";
import { changeReadStatus } from "../../redux/actions/zoomAction";
import { getNotifications } from "../../redux/actions/users";
import "./playerReportingSystem.css";
import WeeklyReport from "./weeklyReport";
import MatchingReport from "./matchReporting";
import goalsReport from "./goalsReport";

const CRS = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (props.location?.state?.event?.insertId) {
      notif();
    }
  }, []);

  const notif = async () => {
    await props.changeReadStatus(props?.location?.state?.id);
    await dispatch({ type: "CLEAN_DATA", payload: [] });
    await props.getNotifications({ page: 0 });
  };
  return (
    <IonContent>
      <div className="reporting-inner-body coach-report-body-wrapper">
       
        <div className="coach-inner-body-top">
          <div className="coach-report-header-div">
              <div className="reporting-heading">
                <h2>Player Reporting System</h2>
              </div>
              {props?.checkUserRole === 1 ? (
                <div className="reporting-questionnaire">
                  {/* <a href="#">Questionnaire</a> */}
                  <Link to="/page/questionnaire" className="edit-question-link">Edit Questions</Link>
                </div>
              ) : null}
            </div>
        </div>

        <IonTabs className="coach-tabs-display-div">
          <IonTabBar slot="top">
            <IonTabButton tab="weekly" href="/page/coachReportingSystem/weekly">
              <div className="player-top-tab">
                <div className="tablabel">
                  <IonLabel>Check-In</IonLabel>
                </div>
              </div>
            </IonTabButton>

            <IonTabButton
              tab="GoalsReport"
              href="/page/coachReportingSystem/GoalsReport"
            >
              <div className="player-top-tab">
                <div className="tablabel">
                  <IonLabel>Goals</IonLabel>
                </div>
              </div>
            </IonTabButton>

            <IonTabButton tab="match" href="/page/coachReportingSystem/match">
              <div className="player-top-tab">
                <div className="tablabel">
                  <IonLabel>Match report</IonLabel>
                </div>
              </div>
            </IonTabButton>
          </IonTabBar>

          <IonRouterOutlet id="main three">
            <Route
              path="/page/coachReportingSystem/:tab(weekly)"
              component={WeeklyReport}
              exact={true}
            />
            <Route
              path="/page/coachReportingSystem"
              component={WeeklyReport}
              exact={true}
            />
            <Route
              path="/page/coachReportingSystem/:tab(GoalsReport)"
              component={goalsReport}
              exact={true}
            />
            <Route
              path="/page/coachReportingSystem/:tab(match)"
              component={MatchingReport}
            />
          </IonRouterOutlet>
        </IonTabs>
      </div>
    </IonContent>
  );
};

const mapStateToProps = (state) => {
  return {
    notificationData: state.prsReducer.notificationData,
    checkUserRole: state.authReducer.checkUserRole,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { getQuestionById, changeReadStatus, getNotifications },
    dispatch
  );
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CRS));
