import { IonContent, IonGrid, IonRow, IonCol } from "@ionic/react";
import React, { useEffect, useState } from "react";
import AssistantCoachList from "./assistantCoachList/assistantCoachList";
import PlayerList from "./playerList/playerList";
import CoachDashboardList from "./coachDashboardList/coachDashboardList";
import "./coachDashboard.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { isUser } from "../../redux/actions/authAction";
import { ClearData } from "../../redux/actions/commonAction";
import AddUserModel from "../addUserModel/addUerModel";
import Weather from "./weatherCard/weatherCard"
import {
  cleanUpUserDetails
} from "../../redux/actions/users";

const CoachDashboard = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  
  const [user, setUser] = React.useState({});
  
  useEffect(() => {
    props.ClearData();
  }, []);
 
  return (
    <IonContent>
      <div className="coachdash-inner-body">
        <div className="coachdash-head">
          <IonGrid>
            <IonRow>
              <IonCol size="12" size-sm="6">
                <div className="dash-heading">
                  <h2>Welcome, {props?.authData?.fullname}!</h2>
                  {/* <p>Coach Dashboard</p> */}
                </div>
              </IonCol>
              <IonCol size="12" size-sm="6">
                <div className="dash-adduser">
                  <a onClick={() => setShowModal(true)}> Add Team Member</a>
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
          {successMessage === true ? (
            <div className="success-msg">
              <p>User Added Successfully</p>
            </div>
          ) : null}
        </div>
        <IonGrid>
          <IonRow>
            <IonCol size="12" size-md="9">
              <CoachDashboardList></CoachDashboardList>
            </IonCol>
            <IonCol size="12" size-md="3">
              <Weather latitude= {props?.authData?.latitude} longitude={props?.authData?.longitude}></Weather>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="12" size-md="6">
              <PlayerList></PlayerList>
            </IonCol>
            {props.checkUserRole === 1 ? <IonCol size="12" size-md="6">
              <AssistantCoachList ></AssistantCoachList>
            </IonCol> : null}
            
          </IonRow>
          {/* <IonRow>
            <IonCol size="12" size-md="12">
              <Weather></Weather>
            </IonCol>
          </IonRow> */}
        </IonGrid>
      </div>
      <AddUserModel
        setShowModal={setShowModal}
        showModal={showModal}
        setSuccessMessage={setSuccessMessage}
        cleanUpUser={props.cleanUpUserDetails}
      ></AddUserModel>
    </IonContent>
  );
};

const mapStateToProps = (state) => {
  return {
    authData: state.authReducer.authData,
    checkUserRole: state.authReducer.checkUserRole,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ isUser, ClearData ,cleanUpUserDetails}, dispatch);
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CoachDashboard)
);
