import axios from "axios";
import { handleError, handleSuccess } from "./commonAction";

const URL = process.env.REACT_APP_API_URL;

export function updatePlayLink(values) {
  return async function (dispatch) {
    try {
      dispatch({ type: "LOADING_STARTED" });
      const res = await axios({
        method: "post",
        url: `${URL}/api/v1/zoom/playLink`,
        headers: {
          authorization: localStorage.getItem("user"),
        },
        data: values,
      });
      dispatch({ type: "LOADING_COMPLETED" });
      dispatch({
        type: "UPDATE_PLAY_LINK",
        payload: { data: res.data },
      });
      return {
        success: res.data.success,
        message: res.data.message,
      };
    } catch (error) {
      dispatch({ type: "LOADING_COMPLETED" });
      dispatch(handleError(error));
    }
  };
}
export function getPlayLink(id) {
  return async function (dispatch) {
    try {
      const res = await axios({
        method: "get",
        url: `${URL}/api/v1/zoom/playLink/${id}`,
        headers: {
          authorization: localStorage.getItem("user"),
        },
      });
      // dispatch(handleSuccess({ message: res.data.success }));
      dispatch({
        type: "GET_PLAY_LINK",
        payload: { data: res.data },
      });
    } catch (error) {
      dispatch(handleError(error));
    }
  };
}
export function updateZoomLink(values) {
  return async function (dispatch) {
    try {
      dispatch({ type: "LOADING_STARTED" });
      const res = await axios({
        method: "post",
        url: `${URL}/api/v1/zoom`,
        headers: {
          authorization: localStorage.getItem("user"),
        },
        data: values,
      });
      dispatch({ type: "LOADING_COMPLETED" });
      dispatch({
        type: "UPDATE_ZOOM",
        payload: { data: res.data },
      });
      return {
        success: res.data.success,
        message: res.data.message,
      };
    } catch (error) {
      dispatch({ type: "LOADING_COMPLETED" });
      dispatch(handleError(error));
    }
  };
}

export function getZoomLink(id) {
  return async function (dispatch) {
    try {
      const res = await axios({
        method: "get",
        url: `${URL}/api/v1/zoom/${id}`,
        headers: {
          authorization: localStorage.getItem("user"),
        },
      });
      // dispatch(handleSuccess({ message: res.data.success }));
      dispatch({
        type: "GET_ZOOM_LINK",
        payload: { data: res.data },
      });
    } catch (error) {
      dispatch(handleError(error));
    }
  };
}

export function getZoomLinkForAdmin(id) {
  return async function (dispatch) {
    try {
      const res = await axios({
        method: "get",
        url: `${URL}/api/v1/zoom/admin/${id}`,
        headers: {
          authorization: localStorage.getItem("user"),
        },
      });
      // dispatch(handleSuccess({ message: res.data.success }));
      dispatch({
        type: "GET_ZOOM_LINK",
        payload: { data: res.data },
      });
    } catch (error) {
      dispatch(handleError(error));
    }
  };
}

export function addEvent(data) {
  return async function (dispatch) {
    try {
      dispatch({ type: "LOADING_STARTED" });
      const res = await axios({
        method: "post",
        url: `${URL}/api/v1/zoom/addEvent`,
        data: data,
        headers: {
          authorization: localStorage.getItem("user"),
        },
      });
      // dispatch(handleSuccess({ message: res.data.success }));
      dispatch({
        type: "ADDEVENT",
        payload: { data: res.data },
      });
      dispatch({ type: "LOADING_COMPLETED" });
      return res;
    } catch (error) {
      dispatch({ type: "LOADING_FAILURE" });
      dispatch(handleError(error));
    }
  };
}

export function getEventList(date) {
  return async function (dispatch) {
    try {
      dispatch({ type: "LOADING_STARTED" });
      let userData = localStorage.getItem("userData");

      let userDataParse = JSON.parse(userData);
      const res = await axios({
        method: "post",
        url: `${URL}/api/v1/zoom/getCoachEvent/${userDataParse.id}/${userDataParse.role}`,
        data: { date },
        headers: {
          authorization: localStorage.getItem("user"),
        },
      });
      dispatch({
        type: "GETEVENT",
        payload: { data: res.data.data.data },
      });
      dispatch({ type: "LOADING_COMPLETED" });
      return res;
    } catch (error) {
      dispatch({ type: "LOADING_FAILURE" });
      dispatch(handleError(error));
    }
  };
}

export function deleteEvent(data) {
  return async function (dispatch) {
    try {
      dispatch({ type: "LOADING_STARTED" });
      const res = await axios({
        method: "get",
        url: `${URL}/api/v1/zoom/deleteEvent/${data}`,
        data: data,
        headers: {
          authorization: localStorage.getItem("user"),
        },
      });
      if (res.data.data.isRegistered) {
        dispatch(handleSuccess({ message: "Event Deleted Successfully!" }));
      } else {
        dispatch(handleError({ message: res.data.message }));
      }

      dispatch({ type: "LOADING_COMPLETED" });
      return res;
    } catch (error) {
      dispatch({ type: "LOADING_FAILURE" });
      dispatch(handleError(error));
    }
  };
}

export function editEvent(id, data) {
  return async function (dispatch) {
    try {
      dispatch({ type: "LOADING_STARTED" });
      const res = await axios({
        method: "put",
        url: `${URL}/api/v1/zoom/editEvent/${id}`,
        data: data,
        headers: {
          authorization: localStorage.getItem("user"),
        },
      });

      if (res.data.data.isRegistered) {
        dispatch(handleSuccess({ message: "Event Updated Successfully!" }));
      } else {
        dispatch(handleError({ message: res.data.message }));
      }
      dispatch({ type: "LOADING_COMPLETED" });
      return res;
    } catch (error) {
      dispatch({ type: "LOADING_FAILURE" });
      dispatch(handleError(error));
    }
  };
}

export function getUpcomingEvents() {
  return async function (dispatch) {
    try {
      dispatch({ type: "LOADING_STARTED" });
      let userData = localStorage.getItem("userData");

      let userDataParse = JSON.parse(userData);
      const res = await axios({
        method: "get",
        url: `${URL}/api/v1/zoom/getUpcomingEvents/${userDataParse.id}/${userDataParse.role}`,
        headers: {
          authorization: localStorage.getItem("user"),
        },
      });
      dispatch({
        type: "GET_UPCOMING_EVENTS",
        payload: { data: res.data.data.data },
      });
      dispatch({ type: "LOADING_COMPLETED" });
      return res;
    } catch (error) {
      dispatch({ type: "LOADING_FAILURE" });
      dispatch(handleError(error));
    }
  };
}

export function changeReadStatus(id) {
  return async function (dispatch) {
    try {
      dispatch({ type: "LOADING_STARTED" });
      const res = await axios({
        method: "get",
        url: `${URL}/api/v1/zoom/updateReadStatus/${id}`,
        headers: {
          authorization: localStorage.getItem("user"),
        },
      });
      dispatch({ type: "LOADING_COMPLETED" });
      return res;
    } catch (error) {
      dispatch({ type: "LOADING_FAILURE" });
      dispatch(handleError(error));
    }
  };
}
