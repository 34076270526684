import {
  IonIcon,
  IonInput,
  IonButton,
  IonLabel,
  IonModal,
  IonSelect,
  IonSpinner,
  IonSelectOption,
} from "@ionic/react";
import React, { useEffect } from "react";

import { useForm } from "react-hook-form";

import { connect } from "react-redux";
import {
  addUserAction,
  getUserDetails,
  getAssistantListing,
  getPlayerListing,
} from "../../redux/actions/users";
import { ClearData } from "../../redux/actions/commonAction";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { close } from "ionicons/icons";
import "./addUserModel.css";

const User = (props) => {
  const { register, handleSubmit, errors } = useForm();
  const [selectedOption, selectOption] = React.useState();
  const options = {
    cssClass: "custom-interface-popover",
  };
  useEffect(() => {
    let currentLocation = props?.location?.pathname;
    if (currentLocation) {
      if (currentLocation === "/page/assistant-listing") {
        selectOption(2);
      } else if (currentLocation === "/page/player-listing") {
        selectOption(3);
      }
    }
  }, []);

  const getOption = () => {
    let currentLocation = props?.location?.pathname;
    if (currentLocation) {
      if (currentLocation === "/page/assistant-listing") {
        selectOption(2);
      } else if (currentLocation === "/page/player-listing") {
        selectOption(3);
      }
    }
  };

  async function onSubmitForm(formData) {
    const userData = JSON.parse(localStorage.getItem("userData"));
    props.ClearData();
    formData.coach_id = userData.id;
    formData.userRole = userData.role;
    formData.team_name = userData.team_name;
    formData.team_logo = userData.team_logo;
    await props.addUserAction(formData).then(async (data) => {
      if (data?.data && data?.message) {
        props.setShowModal(false);
        props.cleanUpUser();
        if (props.location.pathname === "/page/assistant-listing") {
          await props.getUserDetails({
            limit: 15,
            offset: 0,
            role: 2,
            userRole: userData.role,
          });
        } else if (props.location.pathname === "/page/player-listing") {
          await props.getUserDetails({
            limit: 15,
            offset: 0,
            role: 3,
            userRole: userData.role,
          });
        } else if (
          props.location.pathname === "/page/coach-dashboard" ||
          props.location.pathname === "/page/assistant-dashboard"
        ) {
          props.getAssistantListing({
            limit: 5,
            offset: 0,
            role: 2,
            userRole: 1,
          });
          props.getPlayerListing({ limit: 5, offset: 0, role: 3, userRole: 1 });
        }
        props.setSuccessMessage(true);
        setTimeout(() => {
          props.setSuccessMessage(false);
        }, 5000);
      }
    });
  }

  return (
    <IonModal
      isOpen={props.showModal}
      cssClass="addnew-user-popup add-team-member-modal-wrapper"
      swipeToClose={true}
      backdropDismiss={false}
      onDidDismiss={() => {
        props.ClearData();
        props.setShowModal(false);
      }}
      onWillPresent={() => {
        getOption();
        props.ClearData();
      }}
    >
      <div className="adduser-popup">
        <div className="popup-header">
          {props?.location?.pathname === "/page/assistant-listing" ? (
            <h5>Add Assistant Coach </h5>
          ) : props?.location?.pathname === "/page/player-listing" ? (
            <h5>Add Athlete </h5>
          ) : (
            <h5>Add Team Member</h5>
          )}

          {/* <h5>Add Team Member</h5> */}
          <div className="errormsg">
            <p>{props?.errorMsg}</p>
          </div>
          <IonButton onClick={() => props.setShowModal(false)}>
            <IonIcon icon={close} />
          </IonButton>
        </div>
        <form onSubmit={handleSubmit(onSubmitForm)}>
          <div className="form-group">
            <IonLabel>
              Email Address<sup>*</sup>
            </IonLabel>
            <IonInput
              text="email"
              name="email"
              ref={register({
                required: {
                  value: true,
                  message: "This field is required.",
                },
                pattern: {
                  value:
                    /^([a-zA-Z0-9_\.\-]+)@([a-zA-Z0-9_\.\-]+)\.([a-zA-Z]{2,5})$/,
                  message: "Please enter a valid email",
                },
              })}
            ></IonInput>
            {errors && errors.email ? (
              <p className="errormsg">{errors && errors.email.message}</p>
            ) : null}
          </div>
          <div className="form-group">
            <IonLabel>
              First Name<sup>*</sup>
            </IonLabel>
            <IonInput
              text="text"
              name="firstname"
              ref={register({
                required: {
                  value: true,
                  message: "This field is required.",
                },
              })}
            ></IonInput>
            {errors && errors.firstname ? (
              <p className="errormsg">{errors && errors.firstname.message}</p>
            ) : null}
          </div>
          <div className="form-group">
            <IonLabel>
              Last Name<sup>*</sup>
            </IonLabel>
            <IonInput
              type="text"
              name="lastname"
              ref={register({
                required: {
                  value: true,
                  message: "This field is required.",
                },
              })}
            ></IonInput>
            {errors && errors.lastname ? (
              <p className="errormsg">{errors && errors.lastname.message}</p>
            ) : null}
          </div>

          <div className="form-group">
            <IonLabel>
              Role<sup>*</sup>
            </IonLabel>
            <IonSelect
              interface="popover"
              interfaceOptions={options}
              placeholder="Select One"
              name="role"
              value={selectedOption}
              onIonChange={(e) => selectOption(e.detail.value)}
              ref={register({
                required: {
                  value: true,
                  message: "This field is required.",
                },
              })}
            >
              {props?.location?.pathname === "/page/assistant-listing" ? (
                <IonSelectOption value={2}>Assistant Coach</IonSelectOption>
              ) : props?.location?.pathname === "/page/player-listing" ? (
                <IonSelectOption value={3}>Athlete</IonSelectOption>
              ) : props.checkUserRole === 2 ? (
                <>
                  <IonSelectOption value={3}>Athlete</IonSelectOption>
                </>
              ) : (
                <>
                  <IonSelectOption value={3}>Athlete</IonSelectOption>
                  <IonSelectOption value={2}>Assistant Coach</IonSelectOption>
                </>
              )}
            </IonSelect>
            {errors && errors.role ? (
              <p className="errormsg">{errors && errors.role.message}</p>
            ) : null}
          </div>

          <div className="form-group-center">
            <IonButton type="submit" value="submit">
              Invite to Your Team!
              {(() => {
                if (props.loading) {
                  return (
                    <IonSpinner name="crescent" className="spinner-loader" />
                  );
                }
              })()}
            </IonButton>
          </div>
        </form>
      </div>
    </IonModal>
  );
};

const mapStateToProps = (state) => {
  return {
    addUser: state.userReducer.addUser,
    errorMsg: state.commonReducer.errorMsg,
    successMsg: state.commonReducer.successMsg,
    loading: state.commonReducer.loading,
    checkUserRole: state.authReducer.checkUserRole,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      addUserAction,
      getUserDetails,
      ClearData,
      getAssistantListing,
      getPlayerListing,
    },
    dispatch
  );
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(User));
