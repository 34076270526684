import React, { useState, useEffect } from "react";
import {
  IonSpinner,
  IonContent,
  IonHeader,
  IonCardTitle,
  IonSlides,
  IonSlide,
  IonIcon,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonList,
  IonItem,
  IonInput,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonImg,
  IonItemGroup,
  IonItemDivider,
  IonLabel,
  IonItemSliding,
  IonItemOptions,
  IonItemOption,
  
} from "@ionic/react";
import {
  eyeOutline,
  trashOutline,
  documentTextOutline,
  image,
  downloadOutline,
} from "ionicons/icons";

import { Capacitor, Plugins, FilesystemDirectory } from "@capacitor/core";
import { FileTransfer, FileUploadOptions, FileTransferObject } from '@ionic-native/file-transfer';
import { File } from '@ionic-native/file';
import { BUCKET_NAME } from "../../Constants/constants";
import { uploadFile } from "../../Utils/fileUpload";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import { getPlatforms } from '@ionic/react';
import {
  addUserDocument,
  getUserDocument,
  deleteUserDocument,
} from "../../redux/actions/users";
import { useDispatch } from "react-redux";
import { FileOpener } from '@ionic-native/file-opener';
const { Filesystem } = Plugins;
const MyForms = (props) => {
  const [loader, showloader] = useState(false);
  const [isPlatformWeb, setPlatform] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    getUserDocument();
  let isPlatformWeb =   getPlatforms().some(r=> ["desktop","mobileweb"].indexOf(r) >= 0)
  setPlatform(isPlatformWeb)
  }, []);

  useEffect(() => {
    setTimeout(() => {
      dispatch(
        dispatch({
          type: "CLEARDATA",
        })
      );
    }, 3000);
  }, [props.errorMsg, props.successMsg]);

  const getUserDocument = async () => {
    await props.getUserDocument();
  };
  const fileSelectHandler = async (e) => {
    if (props.userDocuments.length < 10) {
      let file = e.target.files[0];
      let name = file.name;
      let extension = file.type;
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = async () => {
        if (extension === "application/pdf") {
          showloader(true);
          const imagePath = await uploadFile(
            file,
            BUCKET_NAME,
            `${"nayana"}/user-team-logo`
          );
          let body = { path: imagePath, name, user_id: props.authData.id };
          await props.addUserDocument(body);
          await getUserDocument();
          // let test = formData;
          // test["teamLogo"] = imagePath;
          // setFormValue(test);
          // setLogo(imagePath);
          showloader(false);
        } else {
          dispatch(
            dispatch({
              type: "ERROR",
              payload: { errorMsg: "File type must be pdf" },
            })
          );
        }
      };
    } else {
      dispatch(
        dispatch({
          type: "ERROR",
          payload: { errorMsg: "You cannot upload more than 10 documents" },
        })
      );
    }
  };

  const download = (path, name) => {
    axios({
      url: path, //your url
      method: "GET",
      responseType: "arraybuffer", // important
    }).then(async (response) => {
      let webb= getPlatforms().some(r=> ["desktop","mobileweb"].indexOf(r) >= 0)
      if (!webb) {
        let newData = Buffer.from(response.data, "binary").toString("base64");

        const fileTransfer = FileTransfer.create();
        fileTransfer.download(path, File.dataDirectory + name).then((entry) => {
          FileOpener.showOpenWithDialog(entry.toURL(), 'application/pdf')
          .then(() => console.log('File is opened'))
          .catch(e => console.log('Error opening file', e));
        }, (error) => {
          // handle error
        });
      }else{
        const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", name); //or any other extension
      document.body.appendChild(link);
      link.click();
      }
      
      
    });
  };

  const deleteFile = async (id) => {
    await props.deleteUserDocument(id);
    await getUserDocument();
  };

  return (
    <IonContent>
      <div className="profiletable-form">
        <div className="profiletable-form-header">
          <IonGrid>
            <IonRow>
              <IonCol size="12" size-sm="6">
                <div className="profiletable-form-heading">
                  <h2>My Documents</h2>
                </div>
              </IonCol>
              <IonCol size="12" size-sm="6">
                {props?.errorMsg ? (
                  <div className="profile-error-msg">
                    <p>{props.errorMsg}</p>
                  </div>
                ) : props?.successMsg ? (
                  <div className="profile-success-msg">
                    <p>{props.successMsg}</p>
                  </div>
                ) : null}
                <div className="uploadnew-form-btn">
                  <a href="">
                    <IonIcon icon={documentTextOutline} /> Upload New
                    {(() => {
                      if (loader === true) {
                        return (
                          <IonSpinner
                            name="crescent"
                            className="spinner-loader"
                          />
                        );
                      }
                    })()}
                  </a>
                  <input
                    name="file1"
                    type="file"
                    accept="application/pdf"
                    onChange={(e) => {
                      fileSelectHandler(e);
                    }}
                  ></input>
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>

        <div className="profiletable-form-mid">
          <div className="table-reponsive">
            <table className="table">
              <thead>
                <tr>
                  <td>
                    <p>FILE NAME</p>
                  </td>
                  <td align="center">
                    <p>ACTIONS</p>
                  </td>
                </tr>
              </thead>

              {props.userDocuments.length > 0 ? (
                <tbody>
                  {props.userDocuments.map((item, key) => { 
                   return (
                    
                    //   <IonRow key={key} >
                    //   <IonCol size="7" size-md="9" onClick={(e)=>download(item.path,item.name)}><p>{item?.name}</p></IonCol>
                    //   <IonCol size="5" size-md="3">
                    //   {/* <IonIcon icon={eyeOutline} /> */}
                    //   <IonIcon icon={trashOutline} onClick={(e)=>{deleteFile(item.id)}} />
                    //   </IonCol>
                    // </IonRow>

                    <tr>
                      <td>
                        <p>{item?.name}</p>
                      </td>
                      <td align="center">
                        <a onClick={(e) => download(item.path, item.name)}>
                          <IonIcon icon={downloadOutline} />
                        </a>
                        <a
                          onClick={(e) => {
                            deleteFile(item.id);
                          }}
                        >
                          <IonIcon icon={trashOutline} />
                        </a>
                        {isPlatformWeb ? (
                          <a
                            onClick={() => {
                              window.open(item.path);
                            }}
                          >
                            <IonIcon icon={eyeOutline} />
                          </a>
                        ) : null}

                        {/* <p>{item?.name}</p> */}
                      </td>
                      {/* <td>
                        
                      </td> */}
                    </tr>
                  )})}
                </tbody>
              ) : (
                <div>No Documents added yet</div>
              )}
              {/* <tr>
                <td><p>kdfjlshjkl sdfkljsdf sdlf sflsd</p></td>
                <td align="center">
                  <a href="#"><IonIcon icon={downloadOutline} /></a>
                  <a href="#"><IonIcon icon={trashOutline} /></a>
                </td>
              </tr>
              <tr>
                <td><p>kdfjlshjkl sdfkljsdf sdlf sflsd</p></td>
                <td align="center">
                  <a href="#"><IonIcon icon={downloadOutline} /></a>
                  <a href="#"><IonIcon icon={trashOutline} /></a>
                </td>
              </tr> */}
            </table>
          </div>
        </div>
      </div>
    </IonContent>
  );
};

const mapStateToProps = (state) => {
  return {
    errorMsg: state.commonReducer.errorMsg,
    errorData: state.commonReducer.errorData,
    successMsg: state.commonReducer.successMsg,
    loading: state.commonReducer.loading,
    userDocuments: state.userReducer.userDocuments,
    authData: state.authReducer.authData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { addUserDocument, getUserDocument, deleteUserDocument },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MyForms)
);
