import React from 'react';
import {  IonGrid, IonRow, IonCol, IonCard, IonCardHeader, IonCardContent, IonImg } from '@ionic/react';
import {withRouter} from 'react-router-dom';
import DashCalander from "../../../images/dash-calendar.svg";
import { getUpcomingEvents } from "../../../redux/actions/zoomAction"
import { useDispatch, connect } from 'react-redux';
import moment from "moment";

const UpComingEvents = (props) => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(getUpcomingEvents())
  }, []);

  function formatTime(date) {
    var momentDate = moment(date)
    return momentDate.format("hh:mm A ")
  }

  return (<IonCard className="dashboard-card">
      <IonCardHeader className="dashboard-card-head">
        <h3>UPCOMING EVENTS</h3>
        <a title="View Calendar">
          <div className="dashboard-card-icon">
            <div className="dashboard-card-icon-inner" onClick={(e)=>{props.history.push("/page/calendar")}}>
              <IonImg src={DashCalander} />
            </div>
          </div>
        </a>
      </IonCardHeader>
      <IonCardContent className="dashboard-card-body">
        <div className="dash-table">
          <div className="dashtable-responsive">
          
            <table className="table">
              <thead>
                  <tr>
                  <td><p>Event Name</p></td>
                  <td><p>Date</p></td>
                  <td><p>Time</p></td>
                  </tr>
              </thead>
              <tbody>
                { props?.upcomingList?.length > 0 ? props?.upcomingList?.map((data, i) => {
                return (<tr className="tableCol">
                  <td><p>{data.name}</p></td>
                  <td><p>{moment(data.startDate.split("T")[0]).format('MM-DD-YYYY')}</p></td>
                  <td><p>{formatTime(data?.startDate)}</p></td>
                </tr>
                )
              }): props.loading === true ? <p style={{padding:10}}>Loading...</p>: <p style={{padding:10}}>No UpComing Events.</p>}
              </tbody>
            </table>

          </div>
        </div>
      </IonCardContent>
    </IonCard>

  );
};

const mapStateToProps = (state) => {
  return {
    upcomingList: state.zoomReducer.upcomingList,
    loading: state.commonReducer.loading,
  };
};


export default withRouter( connect(mapStateToProps)(UpComingEvents))
