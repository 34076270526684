import React, { useEffect, useState } from "react";
import { withRouter, useHistory } from "react-router-dom";
import {
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonTextarea,
  IonSpinner,
} from "@ionic/react";
import {
  getPlayerQuestions,
  clearReportingSystem,
  addPlayerAnswers,
  checkPlayerAnswerSubmission,
} from "../../redux/actions/prsAction";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
import "./playerReportingSystem.css";
import { toast } from "../../Utils/toast";

const WeeklyReport = (props) => {
  const dispatch = useDispatch();
  let history = useHistory();
  const [formValue, setFormValue] = useState([]);
  const [errorMessage, setErrorMessage] = useState(false);
  const [validationError, setValidationError] = useState("");


  const clearFrom = () => {
    dispatch(clearReportingSystem());
    history.push("/page/playerSubmitReportSystem");
  };

  useEffect(() => {
    onSelectCategory();
  }, []);
  useEffect(() => {
    return () => {
      dispatch(clearReportingSystem());
    };
  }, []);

  useEffect(() => {
    setFormValue(props.playersQuestions);
  }, [props.playersQuestions]);

  const onSelectCategory = async (e, id) => {
    await dispatch(await getPlayerQuestions("weekly_checkin"));
  };

  const handleChange = async (e, question_id, id) => {
    const player_id = JSON.parse(localStorage.getItem("userData")).id;
    let val = [...formValue];
    let questionIndex = null;
    let counter = 0;

    for (const i of val) {
      if (i.question_id === question_id) {
        questionIndex = counter;
      }
      counter++;
    }
    if (questionIndex !== null) {
      // if (e.detail.value.length > 1000) {
      //   setValidationError("Max. answer length is 50 characters");
      //   return null;
      // } else {
      //   setValidationError("");
      // }
      val[questionIndex] = {
        question_id: question_id,
        answer: e.detail.value,
        player_id,
        id,
      };
    } else {
      val.push({
        question_id: question_id,
        answer: e.detail.value,
        player_id,
        id,
      });
    }

    await setFormValue(val);
    const array1 = val.filter((value) => value.id === id)[0];
    let elms = document.getElementById(array1.question_id);
    elms.style.display = "none";
    if (!array1.answer) {
      let elms = document.getElementById(array1.question_id);
      elms.style.display = "block";
    }

  
  };
  const onSubmit = async () => {
    if (validationError.length !== 0) {
      return null;
    }
    let finalArray = [];

    const arr = await formValue
      .filter((form) => {
        if (form.answer !== undefined) {
          form.answer = form.answer.trim();
        }

        let elms = document.getElementById(form.question_id);
        elms.style.display = "none";
        var str = form.answer;
        return (
          form.answer === null ||
          form.answer === undefined ||
          form.answer === "" ||
          !str.replace(/\s/g, "").length === true
        );
      })
      .map((data, i) => {
        let elms = document.getElementById(data.question_id);
        elms.style.display = "none";
        return data;
      });

    if (arr.length > 0) {
      arr.map((data, i) => {
        if (!data.answer) {
          let elms = document.getElementById(data.question_id);
          elms.style.display = "block";
        }
      });
    } else {
      await setErrorMessage(false);
      await formValue.map(async (data, index) => {
        let elms = document.getElementById(data.question_id);
        elms.style.display = "none";
        await finalArray.push([
          data.answer,
          data.question_id,
          data.player_id,
          data.id,
        ]);
      });
      await dispatch(await addPlayerAnswers(finalArray));
      toast.success("Check-In successfully submitted");
      dispatch(clearReportingSystem());
      dispatch(getPlayerQuestions("weekly_checkin"));
      setFormValue(props.playersQuestions);
    }
  };

  return (
    <div className="reporting-mid-tab">
      <IonGrid>
        <IonRow>
          <IonCol size="12" size-md="12">
            <div className="reporting-mid-right">
              <div className="tab-content">
                <div className="tab-pane active" id="wellness">
                  <div className="reporting-tabbox reporting-tab-display-wrapper">
                    <div className="reporting-tabbox-head">
                      <div className="player-header-div player-header-content">
                        <div className="player-image-div">
                        <img
                            src={
                              props?.coachImage ||
                              "/assets/icon/client-dummy.png"
                            }
                            alt="profileimage"
                          />
                        </div>
                        <div className="player-detail-div">
                          <h5 className="player-name">
                            {props?.coachQuestionsName}
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div className="reporting-tabbox-mid">
                      {props?.playersQuestions.length > 0 ? (
                        props?.playersQuestions?.map((data, index) => {
                          return (
                            <div className="reporting-tabbox-item">
                              <div
                                className="reporting-tabbox-question"
                                key={index}
                              >
                                <div className="reporting-tabbox-question-icon">
                                  <span>Q</span>
                                </div>
                                <div className="reporting-tabbox-question-text">
                                  <p>{data?.question}</p>
                                </div>
                              </div>
                              <div className="reporting-tabbox-ans">
                                <div className="reporting-tabbox-ans-icon">
                                  <span>A</span>
                                </div>
                                <div className="reporting-tabbox-ansinput">
                                  <IonTextarea
                                  rows={6}
                                    onIonChange={(event) => {
                                      handleChange(
                                        event,
                                        data.question_id,
                                        data.id
                                      );
                                    }}
                                    placeholder="Type answer here"
                                  ></IonTextarea>
                                  <span
                                    className="reporting-tabbox-ans-error"
                                    id={data.question_id}
                                    style={{ display: "none" }}
                                  >
                                    {" "}
                                    Please provide an answer for the above
                                    question.
                                  </span>
                                </div>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <div className="loading-para">
                          {props.loading === true ? (
                            <p style={{ color: "#404F8C" }}>Loading...</p>
                          ) : (
                            <p style={{ color: "#404F8C" }}>
                              No Question Added.
                            </p>
                          )}
                        </div>
                      )}
                      {props &&
                      props.playersQuestions &&
                      props.playersQuestions.length > 0 ? (
                        <div className="reporting-tabbox-btn report-tab-button-wrapper">
                          <button
                            className="cancel-btn-div"
                            onClick={clearFrom}
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            color="none"
                            onClick={onSubmit}
                            className="submit-btn-wrapper"
                          >
                            Submit
                            {(() => {
                              if (props.loading) {
                                return (
                                  <IonSpinner
                                    name="crescent"
                                    style={{ color: "black" }}
                                    className="spinner-loader"
                                  />
                                );
                              }
                            })()}
                          </button>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    playersQuestions: state.prsReducer.playersQuestions,
    isAnswerSubmitted: state.prsReducer.isAnswerSubmitted,
    playersAnswersAfterSubmit: state.prsReducer.playersAnswersAfterSubmit,
    loading: state.commonReducer.loading,
    authData: state.authReducer.authData,
    coachImage: state.prsReducer.coachImage,
    coachQuestionsName: state.prsReducer.coachQuestionsName,
  };
};

export default withRouter(connect(mapStateToProps)(WeeklyReport));
