export const initialState = {
  zoomPayload: {},
  playPayload: {},
  isEventAdded: false,
  upcomingList: [],
  eventList: [],
  type: "LOADING_STARTED",
};

const zoomReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ZOOM_LINK":
      return {
        ...state,
        zoomPayload: action.payload,
      };
    case "GET_PLAY_LINK":
      return {
        ...state,
        playPayload: action.payload,
      };
    case "ADDEVENT":
      return {
        ...state,
        isEventAdded: action.payload.data.isRegistered,
      };

    case "GETEVENT":
      return {
        ...state,
        eventList: action.payload.data,
        type: action.type,
      };
    case "GET_UPCOMING_EVENTS":
      return {
        ...state,
        upcomingList: action.payload.data,
      };

    case "CLEAR_EVENT":
      return {
        ...state,
        eventList: [],
        type: action.type,
      };
    default:
      return state;
  }
};

export default zoomReducer;
