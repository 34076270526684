export const initialState = {
    clientSecret: "",
}

const stripeReducer = (state = initialState, action) => {
    switch (action.type) {
        case "REACT_APP_GET_STRIPE_CLIENT_KEY":
            return {
                ...state,
                clientSecret: action.payload
            };
        default:
            return state;
    }
}

export default stripeReducer;