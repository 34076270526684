import {
  IonContent,
  IonInput,
  IonButton,
  IonLabel,
  IonImg,
  IonSpinner,
} from "@ionic/react";
import React, { useState } from "react";
import { menuController } from "@ionic/core";
import loginlogo from "../images/logo-blue.png";
import "./forgotPassword.css";
import { connect } from "react-redux";
import { forgotPassword } from "../redux/actions/authAction";
import { handleError, ClearData } from "../redux/actions/commonAction";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";

const ForgotPassword = (props) => {
  const [formData, setFormValue] = useState({ email: "" });
  const [errorData, setErrorData] = useState({ email: [] });
  React.useEffect(() => {
    props.ClearData();
    menuController.enable(false);
  }, []);
  const handleChange = (formField, event) => {
    let test = formData;
    test[formField] = event.target.value;
    setFormValue(test);
  };

  const validateForm = () => {
    let isFormValid = true;
    let testError = { email: [], password: [] };
    if (formData.email.trim().length === 0) {
      isFormValid = false;
      testError.email = ["This field is required."];
    }
    if (
      formData.email.trim().length > 0 &&
      /^([a-zA-Z0-9_\.\-]+)@([a-zA-Z0-9_\.\-]+)\.([a-zA-Z]{2,5})$/.test(
        formData.email
      ) === false
    ) {
      isFormValid = false;
      testError.email = ["You entered an invalid email address."];
    }
    setErrorData(testError);
    return isFormValid;
  };
  async function onSubmitForm(event) {
    event.preventDefault();
    if (validateForm()) {
      // props.handleError({message:""});
      // const resp =
      await props.forgotPassword(formData, setFormValue);
      // setFormValue({email:""})
    }
  }
  return (
    <IonContent>
      <div className="forgot-password-from">
        <div className="login-inner">
          <div className="login-logo">
            <IonImg src={loginlogo} />
          </div>
          <div className="login-head">
            <h2>Forgot Password</h2>
            {props?.errorMsg ? (
              <div className="errormsg">
                <p>{props.errorMsg}</p>
              </div>
            ) : props?.successMsg ? (
              <div className="successmsg">
                <p>{props.successMsg}</p>
              </div>
            ) : null}
          </div>
          <div className="login-body">
            <form>
              <div className="form-group">
                <IonLabel>Enter your email address.<sup>*</sup></IonLabel>
                <IonInput
                  type="text"
                  name="email"
                  value={formData.email}
                  className="form-control"
                  onIonChange={(event) => {
                    handleChange("email", event);
                  }}
                ></IonInput>
                <p> {errorData?.email && errorData.email[0]}</p>
              </div>
              <div className="backlogin-link">
                <p>
                  Go back to <Link to="/login">log in.</Link>
                </p>
              </div>
              <div className="button-login">
                <IonButton
                  className="gradient-btn-blue"
                  color="none"
                  onClick={(event) => {
                    onSubmitForm(event);
                  }}
                >
                  Next
                  {(() => {
                    if (props.loading) {
                      return (
                        <IonSpinner
                          name="crescent"
                          className="spinner-loader"
                        />
                      );
                    }
                  })()}
                </IonButton>
              </div>
            </form>
          </div>
        </div>
      </div>
    </IonContent>
  );
};

const mapStateToProps = (state) => {
  return {
    errorMsg: state.commonReducer.errorMsg,
    successMsg: state.commonReducer.successMsg,
    loading: state.commonReducer.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { forgotPassword, handleError, ClearData },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
