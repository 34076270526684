import React from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonList,
  IonItem,
  IonInput,
  IonButton,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonImg,
} from "@ionic/react";

class PrivateGuard extends React.Component {
  dontShowAfterLogin = [
    "/signup",
    "/signUp",
    "/login",
    "/changePassword",
    "/forgotPassword",
    "/verifyEmail",

    // "/landing"
  ];
  checkUser = () => {
    return localStorage.getItem("user");
  };
  render() {
    const route = this.props.location?.pathname;
    let returnData = "";
    if (this.checkUser()) {
      if (!this.dontShowAfterLogin.includes(route)) {
        returnData = <Route {...this.props} />;
      } else {
        if (this.props.checkUserRole === 1) {
          returnData = (
            <Redirect
              to={{
                pathname: "/page/coach-dashboard",
                state: { from: this.props.location },
              }}
            />
          );
        } else if (this.props.checkUserRole === 2) {
          returnData = (
            <Redirect
              to={{
                pathname: "/page/coach-dashboard",
                state: { from: this.props.location },
              }}
            />
          );
        } else if (this.props.checkUserRole === 3) {
          returnData = (
            <Redirect
              to={{
                pathname: "/page/dashboard",
                state: { from: this.props.location },
              }}
            />
          );
        }
      }
    } else {
      if (this.dontShowAfterLogin.includes(route)) {
        returnData = <Route {...this.props} />;
      } else {
        returnData = (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: this.props.location },
            }}
          />
        );
      }
    }

    return <IonContent>{returnData}</IonContent>;
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
    authData: state.authReducer.authData,
    checkUserRole: state.authReducer.checkUserRole,
  };
};

export default connect(mapStateToProps)(PrivateGuard);
