import Menu from "./components/Menu";
import Page from "./pages/Page";
import Login from "./pages/login";
import Signup from "./pages/signup";
import ForgotPassword from "./pages/forgotPassword";
import ChangePassword from "./pages/changePassword";
import SlugPage from "./pages/slugPages";
import VerifyEmail from "./pages/verifyEmail";
import privacyPolicy from "./pages/privacy-policy";
import termsConditions from "./pages/terms-conditions";
// import Stripe from "./components/stripe/stripe"
import { useDispatch } from "react-redux";
import { checkPayment, matchtoken } from "./redux/actions/authAction";
import { changeReadStatus } from "./redux/actions/zoomAction";
import { changeMode } from "./redux/actions/commonAction";
import { connect } from "react-redux";
import React, { useState } from "react";
import { IonApp, IonRouterOutlet, IonSplitPane, IonAlert } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { Redirect, Route, Switch } from "react-router-dom";
import { bindActionCreators } from "redux";
/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";
/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";
/* Theme variables */
import "./theme/variables.css";
import { getPlatforms } from "@ionic/react";
import PrivateGuard from "./Routes/privateGuard";

import { browserHistory } from "./history";
import {
  Plugins,
  PushNotification,
  PushNotificationToken,
  PushNotificationActionPerformed,
  KeyboardInfo,
} from "@capacitor/core";
import landingPage from "./pages/landing-page";

const { Keyboard } = Plugins;
const { PushNotifications } = Plugins;
const App = (props) => {
  const dispatch = useDispatch();
  const [isWeb, setWeb] = useState(
    getPlatforms().some((r) => ["desktop", "mobileweb"].indexOf(r) >= 0)
  );
  const [showAlert1, setShowAlert1] = useState(false);
  const [alertData, setAlertData] = useState({
    title: "",
    body: "",
    notifData: {},
    isNotification: false,
  });
  const [notificationData, setNotification] = useState([]);
  React.useEffect(() => {
    props.changeMode(JSON.parse(localStorage.getItem("darkMode")));

    let webb = getPlatforms().some(
      (r) => ["desktop", "mobileweb"].indexOf(r) >= 0
    );

    setWeb(webb);
    if (!webb) {
      push();
      Keyboard.setAccessoryBarVisible({ isVisible: true });
    }
    if (localStorage.getItem("user")) {
      dispatch(matchtoken());
    }
  }, []);

  const push = () => {
    PushNotifications.requestPermission().then((result) => {
      if (result.granted) {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
      } else {
        // Show some error
      }
    });

    // Register with Apple / Google to receive push via APNS/FCM
    PushNotifications.register();

    // On succcess, we should be able to receive notifications
    PushNotifications.addListener("registration", (token) => {
      dispatch({ type: "FCMTOKEN", payload: token.value });
      localStorage.setItem("fcmToken", token.value);
    });

    // Some issue with our setup and push will not work
    PushNotifications.addListener("registrationError", (error) => {});

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener(
      "pushNotificationReceived",
      (notification) => {
        if (notification.data.type === "Add Question") {
          // let dummy= {title:"New Question Added!",body:"New Question Added by your head coach!",notifData:notification,isNotification:true}
          let dummy = {
            title: "New Question Added!",
            body: "New Question Added by your head coach!",
            notifData: notification,
            isNotification: true,
          };

          // let notificationDataCopy= notificationData
          // notificationData.push(dummy)
          setNotification((oldArray) => [...oldArray, dummy]);
          // setAlertData({title:"New Question Added!",body:"New Question Added by your head coach!",notifData:notification})
          setTimeout(() => {
            setShowAlert1(true);
          }, 1000);
        } else if (notification.data.type === "calendar event") {
          let dummy = {
            title: "Event Notification",
            body: "An event is about to start!",
            notifData: notification,
            isNotification: true,
          };

          notificationData.push(dummy);
          setNotification((oldArray) => [...oldArray, dummy]);
          // setAlertData({title:"New Question Added!",body:"New Question Added by your head coach!",notifData:notification})
          setTimeout(() => {
            setShowAlert1(true);
          }, 1000);
        } else if (notification.data.type === "Chat_Notification") {
          if (browserHistory.location.pathname !== "/page/sportTalks") {
            let dummy = {
              title: notification.body,
              body: message,
              notifData: notification,
              isNotification: true,
            };

            let message = "";
            if (notification.data.data_body) {
              message = JSON.parse(notification.data.data_body);
              message = message.message;
            }

            setNotification((oldArray) => [...oldArray, dummy]);
            setTimeout(() => {
              setShowAlert1(true);
            }, 1000);
          }
        } else {
          window.alert(JSON.stringify(notification));
        }
        // notificationAction(notification.data.type,notification.data.notification_id,JSON.parse(notification.data.data_body))
      }
    );

    // Method called when tapping on a notification
    PushNotifications.addListener(
      "pushNotificationActionPerformed",
      (notification) => {
        if (notification.notification.data.type === "Chat_Notification") {
          notificationAction(
            notification.notification.data.type,
            null,
            JSON.parse(notification.notification.data.data_body)
          );
        } else {
          notificationAction(
            notification.notification.data.type,
            notification.notification.data.notification_id,
            JSON.parse(notification.notification.data.data_body)
          );
        }
      }
    );
  };

  const dismissNotification = (index) => {
    // let notifArray= notificationData

    notificationData.splice(index, 1);
    if (notificationData.length === 0) {
      setShowAlert1(false);
    }
    setNotification([...notificationData]);
  };

  // alert(`${isWeb} `)

  const navigateToChanges = (notif, index) => {
    let notifArray = notificationData;
    if (notif.notifData?.data?.type === "Chat_Notification") {
      notificationAction(
        notif.notifData?.data?.type,
        null,
        JSON.parse(notif.notifData?.data?.data_body)
      );
      notificationData.splice(index, 1);
      if (notificationData.length === 0) {
        setShowAlert1(false);
      }

      setNotification([...notificationData]);
    } else {
      notificationAction(
        notif.notifData?.data?.type,
        notif.notifData?.data?.notification_id,
        JSON.parse(notif.notifData?.data?.data_body)
      );
      notificationData.splice(index, 1);
      if (notificationData.length === 0) {
        setShowAlert1(false);
      }

      setNotification([...notificationData]);
    }
  };
  const notificationAction = async (type, id, data) => {
    if (type === "Add Question") {
      let categorydata = data[2];
      if (props.checkUserRole === 2) {
        await props.changeReadStatus(id);
        browserHistory.push({
          pathname: "/page/coachReportingSystem",
          state: { category: categorydata },
        });
      } else if (props.checkUserRole === 3) {
        await props.changeReadStatus(id);
        browserHistory.push({
          pathname: "/page/playerReportingSystem",
          state: { category: categorydata },
        });
      }
    } else if (type === "calendar event") {
      await props.changeReadStatus(id);
      browserHistory.push({
        pathname: "/page/calendar",
        state: { event: data },
      });
    } else if (type === "Chat_Notification") {
      browserHistory.push({
        pathname: "/page/sportTalks",
        state: { event: data },
      });
    } else {
    }
  };

  return (
    <IonApp className={props.darkMode === true ? "dark-mode" : ""}>
      <IonReactRouter history={browserHistory}>
        <IonSplitPane contentId="main">
          {window.location.pathname === "/privacy-policy" ? (
            ""
          ) : window.location.pathname === "/terms-conditions" ? (
            ""
          ) : (
            <Menu />
          )}

          <IonRouterOutlet id="main">
            <Switch>
              <PrivateGuard path="/page/:name" component={Page} />
              <Route path="/content/:pageSlug" component={SlugPage} />
              <PrivateGuard path="/signup" component={Signup} exact />
              <PrivateGuard path="/login" component={Login} exact />
              <Route path="/landing" component={landingPage} exact />
              <Route path="/verifyEmail" component={VerifyEmail} />
              <Route path="/privacy-policy" component={privacyPolicy} />

              <Route path="/terms-conditions" component={termsConditions} />
              <PrivateGuard
                path="/forgotPassword"
                component={ForgotPassword}
                exact
              />

              <PrivateGuard
                path="/changePassword"
                component={ChangePassword}
                exact
              />
              <Redirect
                from="/page"
                to={
                  props?.authData?.role === 1
                    ? "/page/coach-dashboard"
                    : props?.authData?.role === 2
                    ? "/page/assistant-dashboard"
                    : "/page/dashboard"
                }
                exact
              />
              {isWeb === true ? (
                <Redirect from="/" to="/landing" exact />
              ) : isWeb === false ? (
                <Redirect from="/" to="/login" exact />
              ) : null}
              {/* <Redirect from="/" to="/lo" exact /> */}
            </Switch>
          </IonRouterOutlet>
          {notificationData.map((notiff, index) => {
            return (
              <IonAlert
                isOpen={showAlert1}
                onDidDismiss={() => dismissNotification(index)}
                cssClass="my-custom-class"
                header={notiff.title}
                message={notiff.body}
                buttons={[
                  {
                    text: "Cancel",
                    role: "cancel",
                    cssClass: "secondary",
                    handler: (blah) => {},
                  },
                  {
                    text: "View",
                    handler: () => {
                      navigateToChanges(notiff, index);
                    },
                  },
                ]}
              />
            );
          })}
        </IonSplitPane>
      </IonReactRouter>
    </IonApp>
  );
};

const mapStateToProps = (state) => {
  return {
    authData: state.authReducer.authData,
    checkUserRole: state.authReducer.checkUserRole,
    darkMode: state.commonReducer.darkMode,
    checkIsPayment: state.authReducer.checkIsPayment,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ changeReadStatus, changeMode }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
